import { PaperAirplaneIcon } from '@heroicons/react/24/outline'
import { Loader2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button, { Variant } from '@sherweb/core/components/Button'
import { usePrompt } from '@sherweb/core/components/Prompt'
import { AutosizeTextarea } from '@sherweb/core/components/Textarea'

import { useHelpDeskCreateTicketNoteMutation } from '@ssp/modules/integrations/helpdesk'
import { useParams } from '@ssp/modules/navigation'

type HelpDeskTicketSendMessageProps = {
  onScrollToLastMessage: () => void
}

export const HelpDeskTicketSendMessage = ({
  onScrollToLastMessage,
}: HelpDeskTicketSendMessageProps) => {
  const { t } = useTranslation()

  const { helpDeskTicketId } = useParams()

  const [message, setMessage] = useState('')

  const helpDeskCreateTicketNoteMutation = useHelpDeskCreateTicketNoteMutation()

  usePrompt({
    isDirty: message !== '',
  })

  useEffect(() => {
    if (helpDeskCreateTicketNoteMutation.isSuccess) {
      setMessage('')
      onScrollToLastMessage()
    }
  }, [helpDeskCreateTicketNoteMutation.isSuccess, onScrollToLastMessage])

  const handleSendMessage = () => {
    if (message !== '') {
      helpDeskCreateTicketNoteMutation.mutate({
        text: message,
        ticketId: helpDeskTicketId ?? '',
      })
    }
  }

  return (
    <div className="flex w-full items-end justify-between gap-2 px-6 py-4">
      <div className="relative w-full">
        <AutosizeTextarea
          autoComplete="off"
          autoFocus
          minHeight={80}
          value={message}
          name="message"
          data-testid="inputHelpDeskTicketNote"
          disabled={helpDeskCreateTicketNoteMutation.isLoading}
          onChange={event => setMessage(event?.target.value)}
          placeholder={t('ssp:pages.helpdesk.detail.replyPlaceholder')}
          className="flex h-9 w-full items-center border bg-background"
        />
      </div>
      <Button
        variant={Variant.None}
        onClick={handleSendMessage}
        data-testid="btnHelpDeskTicketSendNote"
        className="p-4 text-indigo-600 enabled:hover:rounded-full enabled:hover:bg-slate-100 enabled:hover:dark:bg-slate-950"
      >
        {helpDeskCreateTicketNoteMutation.isLoading ? (
          <Loader2 data-testid="loaderHelpDeskSendNote" className="h-4 w-4 animate-spin" />
        ) : (
          <PaperAirplaneIcon className="h-5 w-5" />
        )}
      </Button>
    </div>
  )
}
