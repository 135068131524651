import Spinner from '../Spinner'
import { Sheet, SheetContent, SheetHeader, SheetTitle } from './Sheet'

type SheetPageProps = {
  isLoading?: boolean
  title: React.ReactNode
  children: React.ReactNode
  onClose: () => void
}

export const SheetPage = ({ isLoading, title, children, onClose }: SheetPageProps) => (
  <Sheet open>
    <SheetContent
      className="flex w-full flex-col gap-0 overflow-auto p-0 sm:w-[550px] sm:p-0"
      closeIconContainerClassName="!top-6 [&>button]:p-0"
      onPointerDownOutside={onClose}
      onEscapeKeyDown={onClose}
      onClose={onClose}
    >
      {isLoading ? (
        <Spinner className="absolute left-[calc(50%-3rem)] top-[calc(50%-3rem)]" />
      ) : (
        <>
          <SheetHeader className="p-6 pr-14">
            <SheetTitle>{title}</SheetTitle>
          </SheetHeader>
          {children}
        </>
      )}
    </SheetContent>
  </Sheet>
)
