import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
  CreateHelpDeskTicketCommand,
  CreateHelpDeskTicketNoteCommand,
  HelpDeskTicketDetailsQueryResult,
  HelpDeskTicketStatus,
  HelpDeskTicketType,
  OrganizationIntegrationsQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationTicketsService } from '@sherweb/core/openapi-generated/OrganizationTicketsService'

import { successNotification } from '@sherweb/core/components/ToastNotifications'
import { arrayToObject } from '@sherweb/core/utils/array'

import { useSelectedOrganization } from '@ssp/modules/organization'

import { useGetIntegrationsQuery } from '../../core/integrations.hooks'
import { useOptimisticUpdateDetailNote } from './helpdesk.helpers'
import {
  helpDeskAccess,
  helpDeskTickets,
  organizationHelpDeskTicketDetail,
  organizationHelpDeskTicketStatuses,
  organizationHelpDeskTicketTypes,
} from './helpdesk.queries'

export const useGetHelpDeskAccessQuery = () => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: helpDeskAccess.queryKey(selectedOrganization?.id),
    queryFn: async () => await helpDeskAccess.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    staleTime: helpDeskAccess.staleTime,
  })
}

export const useGetHelpDeskEnabledQuery = <TResult = boolean>(
  options: Omit<
    UseQueryOptions<OrganizationIntegrationsQueryResult, Error, TResult>,
    'queryKey' | 'select'
  > = {}
) => {
  return useGetIntegrationsQuery<TResult>({
    ...options,
    select: (integrations?: OrganizationIntegrationsQueryResult) =>
      (integrations?.helpDesk?.isEnabled ?? false) as TResult,
  })
}

export const useGetHelpDeskTicketsQuery = () => {
  const helpDeskAccessQuery = useGetHelpDeskAccessQuery()

  const selectedOrganization = useSelectedOrganization()

  const isHelpDeskAvailable =
    !helpDeskAccessQuery?.isLoading &&
    helpDeskAccessQuery.data !== undefined &&
    helpDeskAccessQuery.data.isPsaAvailable

  return useQuery({
    queryKey: helpDeskTickets.queryKey(selectedOrganization?.id),
    queryFn: async () => await helpDeskTickets.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization && isHelpDeskAvailable,
    staleTime: helpDeskTickets.staleTime,
  })
}

export const useGetHelpDeskOrganizationTicketTypesQuery = <TResult = HelpDeskTicketType[]>(
  select?: (data?: HelpDeskTicketType[]) => TResult
) => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: organizationHelpDeskTicketTypes.queryKey(selectedOrganization?.id),
    queryFn: async () => await organizationHelpDeskTicketTypes.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    select,
    staleTime: organizationHelpDeskTicketTypes.staleTime,
  })
}

export const useGetHelpDeskOrganizationTicketTypeQuery = () => {
  return useGetHelpDeskOrganizationTicketTypesQuery((ticketTypes?: HelpDeskTicketType[]) =>
    arrayToObject(ticketTypes, 'id')
  )
}

export const useGetOrganizationHelpDeskTicketStatusesQuery = () => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: organizationHelpDeskTicketStatuses.queryKey(selectedOrganization?.id),
    queryFn: async () => await organizationHelpDeskTicketStatuses.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    select: (data?: HelpDeskTicketStatus[]) => arrayToObject(data, 'id'),
    staleTime: organizationHelpDeskTicketStatuses.staleTime,
  })
}

export const useHelpDeskCreateTicketMutation = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const selectedOrganization = useSelectedOrganization()

  return useMutation({
    mutationFn: async (
      command: Omit<CreateHelpDeskTicketCommand, 'ticketTypeId'> & { ticketTypeId?: string }
    ) => {
      if (!command?.ticketTypeId || !selectedOrganization?.id) {
        throw new Error('Missing parameters')
      }

      return await OrganizationTicketsService.createTicket({
        organizationId: selectedOrganization?.id,
        command: {
          ...command,
          ticketTypeId: command.ticketTypeId,
        },
      })
    },
    meta: {
      errorMessage: t('ssp:pages.helpdesk.create.failureMessage'),
    },
    onSuccess: async () => {
      successNotification(t('ssp:pages.helpdesk.create.successMessage'))

      await queryClient.invalidateQueries({
        queryKey: helpDeskTickets.queryKey(selectedOrganization?.id),
      })
    },
  })
}

export const useGetOrganizationHelpDeskTicketDetailQuery = (
  options?: UseQueryOptions<HelpDeskTicketDetailsQueryResult>,
  ticketId?: string
) => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery({
    queryKey: organizationHelpDeskTicketDetail.queryKey(selectedOrganization?.id, ticketId),
    queryFn: async () =>
      await organizationHelpDeskTicketDetail.queryFn(selectedOrganization?.id, ticketId),
    enabled: !!selectedOrganization && !!ticketId,
    staleTime: organizationHelpDeskTicketDetail.staleTime,
    ...options,
  })
}

export const useHelpDeskCreateTicketNoteMutation = () => {
  const { t } = useTranslation()

  const selectedOrganization = useSelectedOrganization()

  const { setNote } = useOptimisticUpdateDetailNote()

  return useMutation({
    mutationFn: async (command: CreateHelpDeskTicketNoteCommand & { ticketId?: string }) => {
      if (!command?.ticketId || !selectedOrganization?.id) {
        throw new Error('Missing parameters')
      }

      return await OrganizationTicketsService.createTicketNote({
        organizationId: selectedOrganization?.id,
        ticketId: command.ticketId,
        command: {
          text: command.text,
        },
      })
    },
    meta: {
      errorMessage: t('ssp:pages.helpdesk.detail.failureMessage'),
    },
    onSuccess: async (data, variables) => {
      const queryKey = organizationHelpDeskTicketDetail.queryKey(
        selectedOrganization?.id,
        variables?.ticketId
      )
      return setNote(queryKey, data)
    },
  })
}
