import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { BillingCycle, CommitmentTermType } from '@sherweb/core/openapi-generated/index.defs'

import Routes from '@ssp/app/Routes'
import { usePathGenerator } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'

export const useGetShopRouteParams = (params?: Record<string, string | undefined>) => {
  const organization = useSelectedOrganization()

  const organizationUniqueName = organization?.uniqueName

  if (params && organizationUniqueName) {
    return { organizationUniqueName, ...params }
  }

  if (organizationUniqueName) {
    return { organizationUniqueName }
  }
}

export const useNavigateToShopPage = () => {
  const generatePath = usePathGenerator()

  const navigate = useNavigate()

  const shopRouteParams = useGetShopRouteParams()

  return useCallback(
    (searchParams?: string) => {
      if (shopRouteParams?.organizationUniqueName) {
        navigate({
          pathname: generatePath(Routes.Shop, {
            organizationUniqueName: shopRouteParams?.organizationUniqueName,
          }),
          search: searchParams,
        })
      }
    },
    [generatePath, shopRouteParams?.organizationUniqueName, navigate]
  )
}

export const useBillingCycleTranslation = () => {
  const { t } = useTranslation()
  return (value?: BillingCycle | null) => {
    switch (value) {
      case BillingCycle.Daily:
        return t('ssp:pages.shop.detail.billingCycleValues.daily')
      case BillingCycle.Weekly:
        return t('ssp:pages.shop.detail.billingCycleValues.weekly')
      case BillingCycle.Monthly:
        return t('ssp:pages.shop.detail.billingCycleValues.monthly')
      case BillingCycle.Yearly:
        return t('ssp:pages.shop.detail.billingCycleValues.yearly')
      case BillingCycle.Unknown:
        return t('ssp:pages.shop.detail.billingCycleValues.unknown')
    }
  }
}

export const useCommitmentTermTranslation = () => {
  const { t } = useTranslation()
  return (value?: CommitmentTermType | null) => {
    switch (value) {
      case CommitmentTermType.Annual:
        return t('ssp:pages.shop.detail.commitmentTermValues.annual')
      case CommitmentTermType.Biennial:
        return t('ssp:pages.shop.detail.commitmentTermValues.biennial')
      case CommitmentTermType.Triennial:
        return t('ssp:pages.shop.detail.commitmentTermValues.triennial')
      case CommitmentTermType.Monthly:
        return t('ssp:pages.shop.detail.commitmentTermValues.monthly')
      case CommitmentTermType.Unknown:
        return t('ssp:pages.shop.detail.commitmentTermValues.unknown')
    }
  }
}

export const useContractDurationCommitmentTermTranslation = () => {
  const { t } = useTranslation()
  return (value?: CommitmentTermType | null) => {
    switch (value) {
      case CommitmentTermType.Annual:
        return t('ssp:pages.shop.detail.contractDurationCommitmentTermValues.annual')
      case CommitmentTermType.Biennial:
        return t('ssp:pages.shop.detail.contractDurationCommitmentTermValues.biennial')
      case CommitmentTermType.Triennial:
        return t('ssp:pages.shop.detail.contractDurationCommitmentTermValues.triennial')
      case CommitmentTermType.Monthly:
        return t('ssp:pages.shop.detail.contractDurationCommitmentTermValues.monthly')
      case CommitmentTermType.Unknown:
        return '-'
    }
  }
}

export const useBillingCycleSummaryTranslation = () => {
  const { t } = useTranslation()
  return (value?: BillingCycle | null) => {
    switch (value) {
      case BillingCycle.Daily:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCycleValues.daily')
      case BillingCycle.Weekly:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCycleValues.weekly')
      case BillingCycle.Monthly:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCycleValues.monthly')
      case BillingCycle.Yearly:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCycleValues.yearly')
      case BillingCycle.Unknown:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCycleValues.unknown')
    }
  }
}

export const useBillingCyclePeriodSummaryTranslation = () => {
  const { t } = useTranslation()
  return (value?: BillingCycle | null) => {
    switch (value) {
      case BillingCycle.Daily:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCyclePeriodValues.daily')
      case BillingCycle.Weekly:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCyclePeriodValues.weekly')
      case BillingCycle.Monthly:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCyclePeriodValues.monthly')
      case BillingCycle.Yearly:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCyclePeriodValues.yearly')
      case BillingCycle.Unknown:
        return t('ssp:pages.shop.detail.commitmentSummary.billingCyclePeriodValues.unknown')
    }
  }
}

export const useCommitmentTermSummaryTranslation = () => {
  const { t } = useTranslation()
  return (value?: CommitmentTermType | null) => {
    switch (value) {
      case CommitmentTermType.Annual:
        return t('ssp:pages.shop.detail.commitmentSummary.commitmentTermValues.annual')
      case CommitmentTermType.Biennial:
        return t('ssp:pages.shop.detail.commitmentSummary.commitmentTermValues.biennial')
      case CommitmentTermType.Triennial:
        return t('ssp:pages.shop.detail.commitmentSummary.commitmentTermValues.triennial')
      case CommitmentTermType.Monthly:
        return t('ssp:pages.shop.detail.commitmentSummary.commitmentTermValues.monthly')
      case CommitmentTermType.Unknown:
        return t('ssp:pages.shop.detail.commitmentSummary.commitmentTermValues.unknown')
    }
  }
}
