import {
  OrdersQueryResult,
  Order,
  OrderStatus,
  OrderError,
  OrderErrorResult,
  Money,
  Currency,
  OrdersLineQueryResult,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ResellerOrdersService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getAvailableOrdersByResellerId(
    params: {
      /**  */
      resellerId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrdersQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/resellers/{resellerId}/orders';
      url = url.replace('{resellerId}', params['resellerId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}
