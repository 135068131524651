import BuildingOffice2Icon from '@heroicons/react/24/outline/BuildingOffice2Icon'
import { Separator } from '@radix-ui/react-separator'
import { Loader2 } from 'lucide-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { CommitmentTermType, Permission } from '@sherweb/core/openapi-generated/index.defs'

import { Currency } from '@sherweb/core/common/money'
import Badge from '@sherweb/core/components/Badge'
import Button from '@sherweb/core/components/Button'
import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import { SheetPage } from '@sherweb/core/components/Sheet'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'
import { useCurrencyConverter, useMoneyFormatter } from '@sherweb/core/utils/money'

import { protectPage } from '@ssp/app/ProtectedPage'
import { HasAccess } from '@ssp/modules/authorization'
import { useGetProductById, usePlaceOrderMutation } from '@ssp/modules/shop'
import {
  useBillingCyclePeriodSummaryTranslation,
  useBillingCycleSummaryTranslation,
  useCommitmentTermSummaryTranslation,
  useContractDurationCommitmentTermTranslation,
  useNavigateToShopPage,
} from '@ssp/modules/shop/core/shop.helpers'

import ShopProductQuantity from './ShopProductQuantity'
import ShopProductSubscriptionDetails from './ShopProductSubscriptionDetails'

const ShopProductDetailPage = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const location: { state?: { previousFilterParams?: string } } = useLocation()

  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)

  const [previousQueryParams, setPreviousQueryParams] = useState<undefined | string>(undefined)

  const formatMoneyAmount = useMoneyFormatter(language)

  const { getParams } = useQueryParams<'productQuantity' | 'productCommitment' | 'productSku'>()

  const { shopProductId } = useParams()

  const quantityParam = getParams('productQuantity')

  const quantity = Number(quantityParam === '' ? '0' : quantityParam)

  const selectedCommitmentTerm = getParams('productCommitment')

  const sku = getParams('productSku')

  const getBillingCycleSummaryTranslation = useBillingCycleSummaryTranslation()
  const getBillingCyclePeriodSummaryTranslation = useBillingCyclePeriodSummaryTranslation()
  const getCommitmentTermSummaryTranslation = useCommitmentTermSummaryTranslation()
  const getContractDurationCommitmentTermTranslation =
    useContractDurationCommitmentTermTranslation()

  const navigateToShopPage = useNavigateToShopPage()

  const productQuery = useGetProductById(shopProductId)

  useEffect(() => {
    if (location?.state?.previousFilterParams) {
      setPreviousQueryParams(location.state.previousFilterParams)
    }
  }, [location?.state?.previousFilterParams])

  const placeOrderMutation = usePlaceOrderMutation()

  const handleRedirectToShop = useCallback(() => {
    navigateToShopPage(previousQueryParams)
  }, [navigateToShopPage, previousQueryParams])

  useEffect(() => {
    if (placeOrderMutation?.isSuccess) {
      handleRedirectToShop()
    }
  }, [handleRedirectToShop, placeOrderMutation?.isSuccess])

  const isProductQueryLoading = productQuery.isLoading || !productQuery?.data

  const offersByCommitmentTerm = productQuery.data?.offersByCommitmentTerm

  const selectedOffer = offersByCommitmentTerm?.[
    selectedCommitmentTerm as CommitmentTermType
  ]?.find(x => x.sku === sku)

  const totalAmount = (selectedOffer?.price.amount ?? 0) * quantity
  const currency = selectedOffer?.price.currency ?? Currency.CAD
  const currencyConverter = useCurrencyConverter()

  const formattedTotalAmount = useMemo(() => {
    return formatMoneyAmount({
      amount: totalAmount,
      currency,
    })
  }, [formatMoneyAmount, totalAmount, currency])

  const onSubmit = () => {
    placeOrderMutation.mutate({
      serviceProviderId: productQuery.data?.serviceProviderId,
      orderItems: [
        {
          productName: productQuery.data?.name,
          quantity,
          sku,
          vendorName: productQuery.data?.vendor,
          vendorId: productQuery.data?.vendorId ?? '',
          totalPrice: {
            amount: totalAmount,
            currency: currencyConverter(selectedOffer?.price.currency),
          },
        },
      ],
    })
  }

  return (
    <SheetPage
      isLoading={isProductQueryLoading}
      title={
        <Typography variant="heading4" weight="bold" className="text-wrap">
          {productQuery.data?.name}
        </Typography>
      }
      onClose={handleRedirectToShop}
    >
      <div className="mb-10 flex flex-col">
        <Separator className="!my-1 h-px bg-slate-300 dark:bg-slate-700" />
        <div className="flex justify-between p-6 pb-0">
          <div className="flex flex-wrap items-center gap-x-2">
            <BuildingOffice2Icon className="mt-1 h-4 w-4 dark:lg:text-white" />
            <Typography variant="body2" colors="light" className="mt-1" data-testid="productVendor">
              {productQuery.data?.vendor}
            </Typography>
            <div className="mt-1 flex items-center gap-x-2">
              {productQuery.data?.categories?.map(category => (
                <Badge data-testid="productCategories" key={category} className="dark:bg-slate-200">
                  {category}
                </Badge>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-between p-6 pb-0">
          <Typography variant="body2" colors="light" as="div">
            {productQuery.data?.description}
          </Typography>
        </div>

        <ShopProductSubscriptionDetails
          offersByCommitmentTerm={offersByCommitmentTerm}
          sku={sku}
          selectedOffer={selectedOffer}
          selectedCommitmentTerm={selectedCommitmentTerm}
        />
        <ShopProductQuantity quantity={quantity} />
        <div className="p-6 pb-0">
          <Typography variant="body2" weight="bold" as="div">
            {t('ssp:pages.shop.detail.total')}
          </Typography>
          <Typography variant="heading4" weight="bold" as="div">
            {formattedTotalAmount}
          </Typography>
          {selectedOffer && (
            <Typography variant="body2" colors="light" weight="default" as="div">
              {t('ssp:pages.shop.detail.commitmentSummary.summary', {
                billingCycle: getBillingCycleSummaryTranslation(selectedOffer.billingCycle),
                commitmentTerm: getCommitmentTermSummaryTranslation(
                  selectedOffer.commitmentTermType
                ),
              })}
            </Typography>
          )}
        </div>
        <HasAccess permission={Permission.MarketplaceProductEdit}>
          <div className="w-full p-6 pb-0">
            <Button
              className="w-full"
              variant="primary"
              size="sm"
              data-testid="btnCreateNewSubscription"
              onClick={() => setConfirmationDialogOpened(true)}
              disabled={quantity === 0 || placeOrderMutation.isLoading}
            >
              {placeOrderMutation.isLoading ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : null}
              {t('ssp:pages.shop.detail.create')}
            </Button>
          </div>
        </HasAccess>
        <ConfirmationDialog
          className="sm:max-w-2xl"
          open={confirmationDialogOpened}
          onClose={() => setConfirmationDialogOpened(false)}
          onConfirm={async () => onSubmit()}
          title={t('ssp:pages.shop.detail.confirmationModal.title')}
          confirmButtonText={t('ssp:pages.shop.detail.create')}
          cancelButtonText={t('ssp:actions.cancel')}
        >
          {selectedOffer?.commitmentTermType === CommitmentTermType.Unknown ? (
            <Trans
              i18nKey="ssp:pages.shop.detail.confirmationModal.bodyWithoutCommitmentTerm"
              values={{
                billingCycle: getBillingCycleSummaryTranslation(selectedOffer?.billingCycle),
                totalAmount: formattedTotalAmount,
                billingCyclePeriod: getBillingCyclePeriodSummaryTranslation(
                  selectedOffer?.billingCycle
                ),
                quantity,
              }}
              components={{ 1: <br /> }}
            />
          ) : (
            <Trans
              i18nKey="ssp:pages.shop.detail.confirmationModal.bodyWithCommitmentTerm"
              values={{
                billingCycle: getBillingCycleSummaryTranslation(selectedOffer?.billingCycle),
                commitmentTerm: getContractDurationCommitmentTermTranslation(
                  selectedOffer?.commitmentTermType
                ),
                totalAmount: formattedTotalAmount,
                billingCyclePeriod: getBillingCyclePeriodSummaryTranslation(
                  selectedOffer?.billingCycle
                ),
                quantity,
              }}
              components={{ 1: <br /> }}
            />
          )}
        </ConfirmationDialog>
      </div>
    </SheetPage>
  )
}

export default protectPage(ShopProductDetailPage, Permission.MarketplaceProductDetail)
