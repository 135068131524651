import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AmendmentOrdersService } from '@sherweb/core/openapi-generated/AmendmentOrdersService'
import { OrdersQueryResult, ProcessOrderDecision } from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'
import { Tooltip } from '@sherweb/core/components/Tooltip'

import { useSelectedResellerId } from '@rsp/modules/reseller'

import { buildAmendmentOrders, mapUpdateOnDataOnProcessOrder } from './orders.builder'
import {
  AmendmentOrder,
  OrderDecision,
  OrderError,
  OrderStatus,
  ProcessOrder,
} from './orders.model'
import { ordersByResellerId } from './orders.queries'

export const useOrders = () => {
  const selectedResellerId = useSelectedResellerId()

  return useQuery<OrdersQueryResult[], Error, AmendmentOrder[]>({
    queryKey: ordersByResellerId.queryKey(selectedResellerId),
    queryFn: async () => await ordersByResellerId.queryFn(selectedResellerId),
    enabled: !!selectedResellerId,
    select: data => buildAmendmentOrders(data),
  })
}

export const useOrder = (orderId?: string) => {
  const orders = useOrders()
  const order = orders?.data?.find(s => s.id === orderId)
  const orderNotFound = orders.isFetched && !orders.isLoading && !order

  return {
    ...orders,
    data: order,
    error: orders.error ?? (orderNotFound ? new Error('not found') : undefined),
  }
}

export const useProcessOrder = () => {
  const queryClient = useQueryClient()
  const selectedResellerId = useSelectedResellerId()
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/return-await
    mutationFn: async (processOrder: ProcessOrder) => {
      if (!processOrder.decision) {
        throw new Error('Missing decision')
      }
      if (!processOrder.orderId) {
        throw new Error('Missing order id')
      }
      if (!processOrder.organizationId) {
        throw new Error('Missing organization id')
      }

      return await AmendmentOrdersService.amendmentOrdersProcessOrder({
        command: {
          decision:
            processOrder.decision === OrderDecision.Approve
              ? ProcessOrderDecision.Approve
              : ProcessOrderDecision.Reject,
          orderIds: [processOrder.orderId],
          organizationId: processOrder.organizationId,
          decisionNote: processOrder.decisionNote ?? undefined,
        },
      })
    },
    onSuccess: async (_, variables) => {
      queryClient.setQueryData<OrdersQueryResult[]>(
        ordersByResellerId.queryKey(selectedResellerId),
        oldData => mapUpdateOnDataOnProcessOrder(oldData, variables)
      )
    },
    onError: (error, variables) => {
      throw error
    },
  })
}

export const useOrderStatusBadge = () => {
  const { t } = useTranslation()

  const statusBadgeMapper = {
    [OrderStatus.Failed]: (
      <Badge variant={Variant.Danger}>{t('rsp:pages.orders.status.failed')}</Badge>
    ),
    [OrderStatus.Pending]: (
      <Badge variant={Variant.Warning}>{t('rsp:pages.orders.status.pending')}</Badge>
    ),
    [OrderStatus.Completed]: (
      <Badge variant={Variant.Success}>{t('rsp:pages.orders.status.completed')}</Badge>
    ),
    [OrderStatus.WaitingForApproval]: (
      <Badge variant={Variant.Warning}>{t('rsp:pages.orders.status.waitingForApproval')}</Badge>
    ),
    [OrderStatus.Rejected]: (
      <Badge variant={Variant.Danger}>{t('rsp:pages.orders.status.rejected')}</Badge>
    ),
  }

  return (value?: OrderStatus | null) => (value ? statusBadgeMapper[value] : null)
}

export const useOrderCommentCell = () => {
  const decode = (val?: string | null) => {
    try {
      return decodeURIComponent(encodeURIComponent(val ?? ''))
    } catch (error) {
      console.error(error)
    }
  }

  const formatTooltip = (error: OrderError) => {
    return (
      <>
        {error.title && !error.detail?.includes(error.title) && <p>{decode(error.title)}</p>}
        <p>{decode(error.detail)}</p>
      </>
    )
  }

  return (order: AmendmentOrder) => {
    if (order?.error?.title) {
      return (
        <Tooltip className="max-w-4xl" tooltip={formatTooltip(order.error)}>
          <p className="text-justify">
            {order.error.message}
            <InformationCircleIcon
              data-testid="tooltip-icon"
              className="relative ml-1 inline-block max-h-5 align-bottom"
            />
          </p>
        </Tooltip>
      )
    }

    return <p className="text-left">{order?.error?.message ?? order?.decisionNote}</p>
  }
}
