import { ResellerOrdersService } from '@sherweb/core/openapi-generated/ResellerOrdersService'

export const ordersByResellerId = {
  queryKey: (resellerId?: string) => [
    'ResellerOrdersService/getAvailableOrdersByResellerId',
    resellerId,
  ],
  queryFn: async (resellerId?: string) =>
    resellerId ? await ResellerOrdersService.getAvailableOrdersByResellerId({ resellerId }) : [],
}
