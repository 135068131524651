/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-key */

import { mergeClassName } from '../../utils/mergeClassName'
import Button, { Variant as ButtonVariant } from './../Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './../Dialog'

type ConfirmationDialogProps = {
  open?: boolean
  onClose?: () => void
  onCancel?: () => void
  onConfirm?: () => Promise<void> | void
  title: string
  confirmButtonText?: string | null
  cancelButtonText?: string | null
  children: React.ReactNode
  destructive?: boolean
  className?: string
}

const ConfirmationDialog = ({
  open = false,
  onClose,
  onConfirm,
  onCancel,
  title,
  cancelButtonText,
  confirmButtonText,
  children,
  destructive,
  className,
}: ConfirmationDialogProps) => {
  const closeDialog = () => {
    onClose?.()
  }

  const confirm = async (): Promise<void> => {
    await onConfirm?.()
    onClose?.()
  }

  const cancel = (): void => {
    onClose?.()
    onCancel?.()
  }

  return (
    <Dialog open={open} onOpenChange={closeDialog}>
      <DialogContent className={className ?? ''}>
        {title && (
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
        )}
        {children && (
          <DialogDescription asChild data-testid="dialogDescriptionContent">
            <div className="text-slate-900">{children}</div>
          </DialogDescription>
        )}
        <DialogFooter>
          {cancelButtonText ? (
            <Button
              data-testid="dialogBtnCancel"
              onClick={cancel}
              size="sm"
              variant={ButtonVariant.Outline}
            >
              {cancelButtonText}
            </Button>
          ) : null}
          {confirmButtonText ? (
            <Button
              onClick={async () => {
                await confirm()
              }}
              size="sm"
              data-testid="dialogBtnConfirm"
              variant={ButtonVariant.Primary}
              className={mergeClassName(destructive && 'bg-none bg-red-600 hover:bg-red-500')}
            >
              {confirmButtonText}
            </Button>
          ) : null}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmationDialog
