import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import { Permission } from '@sherweb/core/modules/authorization'

import { protectPage } from '@ssp/app/ProtectedPage'
import Routes from '@ssp/app/Routes'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import Link from '@ssp/components/Link'
import ServerError from '@ssp/components/ServerError/ServerError'
import { HasAccess } from '@ssp/modules/authorization'
import { useUsersByOrganization } from '@ssp/modules/user'

import UsersTable from './UsersTable/UsersTable'
import UsersTableMobile from './UsersTableMobile'

const UsersPage: React.FC = () => {
  const { t } = useTranslation()
  const { isFetching, error, isSuccess, data: users = [] } = useUsersByOrganization()
  const noUsers = isSuccess && !error && !users?.length

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={<PageTitle data-testid="pageUsersTitle">{t('ssp:pages.users.title')}</PageTitle>}
          subTitle={<PageSubTitle>{t('ssp:pages.users.pageDescription')}</PageSubTitle>}
        />
        {isFetching && <Spinner floating />}
        {error && <ServerError error={error} />}

        {noUsers && (
          <div className="flex flex-col gap-4">
            <div className="flex w-full justify-end">
              <HasAccess permission={Permission.UsersCreate}>
                <Button variant="primary" onClick={() => {}} asChild data-testid="btnAddUser">
                  <Link to={Routes.UsersInvite}>{t('ssp:pages.users.inviteUser')}</Link>
                </Button>
              </HasAccess>
            </div>
            <div
              className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
              data-testid="containerNoUsers"
            >
              <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" />
              <p>{t('ssp:pages.users.noUsers')}</p>
            </div>
          </div>
        )}

        {users?.length > 0 && (
          <>
            <div className="hidden lg:block" data-testid="containerUsersTable">
              <UsersTable />
            </div>
            <div className="mt-4 lg:hidden" data-testid="containerUsersTableMobile">
              <UsersTableMobile />
            </div>
          </>
        )}
      </div>
    </ContainedPage>
  )
}

export default protectPage(UsersPage, Permission.UsersList)
