import { QueryKey } from '@tanstack/react-query'

export const localizedKey = 'Localized'

const filterEmptyValues = (keys: Array<string | undefined>) => {
  return keys.filter((key): key is string => !!key)
}

export const getLocalizedQueryKey = (keys: Array<string | undefined>): QueryKey => {
  return filterEmptyValues([localizedKey, ...keys])
}
