import clsx from 'clsx'
import { Link as ReactRouterLink } from 'react-router-dom'

import { useLocation, usePathGenerator } from '@rsp/modules/navigation'

type ReactLink = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

export type LinkProps = {
  to: string
  isAbsolute?: boolean
  currentClassName?: string
  params?: Record<string, string>
} & Omit<ReactLink, 'ref'>

const Link: React.FC<LinkProps> = ({
  to,
  children,
  currentClassName,
  isAbsolute = false,
  params,
  ...linkProps
}) => {
  const location = useLocation()
  const generatePath = usePathGenerator()
  const current = location.pathname === to

  if (isAbsolute) {
    return (
      <a href={to} {...linkProps}>
        {children}
      </a>
    )
  }

  return (
    <ReactRouterLink
      to={generatePath(to, params)}
      className={clsx(linkProps.className, current && currentClassName)}
      {...linkProps}
    >
      {children}
    </ReactRouterLink>
  )
}

export default Link
