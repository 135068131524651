import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { UserStatus } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import Card from '@sherweb/core/components/Card'
import ConfirmationDialog from '@sherweb/core/components/ConfirmationDialog'
import DataTable, { Columns } from '@sherweb/core/components/DataTable'
import { Permission } from '@sherweb/core/modules/authorization'
import { useDateFormatter } from '@sherweb/core/utils/date'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess } from '@ssp/modules/authorization'
import { User, UserRole, useUserRolesTranslation, useUserStatusBadge } from '@ssp/modules/user'
import { useDeleteUser, useUsersByOrganization } from '@ssp/modules/user/core/user.hooks'

import { UserTableActionRow, UserTableActionRowProps } from './UserTableActionRow'
import { UserTableNameRow, UserTableNameRowProps } from './UserTableNameRow'

const CLASSNAME = 'text-center'

const renderEmailRowContent = ({ firstName, lastName, email }: UserTableNameRowProps) => (
  <UserTableNameRow firstName={firstName} lastName={lastName} email={email} />
)

const renderActionRowContent = ({
  user,
  setDeleting,
  setSelectedUser,
}: UserTableActionRowProps) => (
  <UserTableActionRow user={user} setDeleting={setDeleting} setSelectedUser={setSelectedUser} />
)

const UsersTable: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { isLoading, error, data: users } = useUsersByOrganization()
  const getUserRoles = useUserRolesTranslation()
  const [deleting, setDeleting] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User>()
  const { deleteUser } = useDeleteUser()
  const formatDate = useDateFormatter(language)

  const userStatusBadge = useUserStatusBadge()

  const columns: Columns<User> = [
    {
      id: 'email',
      accessorFn: row => `${row.firstName ?? ''} ${row.lastName ?? ''}  ${row.email ?? ''}`,
      header: t('ssp:pages.users.list.columns.user'),
      enableSorting: true,
      cell: ({ cell, row }) =>
        renderEmailRowContent({
          firstName: row.original.firstName,
          lastName: row.original.lastName,
          email: row.original.email ?? '',
        }),
      minSize: 417,
    },
    {
      accessorKey: 'jobTitle',
      header: t('ssp:pages.users.list.columns.jobTitle'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      minSize: 278,
    },
    {
      accessorKey: 'roles',
      header: t('ssp:pages.users.list.columns.roles'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      cell: ({ getValue }) => getUserRoles(getValue<UserRole[]>()),
      minSize: 278,
    },
    {
      accessorKey: 'createdAt',
      header: t('ssp:pages.users.list.columns.createdOn'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      cell: ({ getValue }) => formatDate(getValue<string>()),
      minSize: 278,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      enableSorting: true,
      meta: { className: CLASSNAME },
      cell: ({ getValue }) => userStatusBadge(getValue<UserStatus>()),
      minSize: 278,
    },
    {
      id: 'actions',
      cell: ({ row }) =>
        renderActionRowContent({
          user: row.original,
          setDeleting,
          setSelectedUser,
        }),
      size: 139,
    },
  ]

  if (isLoading || error) {
    return null
  }

  return (
    <Card padded>
      <DataTable
        rowsPerPage={5}
        rowsPerPageOptions={[5]}
        columns={columns}
        data={users}
        dataTestId="tableUsers"
        filterableFields={['email', 'jobTitle']}
        filterPlaceholder={t('ssp:pages.users.list.filterPlaceholder')}
        fieldDescription={t('ssp:pages.users.list.searchFieldDescription')}
        optionalActions={
          <HasAccess permission={Permission.UsersCreate}>
            <Button variant="primary" data-testid="btnAddUser" size="sm" onClick={() => {}} asChild>
              <Link to={Routes.UsersInvite} className="rounded-lg focus-visible:outline-indigo-300">
                {t('ssp:pages.users.inviteUser')}
              </Link>
            </Button>
          </HasAccess>
        }
      />
      {selectedUser && (
        <ConfirmationDialog
          open={deleting}
          title={t('ssp:pages.users.userDeletionDialog.title')}
          onClose={() => setDeleting(false)}
          onConfirm={() => {
            deleteUser(selectedUser.id)
          }}
          confirmButtonText={t('ssp:actions.delete')}
          cancelButtonText={t('ssp:actions.cancel')}
          destructive
        >
          <Trans
            i18nKey="ssp:pages.users.userDeletionDialog.description"
            values={{
              user:
                selectedUser.email ??
                `${selectedUser?.firstName ?? ''} ${selectedUser.lastName ?? ''}`,
            }}
          />
        </ConfirmationDialog>
      )}
    </Card>
  )
}

export default UsersTable
