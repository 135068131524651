import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CommitmentTermType } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import {
  useBillingCycleTranslation,
  useCommitmentTermTranslation,
} from '@ssp/modules/shop/core/shop.helpers'
import { ShopOffer } from '@ssp/modules/shop/core/shop.model'

type ShopProductSubscriptionDetailsProps = {
  offersByCommitmentTerm?: Record<CommitmentTermType, ShopOffer[]>
  sku: string
  selectedCommitmentTerm: string
  selectedOffer?: ShopOffer
}

const ShopProductSubscriptionDetails = ({
  offersByCommitmentTerm,
  selectedCommitmentTerm,
  sku,
  selectedOffer,
}: ShopProductSubscriptionDetailsProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const formatMoneyAmount = useMoneyFormatter(language)

  const { setSearchParams } = useQueryParams<'productCommitment' | 'productSku'>()

  const [availableOffers, setAvailableOffers] = useState<ShopOffer[]>([])

  const getBillingCycleTranslation = useBillingCycleTranslation()
  const getCommitmentTermTranslation = useCommitmentTermTranslation()

  useEffect(() => {
    if (!selectedCommitmentTerm && offersByCommitmentTerm) {
      const firstKey = Object.keys(offersByCommitmentTerm).find(
        key => offersByCommitmentTerm[key as CommitmentTermType].length > 0
      )

      if (firstKey) {
        setSearchParams('productCommitment', firstKey)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offersByCommitmentTerm, selectedCommitmentTerm])

  useEffect(() => {
    if (selectedCommitmentTerm && offersByCommitmentTerm) {
      setAvailableOffers(offersByCommitmentTerm[selectedCommitmentTerm as CommitmentTermType])
    }
  }, [selectedCommitmentTerm, offersByCommitmentTerm])

  useEffect(() => {
    if (availableOffers && availableOffers.length > 0 && !sku) {
      setSearchParams('productSku', availableOffers[0].sku)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableOffers])

  return (
    <>
      <Typography variant="heading6" weight="bold" className="text-wrap p-6 pb-0">
        {t('ssp:pages.shop.detail.commitmentTerm')}
      </Typography>
      <div className="flex justify-between p-6 pb-0">
        {offersByCommitmentTerm &&
          Object.keys(offersByCommitmentTerm).map(term => {
            const offers = offersByCommitmentTerm[term as CommitmentTermType]

            if (offers.length === 0) {
              return null
            }

            return (
              <Button
                className={mergeClassName({
                  'border-2 border-indigo-600 dark:border-indigo-200':
                    selectedCommitmentTerm === term,
                })}
                key={term}
                variant="outline"
                size="sm"
                type="button"
                onClick={() => {
                  setSearchParams('productCommitment', term)
                  setSearchParams('productSku', '')
                }}
              >
                {getCommitmentTermTranslation(term as CommitmentTermType)}
              </Button>
            )
          })}
      </div>
      <Typography variant="heading6" weight="bold" className="text-wrap p-6 pb-0">
        {t('ssp:pages.shop.detail.billingCycle')}
      </Typography>
      <div className="flex justify-between p-6 pb-0">
        {availableOffers.map(offer => {
          const formattedPrice = formatMoneyAmount(offer.price)

          return (
            <Button
              className={mergeClassName({
                'border-2 border-indigo-600 dark:border-indigo-200':
                  selectedOffer?.sku === offer.sku,
              })}
              key={`${offer.sku ?? ''}-billing-cycle`}
              variant="outline"
              size="sm"
              type="button"
              onClick={() => {
                setSearchParams('productSku', offer?.sku)
              }}
            >
              <div className="flex flex-col">
                <Typography variant="body2" weight="bold" as="div">
                  {getBillingCycleTranslation(offer.billingCycle)}
                </Typography>
                <div>{formattedPrice}</div>
              </div>
            </Button>
          )
        })}
      </div>
    </>
  )
}

export default ShopProductSubscriptionDetails
