import { useTranslation } from 'react-i18next'

import DataTable, { Columns } from '@sherweb/core/components/DataTable'
import { useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import {
  AmendmentOrder,
  useOrderCommentCell,
  useOrders,
  useOrderStatusBadge,
} from '@rsp/modules/orders'

import {
  OrdersTableCreatedByNameRow,
  OrdersTableCreatedByNameRowProps,
} from './OrdersTableProductNameRow'
import { OrdersTableRowActions } from './OrdersTableRowActions'

const renderCreatedByNameRowContent = ({
  firstName,
  lastName,
  email,
}: OrdersTableCreatedByNameRowProps) => (
  <OrdersTableCreatedByNameRow firstName={firstName} lastName={lastName} email={email} />
)

const renderActionsDropdown = (order: AmendmentOrder) => <OrdersTableRowActions order={order} />

const renderId = (orderId: string) => <div className="font-medium">{orderId}</div>

const renderOrderValue = ({ orderValue }: { orderValue?: string | null }) => (
  <div className="whitespace-nowrap">{orderValue}</div>
)

const defaultMeta = { className: 'align-top' }

const OrdersTable: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const formatDate = useDateFormatter(language)
  const formatMoneyAmount = useMoneyFormatter(language)
  const getOrderStatusBadge = useOrderStatusBadge()
  const getOrderCommentCell = useOrderCommentCell()
  const { data: orders = [] } = useOrders()

  const columns: Columns<AmendmentOrder> = [
    {
      id: 'humanReadableId',
      accessorFn: row => row.humanReadableId,
      meta: { ...defaultMeta },
      header: t('rsp:pages.orders.list.orderId'),
      cell: ({ row }) => renderId(row.original.humanReadableId ?? ''),
      minSize: 100,
    },

    {
      accessorKey: 'organizationName',
      header: t('rsp:pages.orders.list.organizationName'),
      meta: { className: 'align-top' },
      cell: ({ row }) => row.original.organizationName,
      minSize: 200,
    },
    {
      accessorKey: 'createdOn',
      header: t('rsp:pages.orders.list.createdOn'),
      meta: { ...defaultMeta },
      cell: ({ row }) => formatDate(row.original.createdOn),
      minSize: 150,
    },
    {
      id: 'createdBy',
      accessorFn: row =>
        `${row.userFirstName ?? ''} ${row.userLastName ?? ''}  ${row.userEmail ?? ''}`,
      meta: { ...defaultMeta },
      header: t('rsp:pages.orders.list.createdBy'),
      cell: ({ row }) =>
        renderCreatedByNameRowContent({
          firstName: row.original.userFirstName ?? '',
          lastName: row.original.userLastName ?? '',
          email: row.original.userEmail ?? '',
        }),
      minSize: 180,
    },
    {
      accessorKey: 'orderValue',
      meta: { ...defaultMeta },
      header: t('rsp:pages.orders.list.orderValue'),
      cell: ({ row }) =>
        renderOrderValue({
          orderValue: row.original.orderValue ? formatMoneyAmount(row.original.orderValue) : null,
        }),
      minSize: 100,
    },
    {
      accessorKey: 'orderStatus',
      meta: { className: `${defaultMeta.className} text-center` },
      header: t('rsp:pages.orders.list.status'),
      cell: ({ row }) => getOrderStatusBadge(row.original.orderStatus),
      minSize: 60,
    },
    {
      accessorKey: 'comment',
      meta: { className: `${defaultMeta.className} text-center` },
      header: t('rsp:pages.orders.list.comment'),
      cell: ({ row }) => getOrderCommentCell(row.original),
      minSize: 100,
    },
    {
      accessorKey: 'actions',
      meta: { ...defaultMeta },
      header: String(),
      enableSorting: false,
      cell: ({ row }) => renderActionsDropdown(row.original),
      minSize: 20,
    },
  ]

  return (
    <DataTable
      dataTestId="tableOrders"
      columns={columns}
      data={orders}
      filterableFields={['humanReadableId', 'organizationName', 'createdBy']}
      filterPlaceholder={t('rsp:pages.orders.list.filterPlaceholder')}
      fieldDescription={t('rsp:pages.orders.list.searchFieldDescription')}
    />
  )
}

export default OrdersTable
