import { useTranslation } from 'react-i18next'

import Card, { CardMetric, CardTitle } from '@sherweb/core/components/Card'
import Spinner from '@sherweb/core/components/Spinner/Spinner'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link/Link'
import ServerError from '@ssp/components/ServerError/ServerError'
import { useSubscriptionCount } from '@ssp/modules/subscription'

const SubscriptionCountWidget = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: countResult } = useSubscriptionCount()

  return (
    <>
      {error && <ServerError error={error} />}
      {countResult && (
        <Card padded={false} className="flex min-h-[160px] flex-col justify-between p-6">
          <CardTitle>{t('ssp:pages.dashboard.widgets.SubscriptionCountWidget.title')}</CardTitle>
          <CardMetric data-testid="cardNumberSubscriptions" className="mt-4 flex flex-auto">
            {!(isLoading || countResult.isRecalculating) && <span>{countResult.count}</span>}
            {(isLoading || countResult.isRecalculating) && <Spinner className="h-4 w-4" />}
          </CardMetric>
          <Link
            className="inline w-fit text-sm text-indigo-600 underline focus-visible:outline-indigo-300"
            to={Routes.Subscriptions}
            data-testid="linkViewSubscriptions"
          >
            {t('ssp:pages.dashboard.widgets.SubscriptionCountWidget.viewAll')}
          </Link>
        </Card>
      )}
    </>
  )
}

export default SubscriptionCountWidget
