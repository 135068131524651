import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import { useDateFormatter } from '@sherweb/core/utils/date'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import {
  AmendmentOrder,
  useOrderCommentCell,
  useOrders,
  useOrderStatusBadge,
} from '@rsp/modules/orders'

import { OrderActions } from './OrderActions'

const OrdersTableMobile = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const formatDate = useDateFormatter(language)
  const { data: orders = [] } = useOrders()
  const formatMoneyAmount = useMoneyFormatter(language)
  const getOrderStatusBadge = useOrderStatusBadge()
  const getOrderCommentCell = useOrderCommentCell()
  const [filteredText, setFilteredText] = useState('')

  const filteredData = orders.filter((item: AmendmentOrder) => {
    const fullName = `${item.userFirstName ?? ''} ${item.userLastName ?? ''}`.toLowerCase()

    return (
      fullName?.includes(filteredText.toLowerCase()) ||
      item.organizationName?.toLowerCase().includes(filteredText.toLowerCase())
    )
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="relative mb-4 flex w-full items-center md:mb-0 md:w-1/4 md:min-w-[250px]">
        <MagnifyingGlassIcon
          className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
          aria-hidden="true"
        />
        <input
          className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
          type="text"
          value={filteredText}
          data-testid="inputFilterTableMobile"
          placeholder={t('rsp:pages.orders.list.filterPlaceholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilteredText(event.target.value)
          }}
        />
      </div>
      {filteredData.map((order: AmendmentOrder) => {
        return (
          <Accordion key={order.id} type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {order.organizationName} - {order.humanReadableId}
                <AccordionTriggerSubContent>
                  {getOrderStatusBadge(order.orderStatus)}
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.createdOn')}
                    value={formatDate(order?.createdOn)}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.organizationName')}
                    value={order?.organizationName}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.createdBy')}
                    value={
                      order?.userFirstName && order?.userLastName
                        ? order?.userFirstName + ' ' + order?.userLastName
                        : ''
                    }
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.orderValue')}
                    value={order.orderValue ? formatMoneyAmount(order.orderValue) : null}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.status')}
                    value={getOrderStatusBadge(order.orderStatus)}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.orders.list.comment')}
                    value={getOrderCommentCell(order)}
                  />
                  <AccordionContentListItem label="" value={<OrderActions order={order} />} />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })}
    </div>
  )
}

export default OrdersTableMobile
