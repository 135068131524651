import { useQuery } from '@tanstack/react-query'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'

import { useSelectedOrganization } from '@ssp/modules/organization'

import { ordersByOrganizationId } from './orders.queries'

export const useOrders = () => {
  const { isLoggedIn } = useAuthenticationLoggedInState()
  const selectedOrganization = useSelectedOrganization()
  const organizationId = selectedOrganization?.id

  return useQuery({
    queryKey: ordersByOrganizationId.queryKey(organizationId),
    queryFn: async () => await ordersByOrganizationId.queryFn(organizationId),
    enabled: isLoggedIn && !!organizationId,
  })
}
