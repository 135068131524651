import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { Form } from '@sherweb/core/components/Form'
import Spinner from '@sherweb/core/components/Spinner'

import { protectPage } from '@rsp/app/ProtectedPage'
import { FormSubmitButton } from '@rsp/components/FormSubmitButton'
import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'
import {
  useGetConnectWiseApiInformationQuery,
  useGetConnectWiseConfiguredQuery,
  usePostConnectWiseApiInformationMutation,
} from '@rsp/modules/integrations/helpdesk'

import { DEFAULT_VALUES } from './apiInformation.helper'
import { ApiInformationCard } from './ApiInformationCard'
import { ApiInformationEnabled } from './ApiInformationEnabler'
import { ApiInformationForm } from './ApiInformationForm'
import { useApiInformation } from './hooks/useApiInformation'
import { ApiInformationFormType, useApiInformationSchema } from './hooks/useApiInformationSchema'

export const ApiInformation = () => {
  const connectWiseConfiguredQuery = useGetConnectWiseConfiguredQuery()

  const connectWiseApiInformationQuery = useGetConnectWiseApiInformationQuery()

  const connectWiseApiInformationMutation = usePostConnectWiseApiInformationMutation()

  const schema = useApiInformationSchema()

  const form = useForm<ApiInformationFormType>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES,
  })

  const hasData = !!connectWiseApiInformationQuery?.data?.enabled

  const { isEditMode, setIsEditMode, setFormValues, onEditMode } = useApiInformation({
    form,
  })

  useEffect(() => {
    if (connectWiseApiInformationQuery?.data) {
      setFormValues(connectWiseApiInformationQuery?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectWiseApiInformationQuery?.data])

  useEffect(() => {
    if (connectWiseApiInformationMutation?.isSuccess) {
      setIsEditMode(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectWiseApiInformationMutation?.isSuccess])

  const onSubmit = (values: ApiInformationFormType) => {
    const updatedValues = !values.enabled ? { enabled: false } : values

    connectWiseApiInformationMutation.mutate(updatedValues)
  }

  if (connectWiseConfiguredQuery?.isLoading) {
    return <Spinner floating />
  }

  if (
    connectWiseConfiguredQuery?.isEnabled &&
    (connectWiseApiInformationQuery?.isLoading || !connectWiseApiInformationQuery?.data)
  ) {
    return <Spinner floating />
  }

  return (
    <Form {...form}>
      <FormSplitScreen onSubmit={form.handleSubmit(onSubmit)}>
        <ApiInformationEnabled />
        {hasData && !isEditMode ? (
          <ApiInformationCard
            apiInformation={connectWiseApiInformationQuery?.data}
            onEditMode={onEditMode}
          />
        ) : (
          <ApiInformationForm
            isEditMode={isEditMode}
            onEditMode={onEditMode}
            apiInformation={connectWiseApiInformationQuery?.data}
            apiInformationMutation={connectWiseApiInformationMutation}
          />
        )}
        <FormSubmitButton
          showLoader={connectWiseApiInformationMutation?.isLoading}
          disabled={connectWiseApiInformationMutation?.isLoading}
        />
      </FormSplitScreen>
    </Form>
  )
}

export default protectPage(ApiInformation, [
  Permission.IntegrationConfigure,
  Permission.IntegrationList,
])
