import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon'
import { Loader2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import Tooltip from '@sherweb/core/components/Tooltip'

type CreateSubscriberFormActionsProps = {
  isDisabled?: boolean
  isUpdating?: boolean
  onCancel: () => void
}

export const CreateSubscriberFormActions = ({
  isDisabled,
  isUpdating,
  onCancel,
}: CreateSubscriberFormActionsProps) => {
  const { t } = useTranslation()

  return (
    <div className="mt-4 grid w-full grid-flow-col">
      <div className="flex gap-4 sm:flex-row sm:justify-between">
        <Button
          variant="outline"
          className="w-full"
          size="sm"
          type="button"
          data-testid="btnCancelCreateSubscriber"
          onClick={() => {
            onCancel()
          }}
        >
          {t('ssp:actions.cancel')}
        </Button>
        <Button
          type="submit"
          className="w-full"
          disabled={isDisabled}
          data-testid="btnCreateSubscriber"
        >
          {isUpdating && <Loader2 className="h-4 w-full animate-spin" />}
          {isDisabled ? (
            <Tooltip
              data-testid="tooltip"
              tooltip={t('ssp:pages.licenses.subscribersDialog.confirmationButtonDisableMessage')}
            >
              <span>{t('ssp:pages.licenses.subscribersDialog.createSubscriberButton')}</span>
              <InformationCircleIcon
                data-testid="information-circle-icon"
                className="ml-1 h-5 w-5 stroke-1 dark:stroke-2"
              />
            </Tooltip>
          ) : (
            <div>{t('ssp:pages.licenses.subscribersDialog.createSubscriberButton')}</div>
          )}
        </Button>
      </div>
    </div>
  )
}
