import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Card from '@sherweb/core/components/Card/Card'
import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner/Spinner'

import { protectPage } from '@ssp/app/ProtectedPage'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import { useOrders } from '@ssp/modules/orders'

import OrdersTable from './OrdersTable/OrdersTable'
import OrdersTableMobile from './OrdersTableMobile/OrdersTableMobile'

const OrdersPage = () => {
  const { t } = useTranslation()
  const orders = useOrders()
  const noOrders = orders.isFetched && !orders.isLoading && !orders.error && !orders?.data?.length

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={<PageTitle data-testid="pageOrdersTitle">{t('ssp:pages.orders.title')}</PageTitle>}
          subTitle={<PageSubTitle>{t('ssp:pages.orders.subtitle')}</PageSubTitle>}
        />
        {orders.isLoading && <Spinner floating />}

        {noOrders && (
          <div
            className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
            data-testid="containerNoOrders"
          >
            <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" />
            <p>{t('ssp:pages.orders.noOrder')}</p>
          </div>
        )}

        {(orders?.data?.length ?? 0) > 0 && (
          <>
            <div className="hidden lg:block">
              <Card padded>
                <OrdersTable />
              </Card>
            </div>

            <div className="mt-4 lg:hidden">
              <Card padded>
                <OrdersTableMobile />
              </Card>
            </div>
          </>
        )}
      </div>
    </ContainedPage>
  )
}

export default protectPage(OrdersPage, Permission.OrderList)
