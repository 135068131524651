import { QueryKey } from '@tanstack/react-query'

import { ProductsService } from '@sherweb/core/openapi-generated/ProductsService'

import { getLocalizedQueryKey } from '@sherweb/core/modules/reactQuery'
import { DEFAULT_STALE_TIME } from '@sherweb/core/utils/const'
import { rejectMissingParameters } from '@sherweb/core/utils/error'

import { DEFAULT_PAGE_SIZE } from './shop.const'
import { ISearchOptions } from './shop.types'

export const search = {
  queryKey: (searchOptions: ISearchOptions, organizationId = '') => [
    'ProductsService/searchProducts',
    organizationId,
    searchOptions,
  ],
  queryFn: async (
    { filters, sortBy, query }: ISearchOptions,
    pageParam: number,
    organizationId = ''
  ) => {
    if (!organizationId || organizationId === '') {
      return await rejectMissingParameters()
    }

    return await ProductsService.searchProducts({
      command: {
        sortBy,
        query,
        filters,
        organizationId,
        pageSize: DEFAULT_PAGE_SIZE,
        page: pageParam,
      },
    })
  },
  staleTime: DEFAULT_STALE_TIME,
}

export const productById = {
  queryKey: (productId?: string): QueryKey =>
    getLocalizedQueryKey(['ProductsService/getProductById', productId]),
  queryFn: async (productId?: string) => {
    if (!productId) {
      return await rejectMissingParameters()
    }

    return await ProductsService.getProductById({ id: productId })
  },
  staleTime: 5 * 1000,
}
