import { BuildingOffice2Icon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Money, SimpleProduct } from '@sherweb/core/openapi-generated/index.defs'

import Badge from '@sherweb/core/components/Badge'
import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import Routes from '@ssp/app/Routes'
import { usePathGenerator } from '@ssp/modules/navigation'
import { useGetShopRouteParams } from '@ssp/modules/shop/core/shop.helpers'

type ShopProductsCardProps = {
  product: SimpleProduct
}

export const ShopProductsCard = ({ product }: ShopProductsCardProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { searchParams } = useQueryParams()

  const { removeAllQueryParams } = useQueryParams()

  const pathGenerator = usePathGenerator()

  const formatMoneyAmount = useMoneyFormatter<Money>(language)

  const navigate = useNavigate()

  const shopRouteParams = useGetShopRouteParams({ shopProductId: product.id })

  const handleGoToProductDetailPage = () => {
    removeAllQueryParams()
    navigate(pathGenerator(Routes.ShopProductDetail, shopRouteParams), {
      state: {
        previousFilterParams: searchParams.toString(),
      },
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleGoToProductDetailPage()
    }
  }

  return (
    <Card
      key={product.id}
      className="flex min-h-48 flex-col justify-between hover:cursor-pointer focus-visible:outline-indigo-300 md:p-4"
      data-testid="product"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleGoToProductDetailPage}
    >
      <div className="flex h-full flex-col justify-between gap-2">
        <div>
          <CardTitle className="min-h-12 text-slate-900 dark:text-slate-200">
            <Typography
              variant="body1"
              weight="semiBold"
              className="line-clamp-3 text-balance sm:line-clamp-2"
              data-testid="productName"
            >
              {product.name}
            </Typography>
          </CardTitle>
          <div className="flex flex-wrap items-center gap-x-2">
            <BuildingOffice2Icon className="mt-1 h-4 w-4" />
            <Typography variant="body2" colors="light" className="mt-1" data-testid="productVendor">
              {product.vendor}
            </Typography>
            <div className="mt-1 flex items-center gap-x-2">
              {product.categories?.map(category => (
                <Badge data-testid="productCategories" key={category} className="dark:bg-slate-200">
                  {category}
                </Badge>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Typography variant="body1" weight="semiBold" data-testid="productPrice">
            {product.startingPrice && formatMoneyAmount(product.startingPrice)}
          </Typography>
          <Typography variant="body2" colors="light">
            {t('ssp:pages.shop.card.startingPrice')}
          </Typography>
        </div>
      </div>
    </Card>
  )
}
