import { InformationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'

import { DebouncedSearchInput } from '../DebouncedSearchInput'
import Tooltip from '../Tooltip'

type FilterHeaderProps = {
  value: string
  onChange: (value: string) => void
  filterPlaceholder?: string
  fieldDescription?: string
}

const FilterHeader: React.FC<FilterHeaderProps> = ({
  value,
  onChange,
  filterPlaceholder,
  fieldDescription,
}) => {
  return (
    <div className="relative flex w-full items-center md:mb-0 md:w-1/4 md:min-w-[275px]">
      <MagnifyingGlassIcon
        className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
        aria-hidden="true"
      />
      <DebouncedSearchInput
        type="text"
        className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 focus-visible:outline-offset-0 focus-visible:outline-indigo-300 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
        placeholder={filterPlaceholder}
        value={value}
        onChange={onChange}
        data-testid="inputFilterTable"
      />
      <div className="justify-content absolute right-3 flex items-center">
        <Tooltip tooltip={fieldDescription} className="ml-8">
          <InformationCircleIcon
            className="h-4 w-4 text-gray-500 focus-within:text-gray-600"
            aria-hidden="true"
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default FilterHeader
