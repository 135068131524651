import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { CoreRoutes } from '@sherweb/core/common/coreRoutes'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import {
  useAuthenticationLoggedInState,
  useAuthenticationState,
} from '@sherweb/core/modules/authentication'
import { Permission } from '@sherweb/core/modules/authorization'

import { usePermissionCheck } from '@rsp/modules/authorization'
import { useNavigate } from '@rsp/modules/navigation'

import Routes from '../Routes'

type ProtectedPageProps = {
  Page: React.FC
  RequiredPermission?: Permission | Permission[]
}

const ProtectedPage: React.FC<ProtectedPageProps> = ({ Page, RequiredPermission = [] }) => {
  const { isAuthenticated } = useAuthenticationState()

  const { isLoggedIn, isLoading, isFetching } = useAuthenticationLoggedInState()

  const { navigate } = useNavigate()

  const location = useLocation()

  const { hasAccess, permissionsLoading, permissionsFetched } = usePermissionCheck()

  useEffect(() => {
    const relativePath = CoreRoutes.AdminForbiddenAccess

    const currentPermissions = Array.isArray(RequiredPermission)
      ? RequiredPermission
      : [RequiredPermission]

    if (
      !isAuthenticated &&
      !isLoggedIn &&
      !(isLoading || isFetching) &&
      location.pathname !== Routes.Login
    ) {
      navigate(Routes.Login)
    }

    if (
      permissionsFetched &&
      !window.location.href.includes(relativePath) &&
      isLoggedIn &&
      !permissionsLoading &&
      !hasAccess([Permission.ResellerAdmin, ...currentPermissions])
    ) {
      navigate(relativePath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoggedIn, permissionsFetched, permissionsLoading, isLoading])

  if (isAuthenticated) {
    return <Page />
  }

  return <Spinner floating />
}

export const protectPage = (page: React.FC, permission?: Permission | Permission[]) => {
  return () => <ProtectedPage Page={page} RequiredPermission={permission} />
}
