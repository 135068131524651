// based on: https://ui.shadcn.com/docs/components/data-table#installation

import { rankings, rankItem } from '@tanstack/match-sorter-utils'
import {
  ColumnDef,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useState } from 'react'

import { getDataTestId } from '@sherweb/core/utils/string'

import { DataTestId } from '../../types/dataTestIdType'
import { mergeClassName } from '../../utils/mergeClassName'
import { NoResults } from '../NoResults'
import { Table, TableBody, TableCell, TableHeader, TableRow } from '../Table'
import FilterHeader from './FilterHeader'
import Header from './Header'
import Pagination from './Pagination'

export type Columns<TData> = Array<ColumnDef<TData>>

const DEFAULT_ROWS_PER_PAGE = 20
const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 20, 50, 100]

export type DataTableProps<TColumns, TData> = {
  columns: Array<ColumnDef<TData, TColumns>>
  data?: TData[]
  filterableFields?: string[]
  rowsPerPage?: number
  rowsPerPageOptions?: number[]
  emptyMessage?: React.ReactNode
  filterPlaceholder?: string
  fieldDescription?: string
  className?: string
  optionalActions?: React.ReactNode
  disableHeaders?: boolean
  disableFilterHeader?: boolean
}

const DataTable = <TColumns, TData>({
  columns,
  data = [],
  filterableFields = [],
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  rowsPerPageOptions = DEFAULT_ROWS_PER_PAGE_OPTIONS,
  emptyMessage,
  filterPlaceholder,
  fieldDescription,
  className,
  optionalActions,
  disableHeaders,
  disableFilterHeader = false,
  dataTestId,
}: DataTableProps<TColumns, TData> & DataTestId) => {
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const minimalResultsPerPage = Math.min(...rowsPerPageOptions)

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    if (!filterableFields.includes(columnId)) {
      return false
    }
    const itemRank = rankItem(row.getValue(columnId), value, { threshold: rankings.CONTAINS })
    addMeta({
      itemRank,
    })

    return itemRank.passed
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    state: {
      sorting,
      globalFilter,
    },
    initialState: {
      pagination: {
        pageSize: rowsPerPage,
      },
    },
    defaultColumn: {
      minSize: 64,
    },
  })

  return (
    <>
      {!disableFilterHeader && (
        <div className="mb-8 flex items-center justify-between">
          {filterableFields.length > 0 && (
            <FilterHeader
              value={globalFilter}
              onChange={setGlobalFilter}
              filterPlaceholder={filterPlaceholder}
              fieldDescription={fieldDescription}
            />
          )}
          {optionalActions}
        </div>
      )}

      <div
        className={mergeClassName(
          'overflow-hidden dark:border-zinc-700 md:max-w-[calc(100vw-340px)]',
          className
        )}
      >
        <Table>
          {!disableHeaders && (
            <TableHeader>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <Header key={header.id} {...header} />
                  ))}
                </TableRow>
              ))}
            </TableHeader>
          )}

          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => (
                <TableRow
                  key={row.id}
                  className="align-top dark:border-transparent dark:bg-transparent"
                >
                  {row.getVisibleCells().map(cell => (
                    <TableCell
                      key={cell.id}
                      {...getDataTestId(cell.id.substring(2), dataTestId)}
                      className={mergeClassName(
                        'px-8 py-5',
                        (cell.column.columnDef.meta as any)?.className,
                        (cell.column.columnDef.meta as any)?.cellClassName
                      )}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="bg-transparent p-4 dark:bg-transparent dark:text-white"
                >
                  <NoResults dataTestId={dataTestId} emptyMessage={emptyMessage} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {data.length > minimalResultsPerPage && (
          <Pagination table={table} rowsPerPageOptions={rowsPerPageOptions} />
        )}
      </div>
    </>
  )
}

export default DataTable
