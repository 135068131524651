import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'

import {
  UnusedLicensesStatistics as ApiUnusedLicensesStatistics,
  UsedLicenseStatistics as ApiUsedLicenseStatistics,
  ErroneousLicenseUpdateDto,
  LicensesByVendorQueryResult,
  SubscribersByVendorQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import { useSelectedOrganization, useSelectedOrganizationId } from '@ssp/modules/organization'

import {
  buildDomainsByVendor,
  buildLicensesByVendor,
  buildSubscribersByVendor,
  buildUnusedLicenseStatistics,
  buildUsedLicenseStatistics,
} from './license.builder'
import {
  DomainByVendor,
  License,
  Subscriber,
  UnusedLicenseStatistics,
  UsedLicenseStatistics,
} from './license.model'
import { dismissLicenseUpdate, refreshData, updateLicenseSubscribers } from './license.mutations'
import {
  erroneousLicenseUpdatesByOrganizationId,
  licensesByOrganizationId,
  subscribersByOrganizationId,
  unusedLicenseStatsByOrganizationId,
  usedLicenseStatsByOrganizationId,
} from './license.queries'
import { UpdateLicenseSubscribersPayload } from './license.type'

export const useUsedLicenseStatistics = () => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery<ApiUsedLicenseStatistics, Error, UsedLicenseStatistics>({
    queryKey: usedLicenseStatsByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () => await usedLicenseStatsByOrganizationId.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    initialData: () => buildUsedLicenseStatistics(),
    select: data => buildUsedLicenseStatistics(data),
    refetchInterval: (data, _) => {
      if (!data || data.isRecalculating) {
        return 1000
      }
      return usedLicenseStatsByOrganizationId.staleTime
    },
  })
}

export const useUnusedLicenseStatistics = () => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery<ApiUnusedLicensesStatistics, Error, UnusedLicenseStatistics>({
    queryKey: unusedLicenseStatsByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () => await unusedLicenseStatsByOrganizationId.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    initialData: () => buildUnusedLicenseStatistics(),
    select: data => buildUnusedLicenseStatistics(data),
    refetchInterval: (data, _) => {
      if (!data || data.isRecalculating) {
        return 1000
      }
      return unusedLicenseStatsByOrganizationId.staleTime
    },
  })
}

export const useLicenses = () => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery<LicensesByVendorQueryResult[], Error, License[]>({
    queryKey: licensesByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () => await licensesByOrganizationId.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    select: data => buildLicensesByVendor(data),
    staleTime: licensesByOrganizationId.staleTime,
  })
}

export const useErroneousLicenseUpdates = ({ reload = false, ...queryArgs } = {}) => {
  const selectedOrganization = useSelectedOrganization()

  const { data, isLoading, isFetched, ...queryProps } = useQuery<
    ErroneousLicenseUpdateDto[],
    Error
  >({
    queryKey: erroneousLicenseUpdatesByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () =>
      await erroneousLicenseUpdatesByOrganizationId.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
  })

  return {
    data,
    isFetched,
    isLoading,
    ...queryProps,
  }
}

export const useGetSubsribersByOrganization = <TResult = SubscribersByVendorQueryResult[],>(
  options?: Omit<UseQueryOptions<SubscribersByVendorQueryResult[], Error, TResult>, 'queryKey'>
) => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery<SubscribersByVendorQueryResult[], Error, TResult>({
    queryKey: subscribersByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () => await subscribersByOrganizationId.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    refetchOnMount: false,
    staleTime: subscribersByOrganizationId.staleTime,
    ...options,
  })
}

export const useDomains = (vendorName?: string | null) => {
  return useGetSubsribersByOrganization<DomainByVendor[]>({
    select: data => buildDomainsByVendor(data, vendorName),
  })
}

export const useSubscribers = () => {
  return useGetSubsribersByOrganization<Subscriber[]>({
    select: data => buildSubscribersByVendor(data),
  })
}

export const useUpdateLicenses = () => {
  const selectedOrganizationId = useSelectedOrganizationId()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (data: UpdateLicenseSubscribersPayload) =>
      await updateLicenseSubscribers.mutationFn(selectedOrganizationId ?? '', data),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries(licensesByOrganizationId.queryKey(selectedOrganizationId))
      await queryClient.invalidateQueries(
        subscribersByOrganizationId.queryKey(selectedOrganizationId)
      )
      await queryClient.invalidateQueries(
        erroneousLicenseUpdatesByOrganizationId.queryKey(selectedOrganizationId)
      )
    },
    onError: (error, variables) => {
      throw error
    },
  })

  return {
    updateLicenses: async (data: UpdateLicenseSubscribersPayload) => {
      await mutation.mutateAsync(data)
      if (mutation.isError) {
        throw mutation.error
      }
    },
    ...mutation,
  }
}

export const useRefreshData = () => {
  const selectedOrganizationId = useSelectedOrganizationId()

  const mutation = useMutation({
    mutationFn: async (organizationId: string) => await refreshData.mutationFn(organizationId),
    onError: (error, variables) => {
      throw error
    },
  })

  return {
    refreshData: async () => {
      await mutation.mutateAsync(selectedOrganizationId ?? '')
      if (mutation.isError) {
        throw mutation.error
      }
    },
    ...mutation,
  }
}

export const useDismissLicenseUpdate = () => {
  const selectedOrganizationId = useSelectedOrganizationId()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (licenseUpdateId: string) =>
      await dismissLicenseUpdate.mutationFn(selectedOrganizationId ?? '', licenseUpdateId),
    onSuccess: async (data, licenseUpdateId) => {
      queryClient.setQueryData<ErroneousLicenseUpdateDto[]>(
        erroneousLicenseUpdatesByOrganizationId.queryKey(selectedOrganizationId),
        oldData => {
          if (!oldData) {
            return []
          }

          return oldData.filter((obj: ErroneousLicenseUpdateDto) => {
            return obj.licenseUpdateId !== licenseUpdateId
          })
        }
      )

      await queryClient.invalidateQueries(
        erroneousLicenseUpdatesByOrganizationId.queryKey(selectedOrganizationId)
      )
    },
    onError: (error, variables) => {
      throw error
    },
  })

  return {
    dismissLicenseUpdate: async (licenseUpdateId: string) => {
      await mutation.mutateAsync(licenseUpdateId ?? '')
      if (mutation.isError) {
        throw mutation.error
      }
    },
    ...mutation,
  }
}
