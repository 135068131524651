import { useQueryClient } from '@tanstack/react-query'
import { Resource } from 'i18next'
import { useTranslation } from 'react-i18next'

import { localizedKey } from '../../reactQuery/core/hooks'

export const useLanguage = () => {
  const { i18n } = useTranslation()
  return i18n.resolvedLanguage
}

export const useLanguages = () => {
  const { i18n } = useTranslation()
  return Object.keys(i18n.options.resources as Resource)
}

export const useChangeLanguageAction = () => {
  const { i18n } = useTranslation()
  const queryClient = useQueryClient()

  return (language: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.changeLanguage(language).then(() => {
      // Invalidate every query with a key that starts with localizedKey
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: [localizedKey] })
    })
  }
}
