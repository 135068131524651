import {
  Currency as ApiCurrency,
  OrderStatus as ApiOrderStatus,
  OrdersQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import { Currency, MoneyAmount } from '@sherweb/core/common/money'
import { orderByDesc } from '@sherweb/core/utils/array'

import { AmendmentOrder, OrderDecision, OrderStatus, ProcessOrder } from './orders.model'

export const buildAmendmentOrders = (orders?: OrdersQueryResult[]): AmendmentOrder[] =>
  orders ? orderByDesc(orders.map(buildAmendmentOrder), 'createdOn') : []

export const buildAmendmentOrder = (response?: OrdersQueryResult): AmendmentOrder => {
  const orderValue: MoneyAmount | null = response?.value
    ? { currency: mapCurrency(response.value.currency), amount: response.value.amount }
    : null

  return {
    id: response?.orderId ?? '',
    humanReadableId: response?.humanReadableId ?? '',
    createdOn: response?.createdAt ?? null,
    organizationId: response?.organizationId,
    organizationUniqueName: response?.organizationUniqueName,
    firstSubscriptionId: response?.items?.[0]?.subscriptionId,
    organizationName: response?.organizationName,
    userEmail: response?.userEmail,
    userFirstName: response?.userFirstName,
    userLastName: response?.userLastName,
    orderStatus: response?.orderStatus ? OrderStatus[response?.orderStatus] : null,
    orderValue,
    decisionNote: response?.decisionNote,
    error: response?.errorResult
      ? {
          message: response?.errorResult.message,
          title: response?.errorResult.title,
          detail: response?.errorResult.detail,
        }
      : null,
  }
}

export const mapCurrency = (currency: ApiCurrency): Currency => {
  switch (currency) {
    case ApiCurrency.Cad:
      return Currency.CAD
    case ApiCurrency.Usd:
    default:
      return Currency.USD
  }
}

export const mapUpdateOnDataOnProcessOrder = (
  oldData?: OrdersQueryResult[],
  variables?: ProcessOrder
): OrdersQueryResult[] => {
  if (!oldData) {
    return []
  }

  if (!variables) {
    return oldData
  }

  return [
    ...oldData.map(item => {
      if (variables.orderId === item.orderId) {
        return {
          ...item,
          orderStatus:
            variables.decision === OrderDecision.Approve
              ? ApiOrderStatus.Pending
              : ApiOrderStatus.Rejected,
          decisionNote: variables.decisionNote ?? undefined,
        }
      }
      return item
    }),
  ]
}
