import { useQuery } from '@tanstack/react-query'

import { PortalMetadataResponse } from '../../../openapi-generated/index.defs'
import { canLocationMakeCallForData, QueryResult } from '../../reactQuery'
import { buildSettings } from './portalMetadata.builder'
import { PortalMetadata } from './portalMetadata.models'
import { portalMetadataQuery } from './portalMetadata.queries'
import { PortalMode } from './portalMetadata.utils'

/**
 * returns reseller portal settings based on current hostname
 */
export const usePortalMetadata = ({
  portalMode = PortalMode.SSP,
  ...queryArgs
} = {}): QueryResult<PortalMetadata> => {
  /* istanbul ignore next ; hostname is always defined in test environment */
  const queryKey = portalMetadataQuery.queryKey()

  const { data, isLoading, ...queryProps } = useQuery<PortalMetadataResponse, Error>({
    queryKey,
    queryFn: async () => await portalMetadataQuery.queryFn(portalMode),
    staleTime: portalMetadataQuery.staleTime,
    enabled: canLocationMakeCallForData(),
  })

  return {
    data: buildSettings(data),
    isLoading,
    ...queryProps,
  }
}
