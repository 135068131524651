enum Routes {
  Root = '/',
  Login = '/login',
  Onboarding = '/admin/onboarding',
  OrganizationDispatcher = '/:organizationUniqueName',
  Dashboard = '/:organizationUniqueName/',
  Admin = '/:organizationUniqueName/admin',
  Billing = '/:organizationUniqueName/billing',
  Register = '/:organizationUniqueName/register',
  Subscriptions = '/:organizationUniqueName/subscriptions',
  Subscription = '/:organizationUniqueName/subscriptions/:subscriptionId',
  Licenses = '/:organizationUniqueName/licenses',
  Support = '/:organizationUniqueName/support',
  Users = '/:organizationUniqueName/users',
  UserEdit = '/:organizationUniqueName/users/:userId/edit',
  UsersInvite = '/:organizationUniqueName/users/invite',
  OrganizationsNotActivated = '/no-activated-organizations',
  OrganizationSuspended = '/:organizationUniqueName/organization-suspended',
  HelpDesk = '/:organizationUniqueName/helpdesk',
  HelpDeskCreate = '/:organizationUniqueName/helpdesk/create',
  HelpDeskTicketDetail = '/:organizationUniqueName/helpdesk/:helpDeskTicketId/detail',
  Shop = '/:organizationUniqueName/shop',
  ShopProductDetail = '/:organizationUniqueName/shop/:shopProductId/detail',
  Orders = '/:organizationUniqueName/orders',
}

export default Routes
