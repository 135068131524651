import React, { ReactNode } from 'react'

import { DataTestId } from '../../types/dataTestIdType'

interface AccordionContentListProps {
  children: ReactNode
}

interface AccordionContentListItemProps extends DataTestId {
  label: ReactNode | undefined
  value?: string | ReactNode
}

const AccordionContentList: React.FC<AccordionContentListProps> = ({ children }) => {
  return (
    <div className="flex flex-col divide-y rounded-b-xl bg-white pb-2 dark:divide-slate-800 dark:bg-slate-950">
      {children}
    </div>
  )
}

const AccordionContentListItem: React.FC<AccordionContentListItemProps> = ({
  label,
  value,
  dataTestId,
}) => {
  return (
    <div className="flex h-12 items-center justify-between px-4 py-2.5 text-sm dark:bg-slate-950">
      <div>{label}</div>
      <div data-testid={dataTestId}>{value}</div>
    </div>
  )
}

export { AccordionContentList, AccordionContentListItem }
