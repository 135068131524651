import { CheckIcon, EyeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button, { Variant } from '@sherweb/core/components/Button'
import { Permission } from '@sherweb/core/modules/authorization'
import { usePathGenerator } from '@sherweb/core/modules/browserRouter'

import Routes from '@rsp/app/Routes'
import { HasAccess } from '@rsp/modules/authorization'
import { AmendmentOrder, OrderDecision, OrderStatus, useProcessOrder } from '@rsp/modules/orders'

import { OrderAprovalConfirmationDialog } from '../OrderAprovalConfirmationDialog'

export type OrderActionsProps = {
  order: AmendmentOrder
}

export const OrderActions: React.FC<OrderActionsProps> = ({ order }) => {
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)
  const [decision, setDecision] = useState<OrderDecision | null>(null)
  const processOrderMutation = useProcessOrder()
  const pathGenerator = usePathGenerator()
  const decisionFlag = order?.orderStatus === OrderStatus.WaitingForApproval

  const { t } = useTranslation()

  const setDecisionAndOpenDialog = (decision: OrderDecision) => {
    setDecision(decision)
    setConfirmationDialogOpened(true)
  }

  const cancelConfirmation = () => {
    setDecision(null)
    setConfirmationDialogOpened(false)
  }

  const navigateToSubscriptionView = () => {
    if (order?.firstSubscriptionId && order?.organizationUniqueName) {
      const subscriptionPath = pathGenerator(Routes.Subscription, {
        subscriptionId: order?.firstSubscriptionId ?? '',
        organizationUniqueName: order?.organizationUniqueName ?? '',
      })
      window.open(subscriptionPath, '_blank')
    }
  }

  const onSubmit = (decision: OrderDecision | null, decisionNote?: string) => {
    processOrderMutation.mutate({
      orderId: order?.id,
      organizationId: order?.organizationId,
      decision,
      decisionNote,
    })
  }

  return (
    <div className="flex flex-row gap-4">
      <Button
        variant={Variant.Primary}
        size="sm"
        className="grow"
        onClick={() => navigateToSubscriptionView()}
      >
        <EyeIcon className="h-5 w-5 stroke-2" />
        {t('rsp:pages.orders.list.actions.viewSubscription')}
      </Button>
      {decisionFlag && (
        <HasAccess permission={Permission.OrderApproval}>
          <Button
            variant={Variant.Success}
            size="sm"
            className="grow"
            onClick={() => setDecisionAndOpenDialog(OrderDecision.Approve)}
          >
            <CheckIcon className="h-5 w-5 stroke-2" /> {t('rsp:pages.orders.list.actions.approve')}
          </Button>
          <Button
            variant={Variant.Danger}
            size="sm"
            className="grow"
            onClick={() => setDecisionAndOpenDialog(OrderDecision.Reject)}
          >
            <XMarkIcon className="h-5 w-5 stroke-2" />
            {t('rsp:pages.orders.list.actions.reject')}
          </Button>
        </HasAccess>
      )}
      {decisionFlag && decision && (
        <HasAccess permission={Permission.OrderApproval}>
          <OrderAprovalConfirmationDialog
            decision={decision}
            cancelConfirmation={cancelConfirmation}
            onSubmit={onSubmit}
            order={order}
            confirmationDialogOpened={confirmationDialogOpened}
          />
        </HasAccess>
      )}
    </div>
  )
}
