import { UsersService } from '@sherweb/core/openapi-generated/UsersService'

import { UserRole } from './user.model'

export const deleteUser = {
  mutationFn: async (organizationId: string, userId: string) => {
    return await UsersService.delete({
      command: {
        organizationId,
        userId,
      },
    })
  },
}

export const inviteUserByOrganization = {
  mutationFn: async (
    firstName: string,
    lastName: string,
    title: string,
    email: string,
    role: UserRole,
    organizationId: string,
    preferredCulture: string
  ) => {
    return await UsersService.invite({
      command: {
        firstName,
        lastName,
        jobTitle: title,
        role,
        invitedUserEmailAddress: email,
        organizationsIds: [organizationId],
        preferredCulture,
      },
    })
  },
}
