import { Money, Currency as OpenApiCurrency } from '@sherweb/core/openapi-generated/index.defs'

import { Currency, MoneyAmount } from '../../common/money'

export const useMoneyFormatter = <T extends MoneyAmount | Money>(language: string) => {
  return (moneyAmount: T) => {
    const formatter = Intl.NumberFormat(language, {
      style: 'currency',
      currency: moneyAmount.currency,
    })
    return formatter.format(moneyAmount.amount)
  }
}

export const useCurrencyConverter = () => {
  return (currency?: Currency) => {
    switch (currency) {
      case Currency.USD:
        return OpenApiCurrency.Usd
      case Currency.CAD:
      default:
        return OpenApiCurrency.Cad
    }
  }
}
