import { useQuery } from '@tanstack/react-query'

import { useAuthenticationLoggedInState } from '../../authentication'
import { useHostname } from '../../browserRouter'
import { canLocationMakeCallForData, QueryResult } from '../../reactQuery'
import { getPermissionsByOrganization, getPermissionsByReseller } from './authorization.queries'

/**
 * returns reseller portal settings based on current hostname
 */
export const usePermissionsByOrganization = (organizationId: string): QueryResult<string[]> => {
  const queryKey = getPermissionsByOrganization.queryKey(organizationId)
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const { data, isLoading, ...queryProps } = useQuery<string[], Error>({
    queryKey,
    queryFn: async () => await getPermissionsByOrganization.queryFn(organizationId),
    staleTime: getPermissionsByOrganization.staleTime,
    enabled: !!organizationId && isLoggedIn && canLocationMakeCallForData(),
  })

  return {
    data: data ?? [],
    isLoading: !!organizationId && isLoggedIn && isLoading,
    ...queryProps,
  }
}

export const usePermissionsByReseller = (): QueryResult<string[]> => {
  const { isLoggedIn } = useAuthenticationLoggedInState()
  const hostname = useHostname() ?? 'localhost'
  const queryKey = getPermissionsByReseller.queryKey(hostname)

  const { data, isLoading, ...queryProps } = useQuery<string[], Error>({
    queryKey,
    queryFn: async () => await getPermissionsByReseller.queryFn(hostname),
    staleTime: getPermissionsByReseller.staleTime,
    enabled: isLoggedIn && canLocationMakeCallForData(),
  })

  return {
    data: data ?? [],
    isLoading: isLoading && isLoggedIn,
    ...queryProps,
  }
}
