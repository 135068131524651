import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import { errorNotification } from '@sherweb/core/components/ToastNotifications'

import Routes from '@rsp/app/Routes'
import { usePermissionCheck } from '@rsp/modules/authorization'
import { useNavigate } from '@rsp/modules/navigation'
import { OrderDecision, OrderStatus, useOrder, useProcessOrder } from '@rsp/modules/orders'

import { OrderAprovalConfirmationDialog } from './../OrderAprovalConfirmationDialog'
import { OrderAlreadyReviewed } from './OrderAlreadyReviewed'
import { useOrderApprovalDecisionProps } from './useOrderApprovalDecisionProps'

export const OrderApprovalDecision = () => {
  const props = useOrderApprovalDecisionProps()
  const { data: order, error, isFetched } = useOrder(props.orderId)
  const processOrderMutation = useProcessOrder()
  const { t } = useTranslation()
  const { hasAccess, permissionsFetched } = usePermissionCheck()
  const { navigate } = useNavigate()
  const [linkUsed, setLinkUsed] = useState(false)
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(
    props.havingRequiredProps
  )
  const doNotHavePermissionFlag =
    permissionsFetched && !hasAccess(Permission.OrderApproval) && !linkUsed
  const statusNotWaitingForApproval =
    isFetched &&
    !!order?.orderStatus &&
    order.orderStatus !== OrderStatus.WaitingForApproval &&
    !linkUsed
  const statusNotFound = isFetched && (!order || error) && !linkUsed

  useEffect(() => {
    if (props.havingRequiredProps && statusNotFound) {
      errorNotification(t('rsp:pages.orders.orderApprovalDecisionErrors.notFound'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.havingRequiredProps, statusNotFound])

  useEffect(() => {
    if (doNotHavePermissionFlag) {
      errorNotification(t('rsp:errors.forbidden'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doNotHavePermissionFlag])

  if (!props.havingRequiredProps || doNotHavePermissionFlag) {
    return null
  }

  if (statusNotWaitingForApproval) {
    return <OrderAlreadyReviewed open={statusNotWaitingForApproval} />
  }

  if (!order || error) {
    return null
  }

  const cancelConfirmation = () => {
    setConfirmationDialogOpened(false)
    setInvoked()
  }

  const setInvoked = () => {
    setLinkUsed(true)
    navigate(Routes.Orders)
  }

  const onSubmit = (decision: OrderDecision | null, decisionNote?: string) => {
    processOrderMutation.mutate({
      orderId: order.id,
      organizationId: order.organizationId,
      decision,
      decisionNote,
    })
    setInvoked()
  }

  return (
    <OrderAprovalConfirmationDialog
      decision={props.decision}
      order={order}
      cancelConfirmation={cancelConfirmation}
      onSubmit={onSubmit}
      confirmationDialogOpened={confirmationDialogOpened}
    />
  )
}
