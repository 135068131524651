import { CheckCircleIcon } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Card, { CardMetric, CardTitle } from '@sherweb/core/components/Card'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import { take } from '@sherweb/core/utils/array'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link/Link'
import ServerError from '@ssp/components/ServerError/ServerError'
import { useUsedLicenseStatistics } from '@ssp/modules/license'

const CurrentUserLicenseCountWidget = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: stats } = useUsedLicenseStatistics()

  const itemsToShow = 3

  return (
    <>
      {error && <ServerError error={error} />}
      {stats && (
        <Card padded={false} className="flex min-h-[160px] flex-col justify-between p-6">
          <CardTitle>
            {t('ssp:pages.dashboard.widgets.CurrentUserLicenseCountWidget.title')}
          </CardTitle>
          <CardMetric data-testid="cardContentLicenses" className="mt-4 flex flex-auto">
            {!(isLoading || stats.isRecalculating) && (
              <div className="grid grid-cols-[auto_auto] gap-x-8">
                <span>{stats.currentUserLicensesCount}</span>
                <div className="text-sm font-normal not-italic text-slate-700">
                  <div className="grid auto-rows-min grid-cols-[auto_auto] gap-x-1 gap-y-0.5">
                    {take(stats.currentUserLicenses, itemsToShow).map(name => (
                      <React.Fragment key={name?.trim()}>
                        <CheckCircleIcon className="h-4" />
                        <span className="truncate">{name}</span>
                      </React.Fragment>
                    ))}
                    <span />
                    {stats.currentUserLicenses?.length > itemsToShow &&
                      t('ssp:pages.dashboard.widgets.CurrentUserLicenseCountWidget.more', {
                        count: stats.currentUserLicenses.length - itemsToShow,
                      })}
                  </div>
                </div>
              </div>
            )}
            {(isLoading || stats.isRecalculating) && <Spinner className="h-4 w-4" />}
          </CardMetric>
          <Link
            className="inline w-fit text-sm text-indigo-600 underline focus-visible:outline-indigo-300"
            to={Routes.Licenses}
            data-testid="linkViewLicenses"
          >
            {t('ssp:pages.dashboard.widgets.CurrentUserLicenseCountWidget.viewAll')}
          </Link>
        </Card>
      )}
    </>
  )
}

export default CurrentUserLicenseCountWidget
