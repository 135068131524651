import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Card, { CardTitle } from '@sherweb/core/components/Card'
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormMessage,
} from '@sherweb/core/components/Form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@sherweb/core/components/Select'
import { usePrevious } from '@sherweb/core/hooks'

import { useDomains } from '@ssp/modules/license'
import { License, Subscriber } from '@ssp/modules/license/core/license.model'

import { DEFAULT_VALUES } from './createSubscriberForm.helper'
import { CreateSubscriberFormActions } from './CreateSubscriberFormActions'
import { useCreateSubscriberForm } from './hooks/useCreateSubscriberForm'
import {
  CreateSubscriberFormType,
  useCreateSubscriberSchema,
} from './hooks/useCreateSubscriberSchema'

type CreateSubscriberFormProps = {
  existingSubscribers: Subscriber[]
  license: License
  onCancel: () => void
  onSuccess: () => void
}

const CreateSubscriberForm: React.FC<CreateSubscriberFormProps> = ({
  existingSubscribers,
  license,
  onCancel,
  onSuccess,
}) => {
  const { t } = useTranslation()

  const schema = useCreateSubscriberSchema()

  const form = useForm<CreateSubscriberFormType>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES,
  })

  const { data: domains = [] } = useDomains(license?.vendorName)

  const previousDomains = usePrevious(domains)

  useEffect(() => {
    if (domains?.length !== 0 && !previousDomains && previousDomains !== domains) {
      form.setValue('currentDomain', domains?.find(domain => domain?.isDefault)?.domain ?? '')
    }
  }, [domains, form, previousDomains])

  const { isCreateButtonDisabled, updating, onSubmit } = useCreateSubscriberForm({
    license,
    onSuccess,
    existingSubscribers,
  })

  const handleSubmit = async (values: CreateSubscriberFormType) => {
    await onSubmit(values)
  }

  return (
    <Card
      className="flex flex-col gap-8 p-4 transition ease-in-out dark:bg-slate-900 md:p-4"
      data-testid="create-subscriber-form"
    >
      <CardTitle>{t('ssp:pages.licenses.createSubcriberForm.title')}</CardTitle>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="flex flex-col gap-4">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormInput
                    data-testid="create-subscriber-first-name-field"
                    {...field}
                    placeholder={t('ssp:pages.licenses.createSubcriberForm.placeholders.firstName')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormInput
                    data-testid="create-subscriber-last-name-field"
                    {...field}
                    placeholder={t('ssp:pages.licenses.createSubcriberForm.placeholders.lastName')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormInput
                    data-testid="create-subscriber-username-field"
                    {...field}
                    placeholder={t('ssp:pages.licenses.createSubcriberForm.placeholders.username')}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="currentDomain"
            render={({ field }) => (
              <FormItem>
                <Select value={field.value} onValueChange={field.onChange}>
                  <FormControl>
                    <SelectTrigger data-testid="selectTriggerDomain">
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent side="bottom">
                    {domains?.map(domain => (
                      <SelectItem
                        key={`${domain?.domain ?? ''} - ${domain?.vendorName ?? ''}`}
                        value={domain.domain ?? ''}
                        data-testid="selectItemDomain"
                      >
                        {domain.domain}{' '}
                        {domain?.isDefault
                          ? `(${t('ssp:pages.licenses.createSubcriberForm.default')})`
                          : ''}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <CreateSubscriberFormActions
            isDisabled={isCreateButtonDisabled}
            isUpdating={updating}
            onCancel={onCancel}
          />
        </form>
      </Form>
    </Card>
  )
}

export default CreateSubscriberForm
