import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
  CountResult as ApiCountResult,
  UsersQueryResult,
  UserWithLicensesQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationUsersService } from '@sherweb/core/openapi-generated/OrganizationUsersService'
import { UsersService } from '@sherweb/core/openapi-generated/UsersService'

import { errorNotification, successNotification } from '@sherweb/core/components/ToastNotifications'
import { buildCountResult, CountResult } from '@sherweb/core/modules/common'

import { useSelectedOrganization, useSelectedOrganizationId } from '@ssp/modules/organization'

import { buildUsers } from './user.builder'
import { UpdateUser, User, UserRole } from './user.model'
import { deleteUser } from './user.mutations'
import {
  userByOrganizationId,
  userCountByOrganizationId,
  usersByOrganizationId,
} from './user.queries'

export const useUsersByOrganization = () => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery<UsersQueryResult[], Error, User[]>({
    queryKey: usersByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () => await usersByOrganizationId.queryFn(selectedOrganization?.id),
    select: data => buildUsers(selectedOrganization?.id, data),
    enabled: !!selectedOrganization,
    staleTime: usersByOrganizationId.staleTime,
  })
}

export const useUserCountByOrganization = () => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery<ApiCountResult, Error, CountResult>({
    queryKey: userCountByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () => await userCountByOrganizationId.queryFn(selectedOrganization?.id),
    select: (data: ApiCountResult) => buildCountResult(data),
    initialData: () => buildCountResult(),
    enabled: !!selectedOrganization,
    refetchInterval: (data, _) => {
      if (!data || data.isRecalculating) {
        return 1000
      }
      return userCountByOrganizationId.staleTime
    },
  })
}

export const useDeleteUser = () => {
  const selectedOrganizationId = useSelectedOrganizationId()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (data: { organizationId: string; userId: string }) =>
      await deleteUser.mutationFn(data.organizationId, data.userId),
    onSuccess: async () => {
      successNotification(t('ssp:pages.users.userDeletionDialog.success'))
      await queryClient.invalidateQueries({
        queryKey: usersByOrganizationId.queryKey(selectedOrganizationId),
      })
      await queryClient.invalidateQueries({
        queryKey: userCountByOrganizationId.queryKey(selectedOrganizationId),
      })
    },
    onError: () => {
      errorNotification(t('ssp:errors.generic'))
    },
  })

  return {
    deleteUser: (userId: string): void => {
      if (!userId) {
        throw new Error('Missing user id')
      }

      return mutation.mutate({ organizationId: selectedOrganizationId ?? '', userId })
    },
    ...mutation,
  }
}

export const useInviteUser = () => {
  const selectedOrganizationId = useSelectedOrganizationId()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: UpdateUser) => {
      if (
        !data.firstName ||
        !data.lastName ||
        !data.jobTitle ||
        !data.email ||
        !data.role ||
        !data.preferredCulture
      ) {
        throw new Error('Missing parameters')
      }

      return await UsersService.invite({
        command: {
          ...data,
          invitedUserEmailAddress: data.email,
          organizationsIds: [selectedOrganizationId ?? ''],
        },
      })
    },
    onSuccess: async () => {
      successNotification(t('ssp:pages.user.edit.success'))
      await queryClient.invalidateQueries({
        queryKey: userCountByOrganizationId.queryKey(selectedOrganizationId),
      })
      return await queryClient.invalidateQueries({
        queryKey: usersByOrganizationId.queryKey(selectedOrganizationId),
      })
    },
  })
}

export const useEditUser = (userId?: string) => {
  const selectedOrganizationId = useSelectedOrganizationId()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (user: UpdateUser) => {
      if (!userId) {
        throw new Error('Missing user id')
      }

      return await OrganizationUsersService.updateOrganizationUser({
        organizationId: selectedOrganizationId,
        userId: userId ?? '',
        command: user,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: userByOrganizationId.queryKey(selectedOrganizationId, userId),
      })
      successNotification(t('ssp:pages.user.edit.success'))
      await queryClient.invalidateQueries({
        queryKey: userCountByOrganizationId.queryKey(selectedOrganizationId),
      })
      return await queryClient.invalidateQueries({
        queryKey: usersByOrganizationId.queryKey(selectedOrganizationId),
      })
    },
  })
}

export const useGetUserQuery = (userId?: string) => {
  const selectedOrganizationId = useSelectedOrganizationId()

  return useQuery<
    UserWithLicensesQueryResult,
    Error,
    UserWithLicensesQueryResult & { role: UserRole | undefined }
  >({
    queryKey: userByOrganizationId.queryKey(selectedOrganizationId, userId),
    queryFn: async () => {
      if (!userId || !selectedOrganizationId) {
        throw new Error('Missing user id or organization id')
      }

      return await userByOrganizationId.queryFn(selectedOrganizationId, userId)
    },
    select: data => ({
      ...data,
      role: data?.roles?.find(role => role.organizationId === selectedOrganizationId)?.userRole,
    }),
    enabled: !!selectedOrganizationId && !!userId,
  })
}

export const useResendInvitationMutation = () => {
  const selectedOrganizationId = useSelectedOrganizationId()

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (user: User) => {
      if (!user || !selectedOrganizationId || !user?.email) {
        throw new Error('Missing parameters')
      }

      return await UsersService.reInvite({
        emailAddress: user?.email,
        command: {
          organizationId: selectedOrganizationId,
        },
      })
    },
    meta: {
      errorMessage: t('ssp:pages.users.list.resendInvitationFailureMessage'),
    },
    onSuccess: async () => {
      successNotification(t('ssp:pages.users.list.resendInvitationSuccessMessage'))
      await queryClient.invalidateQueries({
        queryKey: usersByOrganizationId.queryKey(selectedOrganizationId),
      })
    },
  })
}
