import React from 'react'
import { useTranslation } from 'react-i18next'

import { Permission } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import Card, { CardMetric, CardTitle } from '@sherweb/core/components/Card'
import Spinner from '@sherweb/core/components/Spinner/Spinner'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link/Link'
import ServerError from '@ssp/components/ServerError/ServerError'
import { HasAccess } from '@ssp/modules/authorization'
import { useUserCountByOrganization } from '@ssp/modules/user'

const UserCountWidget = () => {
  const { t } = useTranslation()

  const { isLoading, error, data: countResult } = useUserCountByOrganization()

  const isUserCountLoading = isLoading || countResult.isRecalculating

  return (
    <>
      {error && <ServerError error={error} />}
      {countResult && (
        <Card padded={false} className="flex min-h-[160px] flex-col justify-between p-6">
          <CardTitle>{t('ssp:pages.dashboard.widgets.UserCountWidget.title')}</CardTitle>
          <CardMetric data-testid="cardNumberUsers" className="mt-4 flex flex-auto">
            {!isUserCountLoading && <span>{countResult.count}</span>}
            {isUserCountLoading && <Spinner className="h-4 w-4" />}
          </CardMetric>
          {countResult.count === 0 && !isUserCountLoading ? (
            <HasAccess permission={Permission.UsersCreate}>
              <Button data-testid="btnInviteUser" variant="primary" size="sm" asChild>
                <Link to={Routes.UsersInvite} className="inline-block">
                  {t('ssp:pages.dashboard.widgets.UserCountWidget.addUser')}
                </Link>
              </Button>
            </HasAccess>
          ) : (
            <Link
              className="inline-block text-sm text-blue-600 underline focus-visible:outline-indigo-300"
              to={Routes.Users}
              data-testid="linkViewUsers"
            >
              {t('ssp:pages.dashboard.widgets.UserCountWidget.viewAll')}
            </Link>
          )}
        </Card>
      )}
    </>
  )
}

export default UserCountWidget
