import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import {
  HelpDeskTicketDetailsQueryResult,
  HelpDeskTicketNoteQueryResult,
  HelpDeskTicketStatus,
  HelpDeskTicketStatusType,
} from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'

import Routes from '@ssp/app/Routes'
import { useNavigate, usePathGenerator } from '@ssp/modules/navigation'
import { useSelectedOrganization } from '@ssp/modules/organization'

export const useTicketStatusBadge = () => {
  return (status?: HelpDeskTicketStatus) => {
    const ticketStatusMapper = {
      [HelpDeskTicketStatusType.New]: <Badge variant={Variant.Success}>{status?.name}</Badge>,
      [HelpDeskTicketStatusType.Closed]: <Badge variant={Variant.Default}>{status?.name}</Badge>,
      [HelpDeskTicketStatusType.WaitingForCustomer]: (
        <Badge variant={Variant.Warning}>{status?.name}</Badge>
      ),
      [HelpDeskTicketStatusType.Resolved]: (
        <Badge
          variant={Variant.Default}
          className="border-blue-600/10 bg-blue-100 dark:border-blue-800 dark:text-blue-600"
        >
          {status?.name}
        </Badge>
      ),
      [HelpDeskTicketStatusType.Processing]: <Badge variant={Variant.Info}>{status?.name}</Badge>,
    }

    return status ? ticketStatusMapper[status.type] : ''
  }
}

export const useGetHelpDeskRouteParams = (params?: Record<string, string>) => {
  const organization = useSelectedOrganization()

  const organizationUniqueName = organization?.uniqueName

  if (params && organizationUniqueName) {
    return { organizationUniqueName, ...params }
  }

  if (organizationUniqueName) {
    return { organizationUniqueName }
  }
}

export const useNavigateToHelpDeskPage = () => {
  const generatePath = usePathGenerator()

  const { navigate } = useNavigate()

  const helpDeskRouteParams = useGetHelpDeskRouteParams()

  return useCallback(() => {
    if (helpDeskRouteParams?.organizationUniqueName) {
      navigate(
        generatePath(Routes.HelpDesk, {
          organizationUniqueName: helpDeskRouteParams?.organizationUniqueName,
        })
      )
    }
  }, [generatePath, helpDeskRouteParams?.organizationUniqueName, navigate])
}

export const useOptimisticUpdateDetailNote = () => {
  const queryClient = useQueryClient()

  const setNote = (queryKey: Array<string | undefined>, data: HelpDeskTicketNoteQueryResult) => {
    const previousDetail = queryClient.getQueryData<HelpDeskTicketDetailsQueryResult>(queryKey)

    queryClient.setQueryData(queryKey, {
      ...previousDetail,
      notes: [...(previousDetail?.notes ?? []), data],
    })

    return previousDetail
  }

  return { setNote }
}
