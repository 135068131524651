import { useTranslation } from 'react-i18next'

import DataTable, { Columns } from '@sherweb/core/components/DataTable'
import { Permission } from '@sherweb/core/modules/authorization'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import { usePermissionCheck } from '@ssp/modules/authorization'
import {
  Subscription,
  SubscriptionBillingCycle,
  SubscriptionStatus,
  useCycleTranslation,
  useStatusBadge,
  useSubscriptions,
} from '@ssp/modules/subscription'

import {
  SubscriptionTableActionRow,
  SubscriptionTableActionRowProps,
} from './SubscriptionTableActionRow'
import {
  SubscriptionTableProductNameRow,
  SubscriptionTableProductNameRowProps,
} from './SubscriptionTableProductNameRow'

const CLASSNAME = 'text-center'

const renderProductNameRowContent = ({
  renewalDate,
  fullName,
}: SubscriptionTableProductNameRowProps) => (
  <SubscriptionTableProductNameRow renewalDate={renewalDate} fullName={fullName} />
)

const renderActionRowContent = ({ subscriptionId }: SubscriptionTableActionRowProps) => (
  <SubscriptionTableActionRow subscriptionId={subscriptionId} />
)

const SubscriptionTable: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const formatMoneyAmount = useMoneyFormatter(language)
  const { isLoading, error, data: subscriptions } = useSubscriptions()
  const getCycleTranslation = useCycleTranslation()
  const getStatusBadge = useStatusBadge()
  const { hasAccess } = usePermissionCheck()
  const showPrices = hasAccess(Permission.SubscriptionPrices)

  const columns: Columns<Subscription> = [
    {
      accessorKey: 'productName',
      header: t('ssp:pages.subscriptions.list.columns.productName'),
      cell: ({ cell, row }) =>
        renderProductNameRowContent({
          renewalDate: row.original.renewalDate,
          fullName: cell.getValue<string>(),
        }),
      minSize: 417,
    },
    {
      accessorKey: 'billingCycle',
      header: t('ssp:pages.subscriptions.list.columns.billingCycle'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      cell: ({ getValue }) => getCycleTranslation(getValue<SubscriptionBillingCycle>()),
      minSize: 278,
    },
    {
      accessorKey: 'quantity',
      header: t('ssp:pages.subscriptions.list.columns.quantity'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      minSize: 278,
    },
    {
      accessorKey: 'price.amount',
      header: t('ssp:pages.subscriptions.list.columns.price'),
      enableSorting: true,
      meta: { className: `text-right ${showPrices ? '' : 'hidden'}` },
      cell: ({ row }) => {
        const price = row.original.price
        return price ? formatMoneyAmount(price) : null
      },
      minSize: 120,
    },
    {
      accessorKey: 'total.amount',
      header: t('ssp:pages.subscriptions.list.columns.total'),
      enableSorting: true,
      meta: { className: `text-right ${showPrices ? '' : 'hidden'}` },
      cell: ({ row }) => {
        const price = row.original.total
        return price ? formatMoneyAmount(price) : null
      },
      minSize: 120,
    },
    {
      accessorKey: 'statusWithOrders',
      enableSorting: true,
      meta: { className: CLASSNAME },
      header: t('ssp:pages.subscriptions.list.columns.status'),
      cell: ({ getValue, row }) =>
        getStatusBadge(getValue<SubscriptionStatus>(), row.original.lastPendingOrFailedOrder),
      minSize: 278,
    },
    {
      id: 'actions',
      cell: ({ row }) => renderActionRowContent({ subscriptionId: row.original.id }),
      minSize: 139,
    },
  ]

  if (isLoading || error) {
    return null
  }

  return (
    <DataTable
      columns={columns}
      data={subscriptions}
      dataTestId="tableSubscriptions"
      filterableFields={['productName']}
      filterPlaceholder={String(t('ssp:pages.subscriptions.list.filterPlaceholder'))}
      fieldDescription={String(t('ssp:pages.subscriptions.list.searchFieldDescription'))}
    />
  )
}

export default SubscriptionTable
