import { CurrencyDollarIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { BillingCycle, Facet } from '@sherweb/core/openapi-generated/index.defs'

import Button from '@sherweb/core/components/Button'
import { useQueryParams } from '@sherweb/core/hooks'
import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

import { ShopFilter } from './ShopFilter'
import { ShopFilterTriggerContent } from './ShopFilterTriggerContent'

type ShopBillingCycleFilterProps = {
  facets?: Facet[]
}

export const ShopBillingCycleFilter = ({ facets }: ShopBillingCycleFilterProps) => {
  const { t } = useTranslation()

  const billingCycleOptions = facets?.find(
    ({ fieldName }) => fieldName === 'offers.billing_cycle'
  )?.countsByValue

  const { getArrayParam } = useQueryParams<'productsBillingCycle'>()

  const queryBillingCycleSelectedValues = getArrayParam<BillingCycle>('productsBillingCycle')

  return (
    <ShopFilter
      dataTestId="billingCycle"
      options={billingCycleOptions}
      queryParamType="productsBillingCycle"
      trigger={
        <Button
          size="small"
          variant="None"
          className={mergeClassName(
            'flex items-center px-4 py-2 text-slate-900 hover:bg-white/50 dark:text-slate-100',
            {
              'bg-white/50': queryBillingCycleSelectedValues.length !== 0,
            }
          )}
        >
          <ShopFilterTriggerContent
            text={t('ssp:pages.shop.filters.billingCycle')}
            icon={<CurrencyDollarIcon className="mr-1 h-4 w-4" />}
            value={queryBillingCycleSelectedValues}
          />
        </Button>
      }
    />
  )
}
