import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import DataTable, { Columns } from '@sherweb/core/components/DataTable'

import { ProductAvatarWithName, ProductAvatarWithNameProps } from '@ssp/components/Avatars'
import { License, useLicenses, useRefreshData } from '@ssp/modules/license'

import SubscribersDialog from '../SubscribersDialog'
import { LicenseTableActionRow, LicenseTableActionRowProps } from './LicenseTableActionRow'

const CLASSNAME = 'text-center'

const renderProductNameRowContent = ({ fullName }: ProductAvatarWithNameProps) => (
  <ProductAvatarWithName fullName={fullName} />
)

const renderActionRowContent = ({
  license,
  setSelectedLicense,
  setOpenDialog,
}: LicenseTableActionRowProps) => (
  <LicenseTableActionRow
    license={license}
    setSelectedLicense={setSelectedLicense}
    setOpenDialog={setOpenDialog}
  />
)

const LicenseTable: React.FC = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: licenses } = useLicenses()
  const { refreshData } = useRefreshData()
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedLicense, setSelectedLicense] = useState<License>()

  const columns: Columns<License> = [
    {
      accessorKey: 'productName',
      header: t('ssp:pages.licenses.list.columns.productName'),
      cell: ({ cell }) => renderProductNameRowContent({ fullName: cell.getValue<string>() }),
      minSize: 417,
    },
    {
      accessorKey: 'vendorName',
      header: t('ssp:pages.licenses.list.columns.vendorName'),
      meta: { className: CLASSNAME },
      minSize: 278,
    },
    {
      accessorKey: 'availableQuantity',
      header: t('ssp:pages.licenses.list.columns.licenses'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      minSize: 139,
    },
    {
      accessorKey: 'numberUsers',
      header: t('ssp:pages.licenses.list.columns.users'),
      enableSorting: true,
      meta: { className: CLASSNAME },
      minSize: 139,
    },
    {
      id: 'actions',
      cell: ({ row }) =>
        renderActionRowContent({ license: row.original, setSelectedLicense, setOpenDialog }),
      minSize: 139,
    },
  ]

  if (isLoading || error) {
    return null
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={licenses}
        dataTestId="tableLicenses"
        filterableFields={['productName']}
        filterPlaceholder={t('ssp:pages.licenses.list.filterPlaceholder')}
        fieldDescription={t('ssp:pages.licenses.list.searchFieldDescription')}
        optionalActions={
          <Button
            variant="primary"
            size="sm"
            data-testid="btnRefreshLicenses"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshData()
            }}
          >
            {t('ssp:pages.licenses.refresh')}
          </Button>
        }
      />
      {selectedLicense && (
        <SubscribersDialog
          open={openDialog}
          onOpenChange={setOpenDialog}
          license={selectedLicense}
        />
      )}
    </>
  )
}

export default LicenseTable
