import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationOrderQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'

import { useOrders } from '@ssp/modules/orders'

import {
  renderCreatedOnRowContent,
  useRenderOrderType,
  useRenderStatusBadge,
} from '../orders.helper'

export const OrdersTableMobile = () => {
  const { t } = useTranslation()
  const orders = useOrders()
  const [filteredText, setFilteredText] = useState('')

  const renderStatusBadge = useRenderStatusBadge()
  const renderOrderType = useRenderOrderType()

  const filteredData = orders?.data?.filter((order: OrganizationOrderQueryResult) => {
    return (
      (order.humanReadableId ?? '').toLowerCase().includes(filteredText.toLowerCase()) ||
      order.productName?.toLowerCase().includes(filteredText.toLowerCase())
    )
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="relative mb-4 flex w-full items-center md:mb-0 md:w-1/4 md:min-w-[250px]">
        <MagnifyingGlassIcon
          className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
          aria-hidden="true"
        />
        <input
          className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
          type="text"
          value={filteredText}
          data-testid="inputFilterTableMobile"
          placeholder={t('ssp:pages.orders.filterPlaceholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilteredText(event.target.value)
          }}
        />
      </div>
      {filteredData?.map((order: OrganizationOrderQueryResult) => {
        return (
          <Accordion key={order.orderId} type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {order.humanReadableId} - {order.productName}
                <AccordionTriggerSubContent>
                  {renderStatusBadge(order.orderStatus)}
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.id')}
                    value={order.humanReadableId}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.productName')}
                    value={order.productName}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.createdOn')}
                    value={renderCreatedOnRowContent(order.createdOn)}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.type')}
                    value={renderOrderType(order.type)}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.initialQuantity')}
                    value={order.initialQuantity}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.requestedQuantity')}
                    value={order.requestedQuantity}
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.orders.list.status')}
                    value={renderStatusBadge(order.orderStatus)}
                  />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })}
    </div>
  )
}

export default OrdersTableMobile
