import { QueryKey } from '@tanstack/react-query'

import { PortalType } from '../../../openapi-generated/index.defs'
import { PortalMetadataService } from '../../../openapi-generated/PortalMetadataService'
import { PortalMode } from './portalMetadata.utils'

export const portalMetadataQuery = {
  queryKey: (): QueryKey => ['portalMetadata'],
  queryFn: async (mode: PortalMode) =>
    await PortalMetadataService.getPortalMetadata({
      portalType: mode === PortalMode.SSP ? PortalType.SSP : PortalType.RSP,
    }),
  staleTime: 60 * 60 * 1000,
}
