import { useTranslation } from 'react-i18next'

import { dateFormatTypes, useDateFormatter } from '@sherweb/core/utils/date'

export type OrdersTableCreatedOnRowProps = {
  date: Date
}

export const OrdersTableCreatedOnRow = ({ date }: OrdersTableCreatedOnRowProps) => {
  const {
    i18n: { language },
  } = useTranslation()
  const formatDate = useDateFormatter(language, dateFormatTypes.datetime)

  const formattedDateTime = formatDate(date)?.toString()
  const formattedDate = formattedDateTime?.split(' ').slice(0, 3).join(' ')
  const time = formattedDateTime?.split(' ').slice(3).join(' ')

  const formattedTime = time?.replace(/^0/, '')

  return (
    <div className="flex flex-row items-center gap-4">
      <div className="mb-1 flex flex-row justify-between gap-1.5 text-sm font-medium sm:text-sm md:mb-0 lg:flex-col lg:gap-0">
        <div className="font-semibold">{formattedDate}</div>
        <div className="font-light">{formattedTime}</div>
      </div>
    </div>
  )
}
