import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Card from '@sherweb/core/components/Card'
import PageTitle, { PageSubTitle, PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import { useOrganizations } from '@rsp/modules/organizations'

import OrganizationsTable from './OrganizationsTable/OrganizationsTable'
import OrganizationsTableMobile from './OrganizationsTableMobile/OrganizationsTableMobile'

const OrganizationsPage: React.FC = () => {
  const { t } = useTranslation()
  const organizations = useOrganizations()
  const noOrganization =
    organizations.isFetched &&
    !organizations.isLoading &&
    !organizations.error &&
    !organizations?.data?.length

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={
            <PageTitle data-testid="pageOrganizationsTitle">
              {t('rsp:pages.organizations.title')}
            </PageTitle>
          }
          subTitle={<PageSubTitle>{t('rsp:pages.organizations.subtitle')}</PageSubTitle>}
        />

        {organizations.isLoading && <Spinner floating />}

        {noOrganization && (
          <div
            className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
            data-testid="containerNoOrganizations"
          >
            <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" />
            <p>{t('rsp:pages.organizations.noOrganization')}</p>
          </div>
        )}

        {(organizations?.data?.length ?? 0) > 0 && (
          <>
            <div className="hidden lg:block">
              <Card padded>
                <OrganizationsTable />
              </Card>
            </div>

            <div className="mt-4 lg:hidden">
              <Card padded>
                <OrganizationsTableMobile />
              </Card>
            </div>
          </>
        )}
      </div>
    </ContainedPage>
  )
}

export default protectPage(OrganizationsPage)
