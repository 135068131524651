/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowPathIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import Button from '@sherweb/core/components/Button'
import { usePrevious } from '@sherweb/core/hooks'
import { Permission } from '@sherweb/core/modules/authorization'

import { HasAccess } from '@ssp/modules/authorization'
import { License, useLicenses, useRefreshData } from '@ssp/modules/license'

import SubscribersDialog from '../SubscribersDialog'

const LicenseTableMobile: React.FC = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: licenses = [] } = useLicenses()
  const previousLicenses = usePrevious(licenses)
  const { refreshData } = useRefreshData()
  const [filteredList, setFilteredList] = useState([] as License[])
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedLicense, setSelectedLicense] = useState<License>()
  const getUsage = (available: number, used: number) => {
    if (available <= 0) {
      throw new Error('error')
    }

    const availableLicensesQty = available - used

    return (
      <div>
        <span className="font-base">{t('ssp:pages.licenses.list.availableLicenses')}</span>{' '}
        {availableLicensesQty}
      </div>
    )
  }

  const filterBySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value
    let updatedList = licenses

    updatedList = updatedList.filter((item: License) => {
      return item.productName?.toLowerCase().includes(query.toLowerCase())
    })

    setFilteredList(updatedList)
  }

  useEffect(() => {
    if (!previousLicenses && previousLicenses !== licenses) {
      setFilteredList(licenses)
    }
  }, [licenses, previousLicenses])

  if (isLoading || error) {
    return null
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="mb-4 flex justify-between gap-4">
        <div className="relative flex w-full items-center md:mb-0 md:w-1/4 md:min-w-[250px]">
          <MagnifyingGlassIcon
            className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
            aria-hidden="true"
          />
          <input
            className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
            type="text"
            placeholder={t('ssp:pages.licenses.list.filterPlaceholder')}
            data-testid="inputFilterTableMobile"
            onChange={filterBySearch}
          />
        </div>
        <Button
          variant="primary"
          size="sm"
          onClick={() => {}}
          data-testid="btnRefreshLicensesMobile"
          asChild
        >
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              refreshData()
            }}
          >
            <ArrowPathIcon className="h-4 w-4" />
          </Button>
        </Button>
      </div>
      {filteredList.map((license: License) => {
        return (
          <Accordion key={license.id} type="single" collapsible data-testid="licenseAccordion">
            <AccordionItem value="item-1">
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {license.productName}
                <AccordionTriggerSubContent>
                  {getUsage(license.availableQuantity, license.numberUsers)}
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('ssp:pages.licenses.list.columns.vendorName')}
                    value={license.vendorName}
                    dataTestId="accVendorNameLicenses"
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.licenses.list.columns.licenses')}
                    value={license.availableQuantity}
                    dataTestId="accAvailibilityLicenses"
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.licenses.list.columns.users')}
                    value={license.numberUsers}
                    dataTestId="accNumberUserLicenses"
                  />
                  <AccordionContentListItem
                    label=""
                    value={
                      <HasAccess permission={Permission.LicenseDetails}>
                        <Button
                          variant="outline"
                          size="sm"
                          type="button"
                          data-testid="btnManageSubscribersMobile"
                          onClick={() => {
                            setSelectedLicense(license)
                            setOpenDialog(true)
                          }}
                        >
                          {t('ssp:pages.licenses.manage')}
                        </Button>
                      </HasAccess>
                    }
                  />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })}

      {selectedLicense && (
        <SubscribersDialog
          open={openDialog}
          onOpenChange={setOpenDialog}
          license={selectedLicense}
        />
      )}
    </div>
  )
}

export default LicenseTableMobile
