/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StepWizardChildProps } from 'react-step-wizard'
import * as z from 'zod'

import Button from '@sherweb/core/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'

import {
  Reseller,
  useSetSupportInformation,
  useUpdateOnboardingResellerAction,
} from '@rsp/modules/reseller'

import supportInformationSchema from '../validationSchema/supportInformationSchema'

export type SupportInformationStepProps = {
  reseller: Reseller
  isFormValid: boolean
} & Partial<StepWizardChildProps>

const SupportInformationStep: React.FC<SupportInformationStepProps> = ({ ...props }) => {
  const { t } = useTranslation()
  const { setSupportInformation } = useSetSupportInformation()
  const { updateOnboarding } = useUpdateOnboardingResellerAction()

  const form = useForm<z.infer<typeof supportInformationSchema>>({
    resolver: zodResolver(supportInformationSchema),
    defaultValues: {
      supportEmail: props.reseller.supportInformation.email,
      supportPhoneNumber: props.reseller.supportInformation.phoneNumber,
    },
  })

  async function onSubmit(values: z.infer<typeof supportInformationSchema>) {
    if (props?.nextStep !== undefined) {
      await setSupportInformation(
        values.supportEmail ?? '',
        values.supportPhoneNumber ?? '',
        props?.reseller?.supportInformation?.address ?? ''
      )
      await updateOnboarding()

      props?.nextStep()
    }
  }

  return (
    <div className="mt-6 flex flex-col gap-4 md:mt-12" data-testid="support-information-step">
      <div className="flex flex-col gap-4 md:gap-12">
        <h1 className="text-3xl font-medium text-slate-900 dark:text-slate-200">
          {t('rsp:pages.onboarding.supportInformation.title')}
        </h1>
        <p className="text-sm text-slate-500 dark:text-slate-200">
          {t('rsp:pages.onboarding.supportInformation.description')}
        </p>
      </div>
      <div className="flex flex-col gap-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="mt-2 flex flex-col gap-4">
            <FormField
              control={form.control}
              name="supportEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('rsp:pages.onboarding.supportInformation.labels.supportEmail')}
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      {...field}
                      placeholder={t(
                        'rsp:pages.onboarding.supportInformation.placeholders.supportEmail'
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="supportPhoneNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('rsp:pages.onboarding.supportInformation.labels.supportPhoneNumber')}
                  </FormLabel>
                  <FormControl>
                    <FormInput
                      {...field}
                      placeholder={t(
                        'rsp:pages.onboarding.supportInformation.placeholders.supportPhoneNumber'
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-4 flex flex-row justify-between">
              <Button variant="outline" type="button" onClick={props.previousStep}>
                {t('rsp:pages.onboarding.previousStep')}
              </Button>
              <Button type="submit" className="w-1/3" disabled={!props.isFormValid}>
                {t('rsp:pages.onboarding.nextStep')}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}

export default SupportInformationStep
