import { ResellerOrganizationsService } from '@sherweb/core/openapi-generated/ResellerOrganizationsService'

export const detailedOrganizationsByResellerId = {
  queryKey: (resellerId?: string) => [
    'ResellerOrganizationsService/resellerOrganizationsGetAvailableDetailedOrganizations',
    resellerId,
  ],
  queryFn: async (resellerId?: string) =>
    resellerId
      ? await ResellerOrganizationsService.resellerOrganizationsGetAvailableDetailedOrganizations({
          resellerId,
        })
      : [],
}
