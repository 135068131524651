import { useTranslation } from 'react-i18next'

import { getSignOutUrl } from '@config'

import { Avatar, AvatarFallback } from '@sherweb/core/components/Avatar'
import { DarkModeSwitch } from '@sherweb/core/components/DarkModeSwitch'
import Menu, { MenuItem } from '@sherweb/core/components/Menu'
import { useCurrentUser } from '@sherweb/core/modules/user'
import { getInitials } from '@sherweb/core/utils/initials'

import LanguageSelector from '@ssp/components/LanguageSelector'
import Link from '@ssp/components/Link/Link'
import { useOrigin } from '@ssp/modules/navigation'
import { useThemeActions, useThemeDarkModeState } from '@ssp/modules/theme'

const UserMenu: React.FC = () => {
  const { t } = useTranslation()

  const { data: user } = useCurrentUser()

  const signOutUrl = getSignOutUrl()

  const darkMode = useThemeDarkModeState()
  const { setDarkMode } = useThemeActions()
  const origin = useOrigin()
  const rspUrl = `${origin}/admin`

  if (!user) {
    return null
  }

  const getUserFullName = () => {
    if (!user.firstName || !user.lastName) {
      return
    }
    return `${user.firstName} ${user.lastName}`
  }

  const fullName = getUserFullName()

  return (
    <Menu
      buttonContent={
        <div className="flex flex-row items-center gap-3 rounded-full md:pr-0">
          <Avatar>
            <AvatarFallback className="dark:text-slate-200">{getInitials(fullName)}</AvatarFallback>
          </Avatar>
        </div>
      }
      className="border border-slate-800 bg-slate-950 focus-visible:outline-offset-0 focus-visible:outline-indigo-300 dark:border dark:border-slate-800 dark:bg-slate-900 sm:border-slate-200 sm:bg-slate-200"
      data-testid="user-menu"
    >
      <div>
        <MenuItem
          className="cursor-default text-gray-900 hover:bg-transparent dark:text-white dark:hover:text-white dark:focus-visible:text-primary"
          disabled
        >
          <div className="flex min-w-[225px] flex-col items-center gap-y-2 px-1 pb-2 pt-1">
            <div className="flex h-11 w-11 items-center justify-center rounded-full border border-slate-200 bg-slate-300 dark:border-slate-800 dark:bg-slate-900">
              <Avatar>
                <AvatarFallback className="dark:bg-slate-950 dark:text-slate-200">
                  {getInitials(fullName)}
                </AvatarFallback>
              </Avatar>
            </div>
            <div className="flex flex-col items-center justify-center gap-1 pl-4 pr-2">
              {fullName && (
                <div className="text-sm font-semibold leading-none" data-testid="menuUserFullName">
                  {fullName}
                </div>
              )}
              {user.jobTitle && <div className="text-sm text-gray-500">{user.jobTitle}</div>}
              {user?.isResellerAdminOrInternalAdmin ? (
                <Link
                  isAbsolute
                  to={rspUrl}
                  target="_blank"
                  data-testid="linkAdminUrl"
                  rel="noopener noreferrer"
                  className="inline-block text-sm text-blue-600 focus-visible:outline-indigo-300"
                >
                  {t('ssp:layout.userMenu.goToAdmin')}
                </Link>
              ) : null}
            </div>
          </div>
        </MenuItem>
      </div>

      <div>
        <MenuItem autoClose={false} className="flex items-center justify-between whitespace-nowrap">
          {t('ssp:layout.userMenu.darkModeToggle.label')}
          <DarkModeSwitch darkMode={darkMode} setDarkMode={setDarkMode} />
        </MenuItem>
      </div>

      <div>
        <MenuItem autoClose={false} className="flex items-center justify-between whitespace-nowrap">
          {t('ssp:layout.userMenu.languageToggle.label')}
          <LanguageSelector />
        </MenuItem>
      </div>

      <div>
        <a href={signOutUrl} className="mt-10 w-3/4" data-testid="linkSignOutUrl">
          <MenuItem>{t('ssp:layout.userMenu.signOut')}</MenuItem>
        </a>
      </div>
    </Menu>
  )
}

export default UserMenu
