import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { CoreRoutes } from '@sherweb/core/common/coreRoutes'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import {
  useAuthenticationLoggedInState,
  useAuthenticationState,
} from '@sherweb/core/modules/authentication'
import { Permission } from '@sherweb/core/modules/authorization'

import Routes from '@ssp/app/Routes'
import { usePermissionCheck } from '@ssp/modules/authorization'
import { useNavigate, usePathGenerator } from '@ssp/modules/navigation'
import {
  useOrganizationIsSuspended,
  useOrganizationsBeenActivated,
  useSelectedOrganizationId,
} from '@ssp/modules/organization'

type ProtectedPageProps = {
  Page: React.FC
  RequiredPermission?: Permission
}

const ProtectedPage: React.FC<ProtectedPageProps> = props => {
  const { isAuthenticated } = useAuthenticationState()

  const { isLoggedIn, isLoading, isFetching } = useAuthenticationLoggedInState()

  const { navigate } = useNavigate()

  const generatePath = usePathGenerator()

  const { hasAccess, permissionsLoading, permissionsFetched } = usePermissionCheck()

  const location = useLocation()

  const selectedOrganizationId = useSelectedOrganizationId()

  const { isLoading: isActivatedOrganizationsLoading, hasNoActivatedOrganizations } =
    useOrganizationsBeenActivated()

  const showNonActivatedOrganizationsPage =
    hasNoActivatedOrganizations && isAuthenticated && !permissionsLoading

  const {
    isLoading: isSuspendedLoading,
    isSuspended,
    selectedOrganizationUniqueName,
  } = useOrganizationIsSuspended()

  const showSuspendedOrganizationsPage = isSuspended && isAuthenticated && !permissionsLoading

  useEffect(() => {
    const relativePath = CoreRoutes.ForbiddenAccess

    if (
      !isAuthenticated &&
      !isLoggedIn &&
      !(isLoading || isFetching) &&
      location.pathname !== Routes.Login
    ) {
      navigate(Routes.Login)
    }

    if (
      !window.location.href.includes(relativePath) &&
      isLoggedIn &&
      !permissionsLoading &&
      permissionsFetched &&
      props.RequiredPermission !== undefined &&
      !hasAccess(props.RequiredPermission) &&
      props.RequiredPermission !== Permission.AccessToSuspendedOrganization
    ) {
      navigate(relativePath)
    }
  }, [
    isAuthenticated,
    isLoggedIn,
    isLoading,
    permissionsFetched,
    navigate,
    permissionsLoading,
    hasAccess,
    props.RequiredPermission,
    isFetching,
    location.pathname,
  ])

  useEffect(() => {
    if (showNonActivatedOrganizationsPage && !selectedOrganizationId) {
      navigate(Routes.OrganizationsNotActivated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNonActivatedOrganizationsPage])

  useEffect(() => {
    if (
      showSuspendedOrganizationsPage &&
      selectedOrganizationUniqueName &&
      props.RequiredPermission !== undefined &&
      !hasAccess(props.RequiredPermission)
    ) {
      navigate(
        generatePath(Routes.OrganizationSuspended, {
          organizationUniqueName: selectedOrganizationUniqueName,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuspendedOrganizationsPage])

  if (
    isAuthenticated &&
    !permissionsLoading &&
    !isActivatedOrganizationsLoading &&
    !isSuspendedLoading &&
    !!selectedOrganizationId
  ) {
    return <props.Page />
  }

  return <Spinner floating />
}

export const protectPage = (page: React.FC, permission?: Permission): React.FC => {
  return () => <ProtectedPage Page={page} RequiredPermission={permission} />
}
