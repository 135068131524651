import {
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  ListBulletIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { SidebarMenu as CoreSideBarMenu, SidebarMenuItem } from '@sherweb/core/components/Sidebar'

import Routes from '@rsp/app/Routes'
import Link from '@rsp/components/Link'
import { useLocation, useNavigate } from '@rsp/modules/navigation'

const Sidebar: React.FC = () => {
  const { t } = useTranslation()

  const { pathname } = useLocation()

  const { navigate } = useNavigate()

  const onLogoClick = () => {
    navigate(Routes.Settings)
  }

  const menuItems = [
    {
      label: t('rsp:layout.menu.orders'),
      to: Routes.Orders,
      Icon: DocumentDuplicateIcon,
      dataTestId: 'menuItemOrders',
    },
    {
      label: t('rsp:layout.menu.integrations'),
      to: Routes.Integrations,
      Icon: PuzzlePieceIcon,
      dataTestId: 'menuItemIntegrations',
    },
    {
      label: t('rsp:layout.menu.organizations'),
      to: Routes.Organizations,
      Icon: ListBulletIcon,
      dataTestId: 'menuItemOrganizations',
    },
    {
      label: t('rsp:layout.menu.settings'),
      to: Routes.Settings,
      Icon: Cog6ToothIcon,
      dataTestId: 'menuItemSettings',
    },
  ]

  const isCurrent = (to: string) => {
    if (to === '/admin') {
      return to === pathname
    }
    return pathname.startsWith(to)
  }

  return (
    <CoreSideBarMenu
      logo={`${process.env.PUBLIC_URL}/logo512-blue.svg`}
      onLogoClick={onLogoClick}
      logoContainerClassName="mt-10 mb-6 px-6"
    >
      {menuItems.map(({ label, to, Icon, dataTestId }) => (
        <Link key={to} to={to} className="block">
          <SidebarMenuItem dataTestId={dataTestId} Icon={Icon} current={isCurrent(to)}>
            {label}
          </SidebarMenuItem>
        </Link>
      ))}
    </CoreSideBarMenu>
  )
}

export default Sidebar
