/* eslint-disable @typescript-eslint/no-unused-vars */
import { MagnifyingGlassIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'
import { usePrevious } from '@sherweb/core/hooks'
import { Permission } from '@sherweb/core/modules/authorization'
import { useMoneyFormatter } from '@sherweb/core/utils/money'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link'
import { HasAccess } from '@ssp/modules/authorization'
import { Subscription, useStatusBadge, useSubscriptions } from '@ssp/modules/subscription'

const SubscriptionTableMobile: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { isLoading, error, data: subscriptions = [] } = useSubscriptions()
  const previousSubscriptions = usePrevious(subscriptions)
  const formatMoneyAmount = useMoneyFormatter(language)
  const getStatusBadge = useStatusBadge()
  const [filteredList, setFilteredList] = useState([] as Subscription[])

  const filterBySearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value
    let updatedList = subscriptions

    updatedList = updatedList.filter((item: Subscription) => {
      return item.productName?.toLowerCase().includes(query.toLowerCase())
    })
    setFilteredList(updatedList)
  }

  useEffect(() => {
    if (!previousSubscriptions && previousSubscriptions !== subscriptions) {
      setFilteredList(subscriptions)
    }
  }, [previousSubscriptions, subscriptions])

  if (isLoading || error) {
    return null
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="relative mb-4 flex w-full items-center md:mb-0 md:w-1/4 md:min-w-[250px]">
        <MagnifyingGlassIcon
          className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
          aria-hidden="true"
        />
        <input
          className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
          type="text"
          data-testid="inputFilterTableMobile"
          placeholder={t('ssp:pages.subscriptions.list.filterPlaceholder')}
          onChange={filterBySearch}
        />
      </div>
      {filteredList.map((subscription: Subscription, index) => {
        return (
          <Accordion key={subscription.id} type="single" collapsible>
            <AccordionItem value="item-1" data-testid={`listSubscription${index}`}>
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {subscription.productName}
                <AccordionTriggerSubContent>
                  {getStatusBadge(subscription.statusWithOrders)}
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('ssp:pages.subscriptions.list.columns.quantity')}
                    value={subscription?.quantity}
                    dataTestId="accQuantitySubscriptions"
                  />
                  <HasAccess permission={Permission.SubscriptionPrices}>
                    <AccordionContentListItem
                      label={t('ssp:pages.subscriptions.list.columns.price')}
                      value={subscription.price ? formatMoneyAmount(subscription.price) : null}
                      dataTestId="accPriceamountSubscriptions"
                    />
                    <AccordionContentListItem
                      label={t('ssp:pages.subscriptions.list.columns.total')}
                      value={subscription.total ? formatMoneyAmount(subscription.total) : null}
                      dataTestId="accTotalamountSubscriptions"
                    />
                  </HasAccess>
                  <AccordionContentListItem
                    label={t('ssp:pages.subscriptions.list.columns.status')}
                    value={getStatusBadge(subscription?.statusWithOrders)}
                    dataTestId="accStatusWithOrdersSubscriptions"
                  />
                  <AccordionContentListItem
                    label={t('ssp:pages.subscriptions.list.columns.billingCycle')}
                    value={subscription.billingCycle}
                    dataTestId="accBillingCycleSubscriptions"
                  />
                  <HasAccess permission={Permission.SubscriptionDetails}>
                    <AccordionContentListItem
                      label=""
                      value={
                        <Link
                          to={Routes.Subscription}
                          data-testid="linkEditSubscription"
                          params={{ subscriptionId: subscription.id }}
                        >
                          <div className="flex flex-row gap-2 text-indigo-500">
                            <PencilSquareIcon className="inline h-5 w-5 stroke-1 text-indigo-500 dark:stroke-2" />
                            <span>{t('ssp:pages.subscriptions.list.edit')}</span>
                          </div>
                        </Link>
                      }
                      dataTestId="accActionsSubscriptions"
                    />
                  </HasAccess>
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })}
    </div>
  )
}

export default SubscriptionTableMobile
