import {
  ImageResultDto,
  ImageType,
  ResellersQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import { Reseller } from './reseller.models'

export const buildReseller = (response?: ResellersQueryResult): Reseller => {
  const addHash = (url?: string, hash?: number) => {
    if (url && hash) {
      return `${url}?${hash}`
    }
    return url
  }

  return {
    id: response?.id ?? '',
    name: response?.name ?? '',
    isOnboarded: response?.isOnboarded ?? false,
    portalSettings: {
      logo: addHash(
        getImageByType(response?.portalSettings?.images, ImageType.Logo)?.url,
        response?.portalSettings?.logoHash
      ),
      favicon: addHash(
        getImageByType(response?.portalSettings?.images, ImageType.Icon)?.url,
        response?.portalSettings?.logoHash
      ),
      logoHash: response?.portalSettings?.logoHash,
      theme: response?.portalSettings?.theme,
    },
    supportInformation: {
      address: response?.supportInformation?.address,
      email: response?.supportInformation?.email,
      phoneNumber: response?.supportInformation?.phoneNumber,
    },
    resellerSettings: {
      priceVisible: response?.resellerSettings?.priceVisible ?? false,
      isSubresellerShown: response?.resellerSettings?.isSubresellerShown ?? false,
      showUsers: response?.resellerSettings?.showUsers ?? false,
      showBilling: response?.resellerSettings?.showBilling ?? false,
      showLicenses: response?.resellerSettings?.showLicenses ?? false,
      showSubscriptions: response?.resellerSettings?.showSubscriptions ?? false,
      orgAdminCanCreateUsers: response?.resellerSettings?.orgAdminCanCreateUsers ?? false,
    },
    orderSettings: {
      isOrderApprovalRequired: response?.orderSettings?.isOrderApprovalRequired ?? false,
      teamNotifications: {
        enabled: response?.orderSettings?.teamsNotificationsEnabled ?? false,
        webHookUrl: response?.orderSettings?.teamsIncomingWebhook ?? '',
      },
      emailNotifications: {
        enabled: response?.orderSettings?.emailNotificationsEnabled ?? false,
        allUsersEnabled: response?.orderSettings?.emailRecipientsList?.length === 0,
        emails: response?.orderSettings?.emailRecipientsList ?? [],
      },
    },
  }
}

export const getImageByType = (images: ImageResultDto[] | undefined, type: ImageType) => {
  return images?.find(p => p.type === type)
}
