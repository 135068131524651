import { useTranslation } from 'react-i18next'

import { DetailedOrganizationQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import DataTable, { Columns } from '@sherweb/core/components/DataTable'

import { useOrganizations } from '@rsp/modules/organizations'

import { useRenderSettingsType, useRenderStatusBadge } from '../organizations.helper'

const defaultMeta = { className: 'align-top text-center' }

const OrganizationsTable: React.FC = () => {
  const { t } = useTranslation()
  const { data: organizations = [] } = useOrganizations()
  const renderSettingsType = useRenderSettingsType()
  const renderStatusBadge = useRenderStatusBadge()

  const columns: Columns<DetailedOrganizationQueryResult> = [
    {
      accessorKey: 'organizationName',
      meta: { className: 'align-top' },
      header: t('rsp:pages.organizations.list.name'),
      cell: ({ row }) => row.original.organizationName,
      minSize: 400,
    },
    {
      accessorKey: 'userCount',
      header: t('rsp:pages.organizations.list.users'),
      meta: { ...defaultMeta },
      cell: ({ row }) => row.original.userCount,
    },
    {
      accessorKey: 'subscriptionsCount',
      header: t('rsp:pages.organizations.list.subscriptions'),
      meta: { ...defaultMeta },
      cell: ({ row }) => row.original.subscriptionCount,
    },
    {
      accessorKey: 'settings',
      meta: { ...defaultMeta },
      header: t('rsp:pages.organizations.list.settings'),
      cell: ({ row }) => renderSettingsType(row.original.organizationSettings?.isDefault),
    },
    {
      accessorKey: 'status',
      meta: { ...defaultMeta },
      header: t('rsp:pages.organizations.list.status'),
      cell: ({ row }) => renderStatusBadge(row.original.isSuspended),
    },
  ]

  return (
    <DataTable
      dataTestId="tableOrganizations"
      columns={columns}
      data={organizations}
      filterableFields={['organizationName']}
      filterPlaceholder={t('rsp:pages.organizations.list.filterPlaceholder')}
      fieldDescription={t('rsp:pages.organizations.list.searchFieldDescription')}
    />
  )
}

export default OrganizationsTable
