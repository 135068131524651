import { PortalMetadata } from './portalMetadata.models'

export enum PortalMode {
  RSP = 'RSP',
  SSP = 'SSP',
}

export const setManifestAndDocumentMeta = (
  mode: PortalMode,
  data: PortalMetadata,
  bffUrl?: string
) => {
  if (bffUrl) {
    setAttribute(
      'manifest-placeholder',
      'href',
      `${bffUrl}/${mode === PortalMode.SSP ? 'ssp' : 'rsp'}-manifest.webmanifest`
    )
  }
  data.icon && setAttribute('icon-placeholder', 'href', data.icon)
  data.themeColor && setAttribute('theme-color-placeholder', 'content', data.themeColor)
  data.shortName && (document.title = data.shortName)
}

const setAttribute = (elemId: string, attribute: string, value: string) => {
  const elem = document.getElementById(elemId)
  if (elem) {
    elem.setAttribute(attribute, value)
  }
}
