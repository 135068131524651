import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
  CreateOrderItemDto,
  Facet,
  SearchProductsResponse,
} from '@sherweb/core/openapi-generated/index.defs'
import { OrganizationPurchaseOrdersService } from '@sherweb/core/openapi-generated/OrganizationPurchaseOrdersService'

import { successNotification } from '@sherweb/core/components/ToastNotifications'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { missingParametersError } from '@sherweb/core/utils/error'

import { useSelectedOrganization, useSelectedOrganizationId } from '@ssp/modules/organization'

import { buildProduct } from './shop.builder'
import { productById, search } from './shop.queries'
import { ISearchOptions } from './shop.types'

type UseGetProductsQueryProps = {
  searchOptions: ISearchOptions
  enabled?: boolean
}

export const useGetProductsQuery = ({
  searchOptions,
  enabled = true,
}: UseGetProductsQueryProps) => {
  const selectedOrganization = useSelectedOrganization()

  const { isLoggedIn } = useAuthenticationLoggedInState()

  return useInfiniteQuery({
    queryKey: search.queryKey(searchOptions, selectedOrganization?.id),
    queryFn: async ({ pageParam = 1 }) =>
      await search.queryFn(searchOptions, pageParam, selectedOrganization?.id),
    enabled: isLoggedIn && !!selectedOrganization?.id && enabled,
    keepPreviousData: true,
    staleTime: search.staleTime,
    getNextPageParam: (lastPage, pages) => {
      return (
        pages?.reduce((total, page) => total + Math.round(page.foundProducts), 0) < lastPage?.total
      )
    },
  })
}

export const useGetProductsFacetsQuery = ({
  searchOptions,
  enabled = true,
}: UseGetProductsQueryProps): Omit<
  UseInfiniteQueryResult<SearchProductsResponse, unknown>,
  'data'
> & { data?: Facet[] } => {
  const productsQuery = useGetProductsQuery({ searchOptions, enabled })

  return {
    ...productsQuery,
    data: productsQuery?.data?.pages?.[0]?.facets,
  }
}

export const useGetProductById = (productId?: string) => {
  return useQuery({
    queryKey: productById.queryKey(productId),
    queryFn: async () => await productById.queryFn(productId),
    enabled: !!productId,
    staleTime: productById.staleTime,
    select: data => buildProduct(data),
  })
}

export const usePlaceOrderMutation = () => {
  const { t } = useTranslation()

  const selectedOrganizationId = useSelectedOrganizationId()

  return useMutation({
    mutationFn: async (data: { serviceProviderId?: string; orderItems: CreateOrderItemDto[] }) => {
      if (!data.serviceProviderId || !selectedOrganizationId) {
        throw missingParametersError()
      }

      if (data.orderItems.length === 0) {
        throw new Error('Must provide at least one order item')
      }

      await OrganizationPurchaseOrdersService.placeShoppingOrder({
        organizationId: selectedOrganizationId,
        command: {
          serviceProviderId: data.serviceProviderId,
          orderItems: data.orderItems,
        },
      })
    },
    onSuccess: () => {
      successNotification(t('ssp:pages.shop.detail.placeOrderSuccess'))
    },
  })
}
