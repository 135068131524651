import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
  CountResult as ApiCountResult,
  ProcessOrderDecision,
  SubscriptionQueryResult,
} from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'
import Tooltip from '@sherweb/core/components/Tooltip'
import { buildCountResult, CountResult } from '@sherweb/core/modules/common'
import { useInvalidateQuery } from '@sherweb/core/modules/reactQuery'

import { useSelectedOrganization, useSelectedOrganizationId } from '@ssp/modules/organization'

import {
  buildSubscriptions,
  mapUpdateOnDataOnProcessOrder,
  mapUpdateOnDismissOrderError,
  mapUpdateUpdateQuantity,
} from './subscription.builder'
import {
  LastPendingOrFailedOrder,
  OrderDecision,
  OrderStatus,
  Subscription,
  SubscriptionBillingCycle,
  SubscriptionCommitmentType,
  SubscriptionStatus,
} from './subscription.model'
import { createOrder, dismissOrderError, processOrder } from './subscription.mutations'
import {
  subscriptionCountByOrganizationId,
  subscriptionsByOrganizationId,
} from './subscription.queries'
import { DismissOrderErrorPayload, ProcessOrderPayload } from './subscription.type'

export const useSubscriptionCount = () => {
  const selectedOrganization = useSelectedOrganization()

  return useQuery<ApiCountResult, Error, CountResult>({
    queryKey: subscriptionCountByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () => await subscriptionCountByOrganizationId.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    initialData: () => buildCountResult(),
    select: data => buildCountResult(data),
    refetchInterval: (data, _) => {
      if (!data || data.isRecalculating) {
        return 1000
      }
      return subscriptionCountByOrganizationId.staleTime
    },
  })
}

export const useSubscriptions = ({ reload = false, ...queryArgs } = {}) => {
  const selectedOrganization = useSelectedOrganization()

  const queryClient = useQueryClient()
  useInvalidateQuery(
    subscriptionsByOrganizationId.queryKey(selectedOrganization?.id),
    reload,
    queryClient
  )

  return useQuery<SubscriptionQueryResult[], Error, Subscription[]>({
    queryKey: subscriptionsByOrganizationId.queryKey(selectedOrganization?.id),
    queryFn: async () => await subscriptionsByOrganizationId.queryFn(selectedOrganization?.id),
    enabled: !!selectedOrganization,
    staleTime: subscriptionsByOrganizationId.staleTime,
    select: data => buildSubscriptions(data),
  })
}

export const useSubscription = (subscriptionId?: string, queryArgs = {}) => {
  const subscriptions = useSubscriptions(queryArgs)
  const subscription = subscriptions?.data?.find(s => s.id === subscriptionId)
  const subscriptionNotFound = subscriptions.isFetched && !subscriptions.isLoading && !subscription

  return {
    ...subscriptions,
    data: subscription,
    error: subscriptions.error ?? (subscriptionNotFound ? new Error('not found') : undefined),
  }
}

export const useUpdateQuantityAction = (subscriptionId?: string) => {
  const queryClient = useQueryClient()
  const selectedOrganizationId = useSelectedOrganizationId()
  const mutation = useMutation({
    mutationFn: async (data: {
      organizationId: string
      quantity: number
      subscriptionId: string
    }) => await createOrder.mutationFn(data),
    onSuccess: async (data, variables) => {
      queryClient.setQueryData<Subscription[]>(
        subscriptionsByOrganizationId.queryKey(selectedOrganizationId),
        oldData => mapUpdateUpdateQuantity(oldData, variables, data)
      )
    },
    onError: (error, variables) => {
      throw error
    },
  })

  return {
    updateQuantity: async (quantity: number) => {
      if (!subscriptionId) {
        throw new Error('Missing subscription id')
      }
      const result = await mutation.mutateAsync({
        organizationId: selectedOrganizationId ?? '',
        quantity,
        subscriptionId,
      })
      if (mutation.isError) {
        throw mutation.error
      }

      return result
    },
    ...mutation,
  }
}

export const useDismissOrderError = () => {
  const queryClient = useQueryClient()
  const selectedOrganizationId = useSelectedOrganizationId()
  const mutation = useMutation({
    mutationFn: async (data: DismissOrderErrorPayload) => await dismissOrderError.mutationFn(data),
    onSuccess: async (_, variables) => {
      queryClient.setQueryData<Subscription[]>(
        subscriptionsByOrganizationId.queryKey(selectedOrganizationId),
        oldData => mapUpdateOnDismissOrderError(oldData, variables)
      )
    },
    onError: (error, variables) => {
      throw error
    },
  })

  return {
    dismissOrderError: async (orderId?: string, subscriptionId?: string) => {
      if (!subscriptionId) {
        throw new Error('Missing subscription id')
      }
      if (!orderId) {
        throw new Error('Missing order id')
      }
      await mutation.mutateAsync({
        organizationId: selectedOrganizationId ?? '',
        orderLines: [{ subscriptionId, orderId }],
      })
      if (mutation.isError) {
        throw mutation.error
      }
    },
    ...mutation,
  }
}

export const useProcessOrder = () => {
  const queryClient = useQueryClient()
  const selectedOrganizationId = useSelectedOrganizationId()
  const mutation = useMutation({
    // eslint-disable-next-line @typescript-eslint/return-await
    mutationFn: async (data: ProcessOrderPayload) => await processOrder.mutationFn(data),
    onSuccess: async (_, variables) => {
      queryClient.setQueryData<Subscription[]>(
        subscriptionsByOrganizationId.queryKey(selectedOrganizationId),
        oldData => mapUpdateOnDataOnProcessOrder(oldData, variables)
      )
    },
    onError: (error, variables) => {
      throw error
    },
  })

  return {
    processOrder: async (
      orderId?: string,
      decision?: OrderDecision | null,
      decisionNote?: string
    ) => {
      if (!decision) {
        throw new Error('Missing decision')
      }
      if (!orderId) {
        throw new Error('Missing order id')
      }
      const payload = {
        decision:
          decision === OrderDecision.Approve
            ? ProcessOrderDecision.Approve
            : ProcessOrderDecision.Reject,
        orderIds: [orderId],
        organizationId: selectedOrganizationId ?? '',
        decisionNote,
      }
      await mutation.mutateAsync(payload)
      if (mutation.isError) {
        throw mutation.error
      }
    },
    ...mutation,
  }
}

export const useCycleTranslation = () => {
  const { t } = useTranslation()
  return (value?: SubscriptionBillingCycle | null) => {
    switch (value) {
      case SubscriptionBillingCycle.Daily:
        return t('ssp:subscription.cycle.daily')
      case SubscriptionBillingCycle.Weekly:
        return t('ssp:subscription.cycle.weekly')
      case SubscriptionBillingCycle.Monthly:
        return t('ssp:subscription.cycle.monthly')
      case SubscriptionBillingCycle.Yearly:
        return t('ssp:subscription.cycle.yearly')
    }
  }
}

export const useCycleBillingTranslation = () => {
  const { t } = useTranslation()
  return (value?: SubscriptionBillingCycle | null) => {
    switch (value) {
      case SubscriptionBillingCycle.Daily:
        return t('ssp:subscription.cycleBilling.daily')
      case SubscriptionBillingCycle.Weekly:
        return t('ssp:subscription.cycleBilling.weekly')
      case SubscriptionBillingCycle.Monthly:
        return t('ssp:subscription.cycleBilling.monthly')
      case SubscriptionBillingCycle.Yearly:
        return t('ssp:subscription.cycleBilling.yearly')
    }
  }
}

export const useCycleBillingFullTranslation = () => {
  const { t } = useTranslation()
  return (value?: SubscriptionBillingCycle | null) => {
    switch (value) {
      case SubscriptionBillingCycle.Daily:
        return t('ssp:subscription.cycleBillingFull.daily')
      case SubscriptionBillingCycle.Weekly:
        return t('ssp:subscription.cycleBillingFull.weekly')
      case SubscriptionBillingCycle.Monthly:
        return t('ssp:subscription.cycleBillingFull.monthly')
      case SubscriptionBillingCycle.Yearly:
        return t('ssp:subscription.cycleBillingFull.yearly')
    }
  }
}

export const useCommitmentRenewalTranslation = () => {
  const { t } = useTranslation()
  return (value?: SubscriptionCommitmentType | null) => {
    switch (value) {
      case SubscriptionCommitmentType.Annual:
        return t('ssp:subscription.commitmentRenewal.annual')
      case SubscriptionCommitmentType.Biennial:
        return t('ssp:subscription.commitmentRenewal.biennial')
      case SubscriptionCommitmentType.Triennial:
        return t('ssp:subscription.commitmentRenewal.triennial')
      case SubscriptionCommitmentType.Monthly:
        return t('ssp:subscription.commitmentRenewal.monthly')
    }
  }
}

export const useStatusBadge = () => {
  const { t } = useTranslation()
  // eslint-disable-next-line react/display-name
  return (
    value?: SubscriptionStatus | null,
    lastPendingOrFailedOrder?: LastPendingOrFailedOrder | null
  ) => {
    switch (value) {
      case SubscriptionStatus.New:
        return <Badge variant={Variant.Success}>{t('ssp:subscription.status.new')}</Badge>
      case SubscriptionStatus.Active:
        return <Badge variant={Variant.Success}>{t('ssp:subscription.status.active')}</Badge>
      case SubscriptionStatus.Processing:
        return <Badge variant={Variant.Success}>{t('ssp:subscription.status.processing')}</Badge>
      case SubscriptionStatus.Pending: {
        const pendingBadge = (
          <Badge variant={Variant.Warning}>{t('ssp:subscription.status.pending')}</Badge>
        )
        if (!lastPendingOrFailedOrder) {
          return pendingBadge
        }
        return (
          <Tooltip
            data-testid="tooltip"
            tooltip={t('ssp:pages.subscriptions.list.pendingQuantity', {
              pendingQuantity: lastPendingOrFailedOrder?.quantity,
            })}
          >
            {pendingBadge}
          </Tooltip>
        )
      }
      case SubscriptionStatus.WaitingForApproval:
        return (
          <Badge variant={Variant.Warning}>{t('ssp:subscription.status.waitingForApproval')}</Badge>
        )
      case SubscriptionStatus.Failed: {
        const failedBadge = (
          <Badge variant={Variant.Danger}>{t('ssp:subscription.status.failed')}</Badge>
        )
        if (!lastPendingOrFailedOrder) {
          return failedBadge
        }
        return (
          <Tooltip
            data-testid="tooltip"
            tooltip={t('ssp:pages.subscriptions.list.pendingQuantityError', {
              pendingQuantity: lastPendingOrFailedOrder?.quantity,
              error: lastPendingOrFailedOrder?.error?.content,
            })}
          >
            {failedBadge}
          </Tooltip>
        )
      }
      case SubscriptionStatus.Inactive:
        return <Badge variant={Variant.Default}>{t('ssp:subscription.status.inactive')}</Badge>
      case SubscriptionStatus.Suspended:
        return <Badge variant={Variant.Warning}>{t('ssp:subscription.status.suspended')}</Badge>
      default:
        return null
    }
  }
}

export const useOrderStatusBadge = () => {
  const { t } = useTranslation()
  // eslint-disable-next-line react/display-name
  return (value?: OrderStatus | null) => {
    switch (value) {
      case OrderStatus.Failed:
        return <Badge variant={Variant.Danger}>{t('ssp:subscription.order.status.failed')}</Badge>
      case OrderStatus.Rejected:
        return <Badge variant={Variant.Danger}>{t('ssp:subscription.order.status.rejected')}</Badge>
      case OrderStatus.Pending:
        return <Badge variant={Variant.Warning}>{t('ssp:subscription.order.status.pending')}</Badge>
      case OrderStatus.WaitingForApproval:
        return (
          <Badge variant={Variant.Warning}>
            {t('ssp:subscription.order.status.waitingForApproval')}
          </Badge>
        )
      default:
        return null
    }
  }
}
