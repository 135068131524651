import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@sherweb/core/components/DropdownMenu'
import { Permission } from '@sherweb/core/modules/authorization'
import { usePathGenerator } from '@sherweb/core/modules/browserRouter'

import Routes from '@rsp/app/Routes'
import { HasAccess } from '@rsp/modules/authorization'
import { AmendmentOrder, OrderDecision, OrderStatus, useProcessOrder } from '@rsp/modules/orders'

import { OrderAprovalConfirmationDialog } from '../OrderAprovalConfirmationDialog'

export type OrdersTableRowActionsProps = {
  order: AmendmentOrder
}

export const OrdersTableRowActions = ({ order }: OrdersTableRowActionsProps) => {
  const decisionFlag = order?.orderStatus === OrderStatus.WaitingForApproval
  const viewSubscriptionFlag = order?.firstSubscriptionId && order?.organizationUniqueName
  const pathGenerator = usePathGenerator()
  const processOrderMutation = useProcessOrder()
  const { t } = useTranslation()
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [decision, setDecision] = useState<OrderDecision | null>(null)

  const navigateToSubscriptionView = () => {
    if (order?.firstSubscriptionId && order?.organizationUniqueName) {
      const subscriptionPath = pathGenerator(Routes.Subscription, {
        subscriptionId: order?.firstSubscriptionId ?? '',
        organizationUniqueName: order?.organizationUniqueName ?? '',
      })
      window.open(subscriptionPath, '_blank')
    }
  }

  const setDecisionAndOpenDialog = (decision: OrderDecision) => {
    setDecision(decision)
    setConfirmationDialogOpened(true)
    setDropdownOpen(false)
  }

  const cancelConfirmation = () => {
    setDecision(null)
    setConfirmationDialogOpened(false)
    setDropdownOpen(false)
  }

  const onSubmit = (decision: OrderDecision, decisionNote?: string) => {
    processOrderMutation.mutate({
      orderId: order.id,
      organizationId: order.organizationId,
      decision,
      decisionNote,
    })
  }

  if (!viewSubscriptionFlag && !decisionFlag) {
    return null
  }

  return (
    <>
      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild data-testid="dropdownViewOrderOptions">
          <Button variant="ghost" className="h-8 w-8 p-0">
            <EllipsisVerticalIcon className="mr-0.5 w-6 text-gray-500 hover:bg-slate-50" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {viewSubscriptionFlag && (
            <DropdownMenuItem
              onClick={() => navigateToSubscriptionView()}
              data-testid="dropdownItemViewSubscription"
            >
              {t('rsp:pages.orders.list.actions.viewSubscription')}
            </DropdownMenuItem>
          )}
          {decisionFlag && (
            <HasAccess permission={Permission.OrderApproval}>
              <DropdownMenuItem
                data-testid="dropdownItemApprove"
                onClick={() => setDecisionAndOpenDialog(OrderDecision.Approve)}
              >
                {t('rsp:pages.orders.list.actions.approve')}
              </DropdownMenuItem>
              <DropdownMenuItem
                data-testid="dropdownItemReject"
                onClick={() => setDecisionAndOpenDialog(OrderDecision.Reject)}
              >
                {t('rsp:pages.orders.list.actions.reject')}
              </DropdownMenuItem>
            </HasAccess>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {decisionFlag && decision && (
        <OrderAprovalConfirmationDialog
          decision={decision}
          order={order}
          cancelConfirmation={cancelConfirmation}
          onSubmit={onSubmit}
          confirmationDialogOpened={confirmationDialogOpened}
        />
      )}
    </>
  )
}
