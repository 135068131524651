import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'
import { ElementType } from 'react'

import { mergeClassName } from '@sherweb/core/utils/mergeClassName'

type ListItemProps = {
  children?: React.ReactNode
  className?: string
  asChild?: boolean
  as?: ElementType
}

type ListProps = {
  children?: React.ReactNode
  className?: string
}

export const listItemVariants = cva(
  'font-regular flex w-full space-x-3 border-t border-slate-300 p-4 text-sm text-gray-900 first:rounded-t-[0.2rem] first:border-none last:rounded-b-[0.2rem] hover:cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 dark:bg-slate-900 dark:text-white'
)

const ListActions = ({ children }: ListItemProps) => {
  return <div className="mr-4 flex items-center">{children}</div>
}

const List = ({ children, className = '' }: ListProps) => {
  return (
    <ul className={mergeClassName('border border-t border-slate-300 rounded', className)}>
      {children}
    </ul>
  )
}

const ListItem = ({
  asChild,
  as,
  children,
  className = '',
  ...props
}: ListItemProps & React.HTMLAttributes<HTMLLIElement>) => {
  const Component = asChild ? Slot : as ?? 'li'

  return (
    <Component {...props} className={mergeClassName(listItemVariants(), className)}>
      {children}
    </Component>
  )
}

List.Item = ListItem
List.Actions = ListActions

export default List
