import { mergeClassName } from '../../../utils/mergeClassName'

type ReactTitle = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>

const CardTitle: React.FC<ReactTitle> = ({ children, ...props }) => {
  return (
    <h3
      {...props}
      className={mergeClassName(
        'truncate text-sm font-medium text-slate-500 dark:text-slate-200',
        props?.className
      )}
    >
      {children}
    </h3>
  )
}

export default CardTitle
