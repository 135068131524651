import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import config from '@config'

import Spinner from '@sherweb/core/components/Spinner'
import { useLoadGoogleAnalytics } from '@sherweb/core/hooks'
import {
  PortalMode,
  setManifestAndDocumentMeta,
  usePortalMetadata,
} from '@sherweb/core/modules/portalMetadata'
import { useCurrentUser } from '@sherweb/core/modules/user'

import Routes from '@ssp/app/Routes'
import { usePermissionCheck } from '@ssp/modules/authorization'
import { useAvailableOrganizations } from '@ssp/modules/organization'
import { useReseller, useResellerActions, useResellerPortalSettings } from '@ssp/modules/reseller'
import { useThemeActions } from '@ssp/modules/theme'
import Error404 from '@ssp/pages/errors/Error404'

import { setDatadogUserSession } from '../../datadog'

type AppGateProps = {
  children?: React.ReactNode
}

/**
 * App Gate make sure that the required data is available before rendering app
 */
const AppGate: React.FC<AppGateProps> = ({ children }) => {
  const portalSettings = useResellerPortalSettings()

  const portalMetadata = usePortalMetadata()

  const { permissionsLoading } = usePermissionCheck()

  const { isLoading: isLoadingReseller, data: reseller } = useReseller()

  const { setSelectedReseller } = useResellerActions()

  const user = useCurrentUser()

  const availableOrganizations = useAvailableOrganizations()

  const { setTheme } = useThemeActions()

  const { sendPageViewEvent } = useLoadGoogleAnalytics()

  useEffect(() => {
    sendPageViewEvent()
  }, [sendPageViewEvent])

  /* Set user session for datadog */
  useEffect(() => {
    if (user?.data) {
      setDatadogUserSession(
        user.data?.id ?? '',
        `${user?.data.firstName ?? ''} ${user.data?.lastName ?? ''}`,
        user.data.email ?? ''
      )
    }
  }, [user?.data])

  /* set theme */
  useEffect(() => {
    if (!portalSettings?.data?.theme) {
      return
    }
    setTheme(portalSettings.data.theme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalSettings?.data?.theme])

  /* set manifest */
  useEffect(() => {
    setManifestAndDocumentMeta(PortalMode.SSP, portalMetadata?.data, config.bff.url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portalMetadata?.data?.icon, portalMetadata?.data?.shortName])

  useEffect(() => {
    if (reseller) {
      setSelectedReseller(reseller.id)
    }
  }, [reseller, setSelectedReseller])

  useEffect(() => {
    if (!!reseller.id && !reseller.isOnboarded && !isLoadingReseller) {
      window.location.href = Routes.Onboarding
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reseller.isOnboarded, isLoadingReseller])

  const loadingApp =
    portalSettings.isLoading ||
    user.isLoading ||
    availableOrganizations.isLoading ||
    isLoadingReseller ||
    permissionsLoading

  if (portalSettings.error) {
    return <Error404 fullScreen />
  }

  if (loadingApp) {
    return <Spinner floating />
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children ? <>{children}</> : <Outlet />
}

export default AppGate
