import { useTranslation } from 'react-i18next'

import Card, { CardMetric, CardTitle } from '@sherweb/core/components/Card'
import Spinner from '@sherweb/core/components/Spinner/Spinner'
import { take } from '@sherweb/core/utils/array'

import Routes from '@ssp/app/Routes'
import Link from '@ssp/components/Link/Link'
import ServerError from '@ssp/components/ServerError/ServerError'
import { useUnusedLicenseStatistics } from '@ssp/modules/license'

// TODO: Fix trim warning
const UnusedLicenseCountWidget = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: stats } = useUnusedLicenseStatistics()

  const itemsToShow = 3

  return (
    <>
      {error && <ServerError error={error} />}
      {stats && (
        <Card padded={false} className="flex min-h-[160px] flex-col justify-between p-6">
          <CardTitle>{t('ssp:pages.dashboard.widgets.UnusedLicenseCountWidget.title')}</CardTitle>
          <CardMetric data-testid="cardContentUnusedLicenses" className="mt-4 flex flex-auto">
            {!(isLoading || stats.isRecalculating) && (
              <div className="grid grid-cols-[auto_auto] gap-x-8">
                <span>{stats.unusedLicensesCount}</span>
                <div className="text-sm font-normal not-italic text-slate-700 dark:text-white">
                  <div className="grid auto-rows-min grid-cols-1 gap-x-1 gap-y-0.5">
                    {take(stats.unusedLicenses, itemsToShow).map(item => (
                      <div key={`${item.name.trim()}`} className="flex">
                        <span className="truncate">{item.name}</span>
                        <span className="ml-1 font-semibold">({item.unusedLicenseCount})</span>
                      </div>
                    ))}
                    {stats.unusedLicenses?.length > itemsToShow &&
                      t('ssp:pages.dashboard.widgets.UnusedLicenseCountWidget.more', {
                        count: stats.unusedLicenses.length - itemsToShow,
                      })}
                  </div>
                </div>
              </div>
            )}
            {(isLoading || stats.isRecalculating) && <Spinner className="h-4 w-4" />}
          </CardMetric>
          <Link
            className="inline w-fit text-sm text-indigo-600 underline focus-visible:outline-indigo-300"
            to={Routes.Licenses}
            data-testid="linkViewUnusedLicenses"
          >
            {t('ssp:pages.dashboard.widgets.UnusedLicenseCountWidget.viewAll')}
          </Link>
        </Card>
      )}
    </>
  )
}

export default UnusedLicenseCountWidget
