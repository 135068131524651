import { Row } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { HelpDeskTicketQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import { Typography } from '@sherweb/core/components/Typography'

export type HelpDeskTicketTableTitleProps = {
  row?: Row<HelpDeskTicketQueryResult>
}

export const HelpDeskTicketTableTitle = ({ row }: HelpDeskTicketTableTitleProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <Typography className="line-clamp-2" variant="body2" weight="semiBold">
        {row?.original.subject}
      </Typography>
      <div className="flex whitespace-nowrap">
        <Typography variant="label" colors="light">
          {row?.original.openedByName ? (
            <>
              {t('ssp:pages.helpdesk.list.columns.createdBy')}
              &nbsp;{row?.original.openedByName}
            </>
          ) : null}
        </Typography>
      </div>
    </div>
  )
}
