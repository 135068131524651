import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Alert, { Variant } from '@sherweb/core/components/Alert'
import Backlink from '@sherweb/core/components/Backlink'
import Card from '@sherweb/core/components/Card'
import { Markdown, useGetMarkdownPreviewer } from '@sherweb/core/components/Markdown'
import PageTitle from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@sherweb/core/components/Tabs'
import { errorNotification, successNotification } from '@sherweb/core/components/ToastNotifications'
import { Permission } from '@sherweb/core/modules/authorization'
import { useDateFormatter } from '@sherweb/core/utils/date'

import { protectPage } from '@ssp/app/ProtectedPage'
import Routes from '@ssp/app/Routes'
import ContainedPage from '@ssp/components/layout/ContainedPage'
import Link from '@ssp/components/Link/Link'
import { HasAccess, usePermissionCheck } from '@ssp/modules/authorization'
import { useReseller } from '@ssp/modules/reseller'
import { useDismissOrderError, useUpdateQuantityAction } from '@ssp/modules/subscription'
import Error404 from '@ssp/pages/errors/Error404'

import { useGetSubscription } from './hooks/useGetSubscription'
import LastOrder from './LastOrders/LastOrders'
import MetaInformation from './MetaInformation/MetaInformation'
import OrderApproval from './OrderApproval'
import PriceSection from './PriceSection'
import QuantityForm from './QuantityForm'
import Summary from './Summary'

const SubscriptionPage: React.FC = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const formatDate = useDateFormatter(language)

  const { isLoading: isLoadingReseller, data: reseller } = useReseller()
  const supportInformationEmail = reseller?.supportInformation?.email

  const { convertMarkdown } = useGetMarkdownPreviewer()

  const { hasAccess } = usePermissionCheck()

  const subscription = useGetSubscription()
  const [quantity, setQuantity] = useState(subscription?.quantity)

  useEffect(() => {
    if (!quantity) {
      setQuantity(subscription?.quantity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription?.quantity])

  const { updateQuantity, isLoading: updating } = useUpdateQuantityAction(
    subscription?.subscriptionId
  )

  const { dismissOrderError, isLoading: updatingError } = useDismissOrderError()

  const showSpinner = subscription?.isLoading ?? updating ?? isLoadingReseller

  const onSubmit = async (quantity: number) => {
    try {
      await updateQuantity(quantity)
      successNotification(t('ssp:pages.subscription.updateSuccess'))
    } catch (error) {
      errorNotification(t('ssp:errors.generic'))
    }
  }

  const onDismissError = async () => {
    try {
      await dismissOrderError(subscription?.lastPendingOrFailedOrder?.orderId, subscription?.id)
    } catch (error) {
      errorNotification(t('ssp:errors.generic'))
    }
  }

  if (subscription?.notFoundError) {
    return <Error404 />
  }

  return (
    <ContainedPage>
      {showSpinner ? <Spinner floating /> : null}
      {subscription && !subscription.isLoading ? (
        <div className="flex flex-col gap-8 md:gap-16">
          <div>
            <Link to={Routes.Subscriptions} data-testid="linkBackToSubscriptions">
              <Backlink>{t('ssp:pages.subscription.backlink')}</Backlink>
            </Link>
            <PageTitle data-testid="pageSubscriptionTitle">{subscription?.productName}</PageTitle>
          </div>
          <div className="flex flex-col gap-8">
            {subscription?.hasLastOrderPendingQuantity ? (
              <div>
                <Alert variant={Variant.Warning}>
                  {t('ssp:pages.subscription.quantityForm.pendingQuantityWarning')}
                </Alert>
              </div>
            ) : null}
            {subscription?.showLicenseUpdateFailedAlert && !updatingError && (
              <div>
                <Alert
                  variant={Variant.Danger}
                  onClose={hasAccess(Permission.SubscriptionEdit) ? onDismissError : undefined}
                >
                  <Trans
                    i18nKey="ssp:pages.subscription.quantityForm.lastOrderPendingQuantityError"
                    values={{
                      subscriptionName: subscription?.productName,
                      subscriptionUpdateDelta: subscription?.lastOrderFailedQuantityDelta,
                      subscriptionUpdateOperation: subscription?.subscriptionUpdateOperation,
                      subscriptionUpdateDate: formatDate(
                        subscription?.lastPendingOrFailedOrder?.createdAt
                      ),
                    }}
                  >
                    {supportInformationEmail ? (
                      <Link
                        to={`mailto: ${supportInformationEmail}`}
                        className="font-semibold hover:underline"
                      />
                    ) : null}
                  </Trans>
                </Alert>
              </div>
            )}
            <Card padded className="grid w-full grid-cols-6   gap-12 xl:w-full xl:gap-32">
              <div className="col-span-6 flex flex-col gap-8 md:gap-12 lg:col-span-3">
                <MetaInformation subscriptionId={subscription?.subscriptionId} />
                <HasAccess permission={Permission.SubscriptionEdit}>
                  <QuantityForm onQuantityChange={setQuantity} onSubmit={onSubmit} />
                </HasAccess>
              </div>
              <div className="col-span-6 lg:col-span-3">
                <div className="flex flex-col gap-6 md:gap-10">
                  <PriceSection subscriptionId={subscription?.subscriptionId} quantity={quantity} />
                  {subscription?.showLastOrder && (
                    <LastOrder
                      humanReadableid={subscription?.lastPendingOrFailedOrder?.humanReadableId}
                      createdAt={subscription?.lastPendingOrFailedOrder?.createdAt}
                      deltaQuantity={subscription?.lastOrderFailedQuantityDelta}
                      operationType={subscription?.subscriptionUpdateOperation}
                      orderStatus={subscription?.lastPendingOrFailedOrder?.status}
                      decisionNote={subscription?.lastPendingOrFailedOrder?.decisionNote}
                    />
                  )}
                  <OrderApproval
                    orderId={subscription?.lastPendingOrFailedOrder?.orderId}
                    orderStatus={subscription?.lastPendingOrFailedOrder?.status}
                  />
                </div>
              </div>
            </Card>
          </div>
          <Card padded>
            <Tabs defaultValue="details" className="w-full">
              <TabsList>
                <TabsTrigger
                  data-testid="tabsTriggerSubscriptionDetails"
                  value="details"
                  className="focus-visible:ring-indigo-300"
                >
                  {t('ssp:pages.subscription.details.tabs.subscriptionDetails.title')}
                </TabsTrigger>
                {supportInformationEmail && (
                  <TabsTrigger
                    value="manage"
                    data-testid="tabsTriggerManageSubscription"
                    className="focus-visible:ring-indigo-300"
                  >
                    {t('ssp:pages.subscription.details.tabs.manageSubscription.title')}
                  </TabsTrigger>
                )}
              </TabsList>
              <TabsContent
                data-testid="tabsContentSubscriptionDetails"
                value="details"
                className="focus-visible:ring-indigo-300"
              >
                <div className="flex flex-col gap-8 px-4 pb-4 pt-10">
                  {subscription?.hasSummary ? (
                    <div className="w-full lg:w-6/12 ">
                      <Summary subscriptionId={subscription?.subscriptionId} quantity={quantity} />
                    </div>
                  ) : null}
                  <div className="flex flex-col gap-4">
                    <p className="text-base font-semibold leading-none text-slate-800 dark:text-slate-200">
                      {t('ssp:pages.subscription.details.descriptionTitle')}
                    </p>
                    <Markdown
                      content={convertMarkdown(subscription?.description)}
                      className="product_description"
                    />
                  </div>
                </div>
              </TabsContent>
              <TabsContent
                value="manage"
                className="focus-visible:ring-indigo-300"
                data-testid="tabsContentManageSubscription"
              >
                <a
                  href={'mailto:' + (supportInformationEmail ?? '')}
                  className="flex flex-row gap-4 px-4 pb-4 pt-10"
                >
                  <div className="inline-flex h-11 w-11 items-center justify-center rounded-lg bg-indigo-100">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-indigo-500"
                    >
                      <path
                        d="M11.9998 19H12.0098M8.2168 7.69689C8.75729 6.12753 10.2468 5 11.9997 5C14.2089 5 15.9997 6.79086 15.9997 9C15.9997 10.6565 14.9929 12.0778 13.5577 12.6852C12.817 12.9988 12.4466 13.1556 12.317 13.2767C12.1627 13.4209 12.1334 13.4651 12.0608 13.6634C11.9997 13.8299 11.9997 14.0866 11.9997 14.6L11.9998 16"
                        stroke="black"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-sm font-medium text-slate-900 dark:text-slate-200">
                      {t('ssp:pages.subscription.details.tabs.manageSubscription.getHelp.title')}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-slate-500">
                      {t(
                        'ssp:pages.subscription.details.tabs.manageSubscription.getHelp.description'
                      )}
                    </p>
                  </div>
                </a>
              </TabsContent>
            </Tabs>
          </Card>
        </div>
      ) : null}
    </ContainedPage>
  )
}

export default protectPage(SubscriptionPage, Permission.SubscriptionDetails)
