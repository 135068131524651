import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DetailedOrganizationQueryResult } from '@sherweb/core/openapi-generated/index.defs'

import {
  Accordion,
  AccordionContent,
  AccordionContentList,
  AccordionContentListItem,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerSubContent,
} from '@sherweb/core/components/Accordion'

import { useOrganizations } from '@rsp/modules/organizations'

import { useRenderSettingsType, useRenderStatusBadge } from '../organizations.helper'

const OrganizationsTableMobile = () => {
  const { t } = useTranslation()
  const { data: organizations = [] } = useOrganizations()
  const [filteredText, setFilteredText] = useState('')
  const renderSettingsType = useRenderSettingsType()
  const renderStatusBadge = useRenderStatusBadge()

  const filteredData = organizations.filter((organization: DetailedOrganizationQueryResult) => {
    return organization.organizationName?.toLowerCase().includes(filteredText.toLowerCase())
  })

  return (
    <div className="flex flex-col gap-4">
      <div className="relative mb-4 flex w-full items-center md:mb-0 md:w-1/4 md:min-w-[250px]">
        <MagnifyingGlassIcon
          className="absolute left-3 h-5 w-5 text-gray-500 focus-within:text-gray-600"
          aria-hidden="true"
        />
        <input
          className="w-full rounded-lg border border-gray-300 py-2 pl-10 text-left text-sm font-normal leading-none text-gray-600 outline-none focus:border-blue-500 focus:ring-blue-500 dark:border-none dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700"
          type="text"
          value={filteredText.toLowerCase()}
          data-testid="inputFilterTableMobile"
          placeholder={t('rsp:pages.organizations.list.filterPlaceholder')}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFilteredText(event.target.value)
          }}
        />
      </div>
      {filteredData.map((organization: DetailedOrganizationQueryResult) => {
        return (
          <Accordion key={organization.organizationId} type="single" collapsible>
            <AccordionItem value="item-1">
              <AccordionTrigger className="relative justify-between rounded-xl bg-white p-4 [&[data-state=open]>div>div]:hidden [&[data-state=open]]:rounded-b-none">
                {organization.organizationName}
                <AccordionTriggerSubContent>
                  {renderStatusBadge(organization.isSuspended)}
                </AccordionTriggerSubContent>
              </AccordionTrigger>
              <AccordionContent>
                <AccordionContentList>
                  <AccordionContentListItem
                    label={t('rsp:pages.organizations.list.users')}
                    value={organization.userCount}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.organizations.list.subscriptions')}
                    value={organization.subscriptionCount}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.organizations.list.settings')}
                    value={renderSettingsType(organization.organizationSettings?.isDefault)}
                  />
                  <AccordionContentListItem
                    label={t('rsp:pages.organizations.list.status')}
                    value={renderStatusBadge(organization.isSuspended)}
                  />
                </AccordionContentList>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )
      })}
    </div>
  )
}

export default OrganizationsTableMobile
