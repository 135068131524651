import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useQueryParams = <T extends string>() => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getParams = useCallback(
    <R = string>(key: T) => (searchParams.get(key) ?? '') as R,
    [searchParams]
  )

  const setArrayParams = useCallback(
    (key: T, arr: string) => {
      setSearchParams(previousParam => {
        previousParam.set(
          key,
          previousParam.get(key) ? [previousParam.get(key), arr].join(',') : [arr].join(',')
        )

        return previousParam
      })
    },
    [setSearchParams]
  )

  const updateSearchParams = useCallback(
    (key: T, value?: string) => {
      setSearchParams(previousParam => {
        previousParam.set(key, value ?? '')

        return previousParam
      })
    },
    [setSearchParams]
  )

  const getArrayParam = useCallback(
    <R = string>(key: T) => {
      const param = getParams(key)

      return (param ? param.split(',') : []) as R[]
    },
    [getParams]
  )

  const removeSearchParam = useCallback(
    (key: T) => {
      if (searchParams.has(key)) {
        searchParams.delete(key)
        setSearchParams(searchParams)
      }
    },
    [searchParams, setSearchParams]
  )

  const removeArrayParam = useCallback(
    (key: T, value = '') => {
      if (searchParams.has(key)) {
        const values = getParams(key)
          .split(',')
          .filter(v => v !== value)

        if (values.length === 0) {
          searchParams.delete(key)
          setSearchParams(searchParams)
        } else {
          setSearchParams(previousParam => {
            previousParam.set(key, values.join(','))

            return previousParam
          })
        }
      }
    },
    [getParams, searchParams, setSearchParams]
  )

  const removeAllQueryParams = useCallback(() => {
    setSearchParams({})
  }, [setSearchParams])

  return {
    searchParams,
    getParams,
    setSearchParams: updateSearchParams,
    removeAllQueryParams,
    removeSearchParam,
    setArrayParams,
    getArrayParam,
    removeArrayParam,
  }
}
