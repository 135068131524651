import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { ServiceProviderConfigurationQueryResult } from '@sherweb/core/openapi-generated/index.defs'
import { ServiceProvidersConfigurationService } from '@sherweb/core/openapi-generated/ServiceProvidersConfigurationService'

import { successNotification } from '@sherweb/core/components/ToastNotifications'
import { missingParametersError } from '@sherweb/core/utils/error'

import { useSelectedResellerId } from '@rsp/modules/reseller'
import { SherwebServiceProviderFormType } from '@rsp/pages/integrations/serviceProvider/sherweb/hooks/useSherwebServiceProviderSchema'

import { serviceProviders, serviceProvidersConfigurations } from './serviceProvider.queries'

export const useServiceProviders = () => {
  return useQuery({
    queryKey: serviceProviders.queryKey(),
    queryFn: async () => await serviceProviders.queryFn(),
    staleTime: serviceProviders.staleTime,
  })
}

export const useServiceProvidersConfigurations = <
  TResult = ServiceProviderConfigurationQueryResult[],
>(
  options?: Omit<
    UseQueryOptions<ServiceProviderConfigurationQueryResult[], Error, TResult>,
    'queryKey'
  >
) => {
  const resellerId = useSelectedResellerId()

  return useQuery<ServiceProviderConfigurationQueryResult[], Error, TResult>({
    queryKey: serviceProvidersConfigurations.queryKey(resellerId),
    queryFn: async () => await serviceProvidersConfigurations.queryFn(resellerId),
    staleTime: serviceProvidersConfigurations.staleTime,
    ...options,
  })
}

export const useGetSherwebServiceProviderQuery = () => {
  return useServiceProvidersConfigurations<ServiceProviderConfigurationQueryResult | undefined>({
    // TODO: Add enumeration on the backend side rather than just getting first element in service provider
    select: (serviceProviders?: ServiceProviderConfigurationQueryResult[]) => serviceProviders?.[0],
  })
}

export const usePostServiceProviderConfigurationMutation = () => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const resellerId = useSelectedResellerId()

  return useMutation({
    mutationFn: async (data: SherwebServiceProviderFormType) => {
      if (!resellerId || !data?.serviceProviderId) {
        throw missingParametersError()
      }

      return await ServiceProvidersConfigurationService.setServiceProvidersConfiguration({
        resellerId,
        command: {
          serviceProviderId: data.serviceProviderId,
          privateApiKey: data?.clientSecret,
          publicApiKey: data?.clientId,
          subscriptionKey: data?.subscriptionKey,
        },
      })
    },
    meta: {
      errorMessage: t('rsp:pages.integrations.serviceProvider.sherweb.updateFailureMessage'),
    },
    onSuccess: async () => {
      successNotification(t('rsp:pages.integrations.serviceProvider.sherweb.updateSuccessMessage'))

      await queryClient.invalidateQueries({
        queryKey: serviceProvidersConfigurations.queryKey(resellerId),
      })
    },
  })
}
