import { MoneyAmount } from '@sherweb/core/common/money'

export type AmendmentOrder = {
  id: string
  createdOn?: Date | null
  organizationName?: string | null
  organizationUniqueName?: string | null
  userFirstName?: string | null
  userLastName?: string | null
  userEmail?: string | null
  firstSubscriptionId?: string
  organizationId?: string
  orderValue?: MoneyAmount | null
  orderStatus?: OrderStatus | null
  decisionNote?: string | null
  humanReadableId?: string | null
  error?: OrderError | null
}

export type OrderError = {
  message?: string
  title?: string
  detail?: string
}

export type ProcessOrder = {
  orderId?: string
  organizationId?: string | null
  decision?: OrderDecision | null
  decisionNote?: string
}

export enum OrderStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Failed = 'FAILED',
  WaitingForApproval = 'WAITINGFORAPPROVAL',
  Rejected = 'REJECTED',
}

export enum OrderDecision {
  Approve = 'Approve',
  Reject = 'Reject',
}
