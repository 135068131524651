import { validateUuid } from '@sherweb/core/utils/uuid'

import { useParams } from '@rsp/modules/navigation'
import { OrderDecision } from '@rsp/modules/orders'

export const useOrderApprovalDecisionProps = () => {
  const params = useParams()
  const orderId = validateUuid(params.orderId) ? String(params.orderId) : undefined
  const decisionParam = String(params.decision)?.toLowerCase()
  const decision =
    decisionParam === OrderDecision.Approve.toLowerCase()
      ? OrderDecision.Approve
      : OrderDecision.Reject
  const havingRequiredProps = !!orderId && !!decisionParam

  return { havingRequiredProps, orderId, decision }
}
