import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Button from '@sherweb/core/components/Button'
import { Typography } from '@sherweb/core/components/Typography'
import { useQueryParams } from '@sherweb/core/hooks'

const MAX_QUANTITY = 999

type ShopProductQuantityProps = {
  quantity: number
}

const ShopProductQuantity = ({ quantity }: ShopProductQuantityProps) => {
  const { t } = useTranslation()

  const { setSearchParams } = useQueryParams<'productQuantity'>()

  const updateQuantity = (quantity: number) => {
    const totalQuantity = Math.max(Math.min(quantity, MAX_QUANTITY), 0)

    setSearchParams('productQuantity', String(totalQuantity))
  }

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(event.currentTarget.value.replace(/\D/g, '')) || 0
    updateQuantity(newQuantity)
  }

  const add = () => updateQuantity(quantity + 1)
  const remove = () => updateQuantity(quantity - 1)

  return (
    <div className="flex p-6 pb-0">
      <div className="flex w-full flex-col gap-2">
        <Typography variant="body2" weight="bold" as="div">
          {t('ssp:pages.shop.detail.quantity')}
        </Typography>
        <div className="inline-flex w-full justify-between rounded-lg  border border-gray-300 bg-white dark:border-slate-800 dark:bg-slate-950 dark:text-slate-200 dark:ring-1 dark:ring-zinc-700">
          <Button
            onClick={remove}
            variant="outline"
            className="w-11 rounded-l-lg rounded-r-none border-0 px-1 py-3 text-xl font-semibold disabled:bg-gray-100 dark:bg-slate-950  dark:ring-zinc-700 dark:hover:bg-slate-900/50"
            data-testid="btnSubstractQuantity"
          >
            <span className="hidden">-</span>
            <MinusIcon className="h-6 w-6" />
          </Button>
          <input
            value={String(quantity)}
            onChange={onChange}
            className="mx-1 block w-full border-none text-center text-sm font-medium text-gray-900 outline-none disabled:bg-gray-100 dark:border-none dark:bg-slate-950 dark:text-gray-300 dark:ring-zinc-700 dark:focus:bg-slate-900/50  sm:text-sm"
            id="quantity"
            data-testid="inputProductQuantity"
          />
          <Button
            onClick={add}
            variant="outline"
            className="w-11 rounded-l-none rounded-r-lg border-0 px-1 py-3 text-xl font-semibold disabled:bg-gray-100 dark:bg-slate-950  dark:ring-zinc-700 dark:hover:bg-slate-900/50"
            data-testid="btnAddQuantity"
          >
            <span className="hidden">+</span>
            <PlusIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ShopProductQuantity
