import clsx from 'clsx'
import { Link as ReactRouterLink } from 'react-router-dom'

import { DataTestId } from '@sherweb/core/types/dataTestIdType'

import { useLocation, useParams, usePathGenerator } from '@ssp/modules/navigation'

export interface LinkProps
  extends Omit<
      React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
      'ref'
    >,
    DataTestId {
  to: string
  currentClassName?: string
  isAbsolute?: boolean
  params?: Record<string, string>
}

const Link: React.FC<LinkProps> = ({
  to,
  children,
  isAbsolute = false,
  dataTestId,
  currentClassName,
  params = {},
  ...linkProps
}) => {
  const location = useLocation()
  const existingParams = useParams()
  const generatePath = usePathGenerator()

  if (!existingParams.organizationUniqueName && !params.organizationUniqueName) {
    params.organizationUniqueName = ''
  }

  const generatedPath = generatePath(to, params)
  const current = location.pathname === generatedPath

  if (isAbsolute) {
    return (
      <a href={to} {...linkProps}>
        {children}
      </a>
    )
  }

  return (
    <ReactRouterLink
      to={generatedPath}
      data-testid={dataTestId}
      className={clsx(linkProps.className, current && currentClassName)}
      {...linkProps}
    >
      {children}
    </ReactRouterLink>
  )
}

export default Link
