import { QueryKey } from '@tanstack/react-query'

import { ResellerOrganizationsService } from '@sherweb/core/openapi-generated/ResellerOrganizationsService'

export const availableOrganizations = {
  queryKey: (resellerId: string): QueryKey => ['availableOrganizations', resellerId],
  queryFn: async (resellerId: string) => {
    return await ResellerOrganizationsService.resellerOrganizationsGetAvailableOrganizations({
      resellerId,
    })
  },
  staleTime: 1 * 60000,
}
