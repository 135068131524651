import { ArchiveBoxXMarkIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Card from '@sherweb/core/components/Card/Card'
import PageTitle, { PageTitleWrapper } from '@sherweb/core/components/PageTitle'
import Spinner from '@sherweb/core/components/Spinner/Spinner'

import { protectPage } from '@rsp/app/ProtectedPage'
import ContainedPage from '@rsp/components/layout/ContainedPage'
import ServerError from '@rsp/components/ServerError/ServerError'
import { useOrders } from '@rsp/modules/orders'

import { OrderApprovalDecision } from './OrderApprovalDecision'
import OrdersTable from './OrdersTable'
import OrdersTableMobile from './OrdersTableMobile'

const OrdersPage: React.FC = () => {
  const { t } = useTranslation()
  const { isLoading, error, data: orders = [], isFetched } = useOrders()
  const noOrders = isFetched && !isLoading && !error && !orders?.length

  return (
    <ContainedPage>
      <div className="flex flex-col gap-12 md:gap-16">
        <PageTitleWrapper
          title={<PageTitle data-testid="pageOrdersTitle">{t('rsp:pages.orders.title')}</PageTitle>}
        />
        {isLoading && <Spinner floating />}
        {error && <ServerError error={error} />}

        {noOrders && (
          <div
            className="flex w-full flex-col items-center justify-center gap-4 rounded-lg border-2 border-dashed border-gray-300 p-8 font-normal text-gray-400 md:h-60"
            data-testid="containerNoOrders"
          >
            <ArchiveBoxXMarkIcon className="h-16 w-16 stroke-1 text-gray-400" />
            <p>{t('rsp:pages.orders.noOrders')}</p>
          </div>
        )}

        {orders?.length > 0 && (
          <>
            <OrderApprovalDecision />
            <div className="hidden lg:block">
              <Card padded>
                <OrdersTable />
              </Card>
            </div>

            <div className="mt-4 lg:hidden">
              <Card padded>
                <OrdersTableMobile />
              </Card>
            </div>
          </>
        )}
      </div>
    </ContainedPage>
  )
}

export default protectPage(OrdersPage)
