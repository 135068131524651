import { ChevronDownIcon, ChevronUpDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { flexRender, SortDirection, Header as THeader } from '@tanstack/react-table'

import { mergeClassName } from '../../utils/mergeClassName'
import { TableHead } from '../Table'

const Header = <TData,>(header: THeader<TData, unknown>) => {
  const getSortIcon = (sortDirection: SortDirection | false) => {
    switch (sortDirection) {
      case 'asc':
        return <ChevronUpIcon className="mx-2 inline-flex w-[17px]" data-testid="sort-icon-up" />
      case 'desc':
        return (
          <ChevronDownIcon className="mx-2 inline-flex w-[17px]" data-testid="sort-icon-down" />
        )
      default:
        return (
          <ChevronUpDownIcon className="mx-2 hidden w-[17px]" data-testid="sort-icon-neutral" />
        )
    }
  }

  const sortable = header.column.getCanSort()
  const sortDirection = header.column.getIsSorted()
  const sortIcon = sortable ? getSortIcon(sortDirection) : null

  const content = flexRender(header.column.columnDef.header, header.getContext())

  return (
    <TableHead
      key={header.id}
      className={mergeClassName(
        'px-8 py-4 text-sm font-semibold font-regular bg-slate-200 text-slate-900 dark:border-slate-800 dark:hover:bg-slate-950/50 dark:bg-slate-950 dark:text-slate-200 whitespace-nowrap',
        sortable &&
          'hover:bg-slate-200/50 hover:dark:text-slate-200 dark:hover:bg-slate-950/50 cursor-pointer',
        !!sortDirection && 'bg-slate-200/80',
        (header.column.columnDef.meta as any)?.className,
        (header.column.columnDef.meta as any)?.headerClassName
      )}
      onClick={sortable ? () => header.column.toggleSorting(sortDirection === 'asc') : undefined}
      style={{
        width: header.getSize() !== 150 ? header.getSize() : undefined,
      }}
    >
      <div className="relative">
        {content}
        <span className="absolute">{sortable && sortIcon}</span>
      </div>
    </TableHead>
  )
}

export default Header
