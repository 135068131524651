import { Cog6ToothIcon, SignalIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { IntegrationQueryResult, Permission } from '@sherweb/core/openapi-generated/index.defs'

import Badge, { Variant } from '@sherweb/core/components/Badge'
import Card, { CardTitle } from '@sherweb/core/components/Card'
import { Typography } from '@sherweb/core/components/Typography'

import { HasAccess } from '@rsp/modules/authorization'
import {
  useGetIntegrationTypeDescription,
  useGetIntegrationTypeRoute,
  useGetIntegrationTypeTitle,
} from '@rsp/modules/integrations'

import Link from '@ssp/components/Link'

type IntegrationProps = {
  integration: IntegrationQueryResult
}

export const Integration = ({ integration }: IntegrationProps) => {
  const { t } = useTranslation()

  const title = useGetIntegrationTypeTitle(integration.integrationType)

  const description = useGetIntegrationTypeDescription(integration.integrationType)

  const route = useGetIntegrationTypeRoute(integration.integrationType)

  return (
    <Card className="flex flex-col justify-between md:p-4">
      <div className="flex flex-col gap-2">
        <CardTitle>
          <Typography variant="body1" weight="semiBold">
            {title}
          </Typography>
        </CardTitle>
        <Typography variant="label">{description}</Typography>
      </div>
      <div className="mt-4">
        <div className={`flex ${integration.configured ? 'justify-between' : 'justify-end'}`}>
          {integration.configured ? (
            <Badge variant={Variant.Success}>
              <SignalIcon className="mr-1 h-4 w-4" />
              {t('rsp:pages.integrations.buttons.connected')}
            </Badge>
          ) : null}
          <HasAccess permission={Permission.IntegrationConfigure}>
            <Link to={route}>
              <Badge
                className="border-gray-600/10 bg-gray-100 hover:bg-gray-600/10 dark:border-gray-400 dark:bg-transparent dark:text-gray-300"
                data-testid="btnIntegrationSetup"
              >
                <Cog6ToothIcon className="mr-1 h-4 w-4" />
                {t('rsp:pages.integrations.buttons.setup')}
              </Badge>
            </Link>
          </HasAccess>
        </div>
      </div>
    </Card>
  )
}

export default Integration
