import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  ImageType,
  ResellersQueryResult,
  SetResellerSettingsCommand,
  Theme,
} from '@sherweb/core/openapi-generated/index.defs'
import { ResellersService } from '@sherweb/core/openapi-generated/ResellersService'

import { successNotification } from '@sherweb/core/components/ToastNotifications'
import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'
import { useHostname } from '@sherweb/core/modules/browserRouter'
import { missingParametersError } from '@sherweb/core/utils/error'

import * as actions from './reseller.actions'
import { buildReseller } from './reseller.builder'
import { OrderSettings, Reseller } from './reseller.models'
import {
  setSupportInformation,
  updateImage,
  updateOnboarding,
  updatePortalSettings,
} from './reseller.mutations'
import { reseller, resellerAdminEmails } from './reseller.queries'
import * as selectors from './reseller.selectors'

const useGetResellerCommon = () => {
  const hostname = useHostname() ?? 'localhost'
  const resellerId = useSelectedResellerId() ?? ''
  const queryKey = reseller.queryKey(hostname)
  const queryClient = useQueryClient()

  return {
    resellerId,
    queryKey,
    queryClient,
  }
}

export const useReseller = () => {
  const { isLoggedIn } = useAuthenticationLoggedInState()

  const hostname = useHostname() ?? 'localhost'
  const queryKey = reseller.queryKey(hostname)

  return useQuery<ResellersQueryResult, Error, Reseller>({
    queryKey,
    queryFn: async () => await reseller.queryFn(hostname),
    select: data => buildReseller(data),
    enabled: isLoggedIn,
    staleTime: reseller.staleTime,
  })
}

export const useGetResellerAdminEmails = () => {
  const resellerQuery = useReseller()

  return useQuery({
    queryKey: resellerAdminEmails.queryKey(resellerQuery?.data?.id),
    queryFn: async () => await resellerAdminEmails.queryFn(resellerQuery?.data?.id),
    enabled: !!resellerQuery?.data?.id,
    staleTime: resellerAdminEmails.staleTime,
  })
}

export const useSelectedResellerId = () => {
  return useSelector(selectors.selectedReseller)
}

export const useUpdateImageAction = () => {
  const hostname = useHostname() ?? 'localhost'
  const resellerId = useSelectedResellerId() ?? ''
  const queryKey = reseller.queryKey(hostname)
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateImage.mutationFn,
  })

  const invalidateQuery = async () => {
    await queryClient.invalidateQueries(queryKey)
  }

  return {
    updateLogo: async (logoFile: File) => {
      await updateImageFile(resellerId, logoFile, ImageType.Other, 144, 144)
      await mutation.mutateAsync({ resellerId, file: logoFile, type: ImageType.Logo, size: 'any' })
      await invalidateQuery()
    },
    updateIcon: async (iconFile: File) => {
      await updateImageFile(resellerId, iconFile, ImageType.Icon, 64, 64)
      await invalidateQuery()
    },
    ...mutation,
    data: mutation.data,
  }
}

const updateImageFile = async (
  resellerId: string,
  file: File,
  type: ImageType,
  sizeW: number,
  sizeH: number
) => {
  const buffer = await file.arrayBuffer()
  if (buffer) {
    await import('image-js').then(async module => {
      const image = await module.default.load(buffer)
      const iconImage = image.resize({ height: sizeH, width: sizeW })
      const iconBlob = await iconImage.toBlob()
      const newFile = new File([iconBlob], file.name, { type: file.type })
      return await updateImage.mutationFn({
        resellerId,
        size: `${sizeH}x${sizeW}`,
        type,
        file: newFile,
      })
    })
  }
}

export const useUpdatePortalSettings = () => {
  const { resellerId, queryClient, queryKey } = useGetResellerCommon()

  const mutation = useMutation({
    mutationFn: updatePortalSettings.mutationFn,
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey)
    },
  })

  return {
    updatePortalSettings: async (theme: Theme) => {
      return await mutation.mutateAsync({ resellerId, theme })
    },
    ...mutation,
    data: mutation.data,
  }
}

export const useUpdateOnboardingResellerAction = () => {
  const resellerId = useSelectedResellerId() ?? ''
  const queryClient = useQueryClient()
  const queryKey = reseller.queryKey(resellerId)
  const mutation = useMutation({
    mutationFn: updateOnboarding.mutationFn,
  })

  return {
    updateOnboarding: async () => {
      await updateOnboarding.mutationFn({ resellerId })
      const result = await mutation.mutateAsync({ resellerId })
      await queryClient.invalidateQueries(queryKey)
      await queryClient.refetchQueries(queryKey)
      return result
    },
    ...mutation,
    data: mutation.data,
  }
}

export const useResellerActions = () => {
  const dispatch = useDispatch()
  return {
    setSelectedReseller: (...args: any) => dispatch(actions.setSelectedReseller.apply(null, args)),
  }
}

export const useSetSupportInformation = () => {
  const resellerId = useSelectedResellerId() ?? ''

  const mutation = useMutation({
    mutationFn: setSupportInformation.mutationFn,
  })

  return {
    setSupportInformation: async (email: string, phoneNumber: string, address: string) => {
      return await mutation.mutateAsync({ resellerId, email, phoneNumber, address })
    },
    ...mutation,
    data: mutation.data,
  }
}

export const useSetResellerSettingsMutation = () => {
  const { t } = useTranslation()

  const resellerId = useSelectedResellerId()
  const queryClient = useQueryClient()
  const hostname = useHostname() ?? 'localhost'
  const queryKey = reseller.queryKey(hostname)

  return useMutation({
    mutationFn: async (params: SetResellerSettingsCommand) => {
      if (!resellerId) {
        throw missingParametersError()
      }

      return await ResellersService.setResellerSettings({
        resellerId,
        command: {
          ...params,
        },
      })
    },
    onSuccess: async () => {
      successNotification(t('rsp:pages.settings.labels.changesApplied'))

      await queryClient.invalidateQueries(queryKey)
    },
  })
}

export const useSetOrderSettings = () => {
  const { t } = useTranslation()

  const { resellerId, queryClient, queryKey } = useGetResellerCommon()

  return useMutation({
    mutationFn: async (params: OrderSettings) => {
      return await ResellersService.setOrderSettings({
        resellerId,
        command: {
          emailNotificationsEnabled: params.emailNotifications.enabled,
          emailRecipientsList: params.emailNotifications.allUsersEnabled
            ? []
            : params.emailNotifications.emails,
          isOrderApprovalRequired: params.isOrderApprovalRequired,
          teamsNotificationsEnabled: params.teamNotifications.enabled,
          teamsIncomingWebhook: params.teamNotifications.webHookUrl,
        },
      })
    },
    onSuccess: async () => {
      successNotification(t('rsp:pages.settings.labels.changesApplied'))
      return await queryClient.invalidateQueries(queryKey)
    },
  })
}
