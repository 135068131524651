import { useQuery } from '@tanstack/react-query'

import { useAuthenticationLoggedInState } from '@sherweb/core/modules/authentication'

import { useSelectedResellerId } from '@ssp/modules/reseller'

import { detailedOrganizationsByResellerId } from './organizations.queries'

export const useOrganizations = () => {
  const { isLoggedIn } = useAuthenticationLoggedInState()
  const selectedResellerId = useSelectedResellerId()

  return useQuery({
    queryKey: detailedOrganizationsByResellerId.queryKey(selectedResellerId),
    queryFn: async () => await detailedOrganizationsByResellerId.queryFn(selectedResellerId),
    enabled: isLoggedIn && !!selectedResellerId,
  })
}
