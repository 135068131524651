import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@sherweb/core/components/Form'
import Switch from '@sherweb/core/components/Switch'

import { FormSplitScreen } from '@rsp/components/layout/FormSplitScreen'

import { ApiInformationFormType } from './hooks/useApiInformationSchema'

export const ApiInformationEnabled = () => {
  const { t } = useTranslation()

  const form = useFormContext<ApiInformationFormType>()

  return (
    <FormSplitScreen.Wrapper>
      <FormSplitScreen.Row
        leftContent={
          <FormSplitScreen.LeftContent
            label={t('rsp:pages.integrations.helpdesk.connectWise.apiInformation.enabled.title')}
            subLabel={t(
              'rsp:pages.integrations.helpdesk.connectWise.apiInformation.enabled.description'
            )}
          />
        }
      >
        <FormField
          control={form.control}
          name="enabled"
          render={({ field }) => (
            <FormItem className="settings-form-item">
              <FormLabel>
                {t('rsp:pages.integrations.helpdesk.connectWise.apiInformation.enabled.label')}
              </FormLabel>
              <FormControl className="settings-form-control">
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  data-testid="switchApiInformationEnabled"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </FormSplitScreen.Row>
    </FormSplitScreen.Wrapper>
  )
}
