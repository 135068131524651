export type OrdersTableCreatedByNameRowProps = {
  firstName: string
  lastName: string
  email: string
}

export const OrdersTableCreatedByNameRow = ({
  firstName,
  lastName,
  email,
}: OrdersTableCreatedByNameRowProps) => {
  return (
    <div className="flex flex-row items-center gap-4">
      <div className="mb-1 flex flex-col justify-between text-sm font-medium sm:text-sm md:mb-0">
        <div className="font-semibold">
          {firstName} {lastName}
        </div>
        {email && <div className="font-light"> {email}</div>}
      </div>
    </div>
  )
}
