import {
  HelpDeskTicketQueryResult,
  CreateHelpDeskTicketCommand,
  HelpDeskTicketType,
  HelpDeskTicketStatus,
  HelpDeskTicketStatusType,
  HelpDeskTicketDetailsQueryResult,
  HelpDeskTicketNoteQueryResult,
  CreateHelpDeskTicketNoteCommand,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class OrganizationTicketsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getTickets(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskTicketQueryResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/helpDesk/tickets';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createTicket(
    params: {
      /**  */
      organizationId: string;
      /**  */
      command: CreateHelpDeskTicketCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/helpDesk/tickets';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationHelpDeskTicketTypes(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskTicketType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/helpDesk/tickets/types';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOrganizationHelpDeskTicketStatuses(
    params: {
      /**  */
      organizationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskTicketStatus[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/helpDesk/tickets/statuses';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTicketDetails(
    params: {
      /**  */
      organizationId: string;
      /**  */
      ticketId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskTicketDetailsQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/helpDesk/tickets/{ticketId}';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{ticketId}', params['ticketId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTicketAttachment(
    params: {
      /**  */
      organizationId: string;
      /**  */
      ticketId: string;
      /**  */
      attachmentId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/helpDesk/tickets/{ticketId}/attachments/{attachmentId}';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{ticketId}', params['ticketId'] + '');
      url = url.replace('{attachmentId}', params['attachmentId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createTicketNote(
    params: {
      /**  */
      organizationId: string;
      /**  */
      ticketId: string;
      /**  */
      command: CreateHelpDeskTicketNoteCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HelpDeskTicketNoteQueryResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/organizations/{organizationId}/helpDesk/tickets/{ticketId}/notes';
      url = url.replace('{organizationId}', params['organizationId'] + '');
      url = url.replace('{ticketId}', params['ticketId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['command'];

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}
