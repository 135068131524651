import { OrganizationOrdersService } from '@sherweb/core/openapi-generated/OrganizationOrdersService'

export const ordersByOrganizationId = {
  queryKey: (organizationId?: string) => [
    'OrganizationOrdersService/getOrganizationOrdersById',
    organizationId,
  ],
  queryFn: async (organizationId?: string) =>
    organizationId
      ? await OrganizationOrdersService.getOrganizationOrdersById({ organizationId })
      : [],
}
